import React, { useState } from 'react';
import SetSeparatePrice from './SetSeparatePrice';
import Variants from './Variants';
import { useFormContext } from 'react-hook-form';
import InputSection from '../../../../../../Components/InputField/InputSection';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';
import VariantGroupForm from './VariantGroupForm';
import DropdownSection from '../../../../../../Components/DropDown/DropdownSection';
import { gstSlab } from '../../../../../../Constants/Constants';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import { useSelector } from 'react-redux';

export default function SetPrice() {
    const {
        watch,
        formState: { errors },
    } = useFormContext();

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);
    const [showError, setShowError] = useState(false);
    const activeVariant = watch('pricing.activeVariant');

    return (
        <>
            <div className='max-w-[640px] md:max-w-full'>
                <VariantGroupForm />

                <TitleDescription
                    className='mb-4'
                    title='Packaging price and GST rate'
                    description='Set the packaging price(if required) and select a GST slab(if applicable).'
                />

                <div className='flex flex-row items-center mb-6 lg:block'>
                    <div className='mr-1.5 w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full lg:mr-0'>
                        <InputSection
                            label='(Packaging price)'
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            placeholder='Enter packaging price in rupees'
                            shadow='shadow-small-drop-down-shadow'
                            name={`dishPackingPrice`}
                            inputType='number'
                        />
                    </div>
                    <div className='ml-1.5 w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full lg:ml-0'>
                        <DropdownSection
                            boxHeight='h-[52px]'
                            labelpaddingB='pb-2'
                            label='(GST)'
                            placeholder='Select a GST slab'
                            shadow='shadow-small-drop-down-shadow'
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            menuItems={gstSlab}
                            name={`dishGstSlab`}
                        />
                    </div>
                </div>

                {!watch('do_add_variant') ? (
                    <SetSeparatePrice
                        id='pricing.dishPrice'
                        name='pricing.variantPrice'
                    />
                ) : (
                    <>
                        <Variants
                            activeVariantName='pricing.activeVariant'
                            availableVariantName='pricing.variantPrice'
                            CheckForPrice='pricing'
                            setShowError={setShowError}
                        />

                        {watch('pricing.variantPrice')?.map((el, variantIndex) => {
                            if (activeVariant !== el.id) return null;

                            const hasValidTitle = el.title?.some((title) => title?.trim()?.length > 0);

                            return (
                                <div key={variantIndex}>
                                    <TitleDescription
                                        className='mb-4'
                                        title='Variant name'
                                        description='Lorem ipsum dolor sit amet consectetur, adipisicing elit. '
                                    />

                                    <div className='flex flex-row gap-2 mb-4'>
                                        {availableLanguages.map((el, index) => {
                                            return (
                                                <div
                                                    className='w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full md:mr-0 md:mb-2 relative'
                                                    key={index}>
                                                    <InputSection
                                                        boxHeight='[52px]'
                                                        labelMarginB='mb-2'
                                                        label={`(${el.language_label})`}
                                                        labelStyle='paragraph-medium-italic text-neutral-500'
                                                        placeholder={`Enter title in ${el.language_label}`}
                                                        shadow='shadow-smallDropDownShadow'
                                                        name={`pricing.variantPrice[${activeVariant}].title.${el.id}`}
                                                        rules={{
                                                            required: `Please enter title in ${el.language_label}`,
                                                        }}
                                                    />

                                                    {!hasValidTitle && showError && (
                                                        <span className='text-red-500 text-sm'>
                                                            Please enter at least one valid title
                                                        </span>
                                                    )}

                                                    {/* {errors?.pricing?.variantPrice?.[activeVariant]?.title?.[el.id] && (
                                                        <ErrorMessage
                                                            errors={
                                                                errors?.pricing?.variantPrice?.[activeVariant]?.title?.[
                                                                    el.id
                                                                ]
                                                            }
                                                        />
                                                    )} */}
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <SetSeparatePrice
                                        id={`pricing.variantPrice.${el.id}`}
                                        name='pricing.variantPrice'
                                    />
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </>
    );
}
