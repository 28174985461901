import React from 'react';
import CouponCodeSection from './Components/CouponCodeSection';
import DescriptionSection from './Components/DescriptionSection';
import TitleSection from './Components/TitleSection';
import { useFormContext } from 'react-hook-form';

export default function BasicDetails() {
    const { watch } = useFormContext();
    const offerType = watch('offer_type')?.value;
    const hideCouponCode = offerType === 'coupled_offer' || offerType === 'BOGO_View';
    return (
        <>
            {!hideCouponCode && (
                <div className='mb-6'>
                    <CouponCodeSection />
                </div>
            )}

            <div className='mb-6'>
                <TitleSection />
            </div>

            <div className='mb-6'>
                <DescriptionSection />
            </div>
        </>
    );
}
