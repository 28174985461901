import React, { useMemo, useState } from 'react';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import { ListBlankComponentView } from '../../../Components/BlankComponent/ListBlankComponentView';

const Table = (props) => {
    const { reportDetails, checkedItems, setCheckedItems, showCheckbox, isReportCardLoading, paginationChanged } =
        props;
    const [isHeaderIndeterminate, setIsHeaderIndeterminate] = useState(false);

    const formatReportType = (type) => {
        return type
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const areAllCurrentPageItemsSelected = useMemo(() => {
        const currentPageIds = reportDetails.map((report) => report.id);
        const selectedCurrentPageItems = currentPageIds.filter((id) => checkedItems.some((item) => item.id === id));

        setIsHeaderIndeterminate(
            selectedCurrentPageItems.length > 0 && selectedCurrentPageItems.length < currentPageIds.length
        );

        return currentPageIds.length > 0 && selectedCurrentPageItems.length === currentPageIds.length;
    }, [reportDetails, checkedItems]);

    const handleCheckboxChange = (report, isChecked) => {
        if (isChecked) {
            setCheckedItems([...checkedItems, report]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item.id !== report.id));
        }
    };

    const selectAll = () => {
        if (areAllCurrentPageItemsSelected) {
            // Remove all current page items from selection
            const currentPageIds = reportDetails.map((report) => report.id);
            const newCheckedItems = checkedItems.filter((item) => !currentPageIds.includes(item.id));
            setCheckedItems(newCheckedItems);
        } else {
            // Add all current page items to selection
            const currentPageData = reportDetails.map((report) => ({
                id: report.id,
                name: report.name,
                type: report.type,
                start_date: report.start_date,
                requested_at: report.requested_at,
                generated_at: report.generated_at,
                url: report?.url,
            }));

            // Filter out any existing items to avoid duplicates
            const existingIds = checkedItems.map((item) => item.id);
            const newItems = currentPageData.filter((item) => !existingIds.includes(item.id));

            setCheckedItems([...checkedItems, ...newItems]);
        }
    };
    const handleDownloadReport = (report) => {
        if (report.url) {
            try {
                const link = document.createElement('a');
                link.href = encodeURI('https://api.staging.fab.delivery/storage/reports/' + report.name);
                link.download = `${'https://api.staging.fab.delivery/storage/reports/' + report.name}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error(
                    `Failed to download file from URL: ${'https://api.staging.fab.delivery/storage/reports/' + report.name}`,
                    error
                );
                alert(
                    `Error downloading file: ${'https://api.staging.fab.delivery/storage/reports/' + report.name || 'Unnamed Report'}`
                );
            }
        } else {
            console.warn('No URL found for the report:', report);
        }
    };
    return (
        <div className='w-full h-[220px] rounded-lg overflow-auto scrollbar-style border-neutral-300 border mt-4'>
            {isReportCardLoading && paginationChanged ? (
                <table>
                    <tbody>
                        <TableLoader
                            hasSwitch={false}
                            columns={6}
                        />
                    </tbody>
                </table>
            ) : (
                <table className='w-full break-words'>
                    <thead>
                        <tr className='bg-neutral-50 uppercase shadow-innerShadow paragraph-overline-small text-neutral-700 pl-6 h-11'>
                            {showCheckbox && (
                                <th className='min-w-[50px] pl-6'>
                                    <div className='flex items-center'>
                                        <CheckBoxWithoutLabels
                                            onChange={selectAll}
                                            isChecked={areAllCurrentPageItemsSelected}
                                            isIndeterminate={isHeaderIndeterminate}
                                        />
                                    </div>
                                </th>
                            )}
                            <th className='min-w-[100px] pl-6 text-left'>REPORT ID</th>
                            <th className='min-w-[200px] pl-6 text-left'>REPORT NAME</th>
                            <th className='min-w-[200px] pl-6 text-left'>REPORT TYPE</th>
                            <th className='min-w-[150px] pl-6 text-left'>START DATE</th>
                            <th className='min-w-[150px] pl-6 text-left'>REQUESTED AT</th>
                            <th className='min-w-[150px] pl-6 text-left'>GENERATED AT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportDetails.length > 0 ? (
                            reportDetails.map((report) => (
                                <tr
                                    key={report.id}
                                    className='even:bg-neutral-50 border-t first:border-none border-neutral-300 paragraph-small-regular h-[70px] justify-center'>
                                    {showCheckbox && (
                                        <td className='pl-6'>
                                            <div className='flex items-center'>
                                                <CheckBoxWithoutLabels
                                                    onChange={(isChecked) => handleCheckboxChange(report, isChecked)}
                                                    isChecked={checkedItems.some((item) => item.id === report.id)}
                                                />
                                            </div>
                                        </td>
                                    )}
                                    <td className='pl-6'>{report.id}</td>
                                    <td
                                        className='pl-6 cursor-pointer text-primary-500 hover:underline'
                                        onClick={() => handleDownloadReport(report)}>
                                        {report.name}
                                    </td>

                                    <td className='pl-6'>{formatReportType(report.type)}</td>
                                    <td className='pl-6'>{report.start_date}</td>
                                    <td className='pl-6'>{report.end_date}</td>
                                    <td className='pl-6'>{report.generated_at || 'Pending'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={showCheckbox ? 8 : 7}
                                    className='text-center p-10 '>
                                    <ListBlankComponentView previewText={'No Content Available'} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Table;
