import React from 'react';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as Add } from '../../../Assets/add.svg';
import { ReactComponent as Redirect } from '../../../Assets/redirect.svg';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import { ReactComponent as ReArrangeIcon } from '../../../Assets/re-arrange.svg';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function Header(props) {
    const { setPopupStatus, setIsEditMode, isEditMode, popupStatus, setShowTableContent } = props;

    const navigate = useNavigate();

    const handleNavigation = (params) => {
        //this function is used to navigate
        navigate(params);
    };
    const isMobileScreen = IsMobileScreen();

    const handlePopupStatus = (popup) => {
        //this function is used to open or close popup
        isMobileScreen && setShowTableContent(false);
        setIsEditMode(false);
        setPopupStatus((prevStatus) => ({
            ...Object.fromEntries(Object.keys(prevStatus).map((kay) => [kay, false])),
            [popup]: true,
        }));
    };

    const screenWidth = useWindowSize().width;
    return (
        <>
            <div className='flex flex-row justify-between pb-4 border-b border-b-neutral-300 mb-4 md:block'>
                <div className='flex flex-row md:mb-4'>
                    <div
                        className='md:w-1/2  md:mr-2 mobile:mr-1'
                        onClick={() => handleNavigation('/orders/live')}>
                        <LargePrimaryButton
                            isDefault={false}
                            leftIconDefault={<Redirect fill='#ffffff' />}
                            leftIconClick={<Redirect fill='#C4BEED' />}
                            label='Live orders'
                        />
                    </div>

                    <div
                        className='ml-4 lg:ml-1  md:w-1/2 xl:ml-2 mr-2 mobile:ml-1'
                        onClick={() => handleNavigation('/bill-payment')}>
                        <LargePrimaryButton
                            isDefault={false}
                            leftIconDefault={<Redirect fill='#ffffff' />}
                            leftIconClick={<Redirect fill='#C4BEED' />}
                            label='Bill payments'
                        />
                    </div>
                </div>

                <div className='flex flex-row'>
                    <div
                        className='max-w-[159px] md:max-w-full mr-4 '
                        onClick={() => handlePopupStatus('tableArrangeMent')}>
                        <LargePrimaryButton
                            label='Re-arrange'
                            leftIconDefault={<ReArrangeIcon stroke='#ffffff' />}
                            leftIconClick={<ReArrangeIcon stroke='#C4BEED' />}
                            isClicked={popupStatus?.tableArrangeMent}
                        />
                    </div>
                    <div
                        onClick={() => setIsEditMode((prevState) => !prevState)}
                        className='min-w-[64px] cursor-pointer'>
                        <LargePrimaryButton
                            leftIconDefault={<EditIcon stroke='#ffffff' />}
                            leftIconClick={<EditIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden'
                            label='Edit'
                            isClicked={isEditMode}
                        />
                    </div>

                    <div
                        className='mx-4 lg:mx-1 md:mx-2 md:w-full cursor-pointer'
                        onClick={() => handlePopupStatus('addSection')}>
                        <LargePrimaryButton
                            isDefault={false}
                            leftIconDefault={<Add stroke='#ffffff' />}
                            leftIconClick={<Add stroke='#C4BEED' />}
                            label={
                                screenWidth < 1023 && screenWidth > 767
                                    ? 'Section'
                                    : screenWidth < 375
                                      ? 'Section'
                                      : 'Add section'
                            }
                        />
                    </div>

                    <div
                        onClick={() => handlePopupStatus('addTable')}
                        className='md:w-full cursor-pointer'>
                        <LargePrimaryButton
                            isDefault={false}
                            leftIconDefault={<Add stroke='#ffffff' />}
                            leftIconClick={<Add stroke='#C4BEED' />}
                            label={
                                screenWidth < 1023 && screenWidth > 767
                                    ? 'Table'
                                    : screenWidth < 375
                                      ? 'Table'
                                      : 'Add table'
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
