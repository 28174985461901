import React, { useState, useEffect } from 'react';
import { CheckBox } from '../../Components/FormControl/FormControls';
import { useSelector } from 'react-redux';
import { MediumPrimaryButton } from '../../Components/Buttons/Button';
import { DefaultInputField } from '../../../src/Components/InputField/InputField';

const KitchenDisplaySettings = () => {
    const categories = useSelector((state) => state.outlet.categories);
    const items = useSelector((state) => state.outlet.items);

    const [selectedCategories, setSelectedCategories] = useState({});
    const [selectedItems, setSelectedItems] = useState({});
    const [selectAllCategories, setSelectAllCategories] = useState(false);
    const [selectAllItems, setSelectAllItems] = useState(false);

    // Create the categoryMap structure
    const categoryMap = {};
    categories.forEach((category) => {
        if (category.parent_id == null) {
            categoryMap[category.id] = { ...category, subcategories: [], items: [] };
        } else {
            if (categoryMap[category.parent_id]) {
                categoryMap[category.parent_id].subcategories.push({ ...category, items: [] });
            } else {
                categoryMap[category.parent_id] = { subcategories: [{ ...category, items: [] }] };
            }
        }
    });

    const structuredCategories = Object.values(categoryMap);

    // Assign items to categories and subcategories
    const assignItemsToCategories = (categories, items) => {
        categories.forEach((category) => {
            category.items = JSON.parse(category.category_item_links)
                .map((link) => items.find((item) => item.id === link.item_id))
                .filter(Boolean);

            if (category?.subcategories?.length > 0) {
                assignItemsToCategories(category.subcategories, items);
            }
        });
    };

    assignItemsToCategories(structuredCategories, items);

    const handleCategoryChange = (categoryId, isChecked) => {
        const updatedSelectedCategories = { ...selectedCategories };
        const updatedSelectedItems = { ...selectedItems };

        const updateCategoryAndItems = (category, isChecked) => {
            updatedSelectedCategories[category.id] = isChecked;

            // Update items for this category
            category.items.forEach((item) => {
                updatedSelectedItems[item.id] = isChecked;
            });

            // Update subcategories and their items
            if (category.subcategories) {
                category.subcategories.forEach((subcat) => {
                    updateCategoryAndItems(subcat, isChecked);
                });
            }
        };

        const category =
            structuredCategories.find((cat) => cat.id === categoryId) ||
            structuredCategories.flatMap((cat) => cat.subcategories).find((subcat) => subcat.id === categoryId);

        if (category) {
            updateCategoryAndItems(category, isChecked);
        }

        setSelectedCategories(updatedSelectedCategories);
        setSelectedItems(updatedSelectedItems);

        // Check if all categories are selected
        const allCategoriesSelected = structuredCategories.every(
            (cat) =>
                updatedSelectedCategories[cat.id] &&
                cat.subcategories.every((subcat) => updatedSelectedCategories[subcat.id])
        );
        setSelectAllCategories(allCategoriesSelected);

        // Check if all items are selected
        const allItemsSelected = items.every((item) => updatedSelectedItems[item.id]);
        setSelectAllItems(allItemsSelected);
    };

    const handleSelectAllCategories = (isChecked) => {
        const updatedSelectedCategories = {};
        const updatedSelectedItems = {};

        structuredCategories.forEach((category) => {
            updatedSelectedCategories[category.id] = isChecked;
            category.items.forEach((item) => {
                updatedSelectedItems[item.id] = isChecked;
            });
            category.subcategories.forEach((subcat) => {
                updatedSelectedCategories[subcat.id] = isChecked;
                subcat.items.forEach((item) => {
                    updatedSelectedItems[item.id] = isChecked;
                });
            });
        });

        setSelectedCategories(updatedSelectedCategories);
        setSelectedItems(updatedSelectedItems);
        setSelectAllCategories(isChecked);
        setSelectAllItems(isChecked);
    };

    const handleItemChange = (itemId, isChecked) => {
        setSelectedItems((prev) => ({
            ...prev,
            [itemId]: isChecked,
        }));

        // Check if all items are selected
        const updatedSelectedItems = { ...selectedItems, [itemId]: isChecked };
        const allItemsSelected = items.every((item) => updatedSelectedItems[item.id]);
        setSelectAllItems(allItemsSelected);
    };

    const handleSelectAllItems = (isChecked) => {
        const newSelectedItems = {};
        items.forEach((item) => {
            newSelectedItems[item.id] = isChecked;
        });
        setSelectedItems(newSelectedItems);
        setSelectAllItems(isChecked);
    };

    const renderCategory = (category) => (
        <div
            key={category.id}
            className='flex items-start mb-2 w-full mt-4'>
            <div className='w-1/4 mt-3'>
                <CheckBox
                    label={category.internal_name}
                    optionId={`category-${category.id}`}
                    paddingL='6 md:pl-2'
                    labelStyle='paragraph-medium-regular md:block md:overflow-hidden font-bold'
                    isChecked={selectedCategories[category.id] || false}
                    setIsChecked={(isChecked) => handleCategoryChange(category.id, isChecked)}
                />
            </div>
            <div className='w-3/4 flex flex-wrap'>
                {category.subcategories &&
                    category.subcategories.map((subcat) => (
                        <div
                            className='w-1/2 mt-3'
                            key={subcat.id}>
                            <CheckBox
                                label={subcat.internal_name}
                                optionId={`subcategory-${subcat.id}`}
                                paddingL='6 md:pl-2'
                                labelStyle='paragraph-medium-regular md:block md:overflow-hidden'
                                isChecked={selectedCategories[subcat.id] || false}
                                setIsChecked={(isChecked) => handleCategoryChange(subcat.id, isChecked)}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
    const orderingMode = [
        { id: 1, name: 'Delivery' },
        { id: 2, name: 'Pick Up' },
        { id: 3, name: 'Dine In' },
    ];

    return (
        <div className='m-6'>
            <div className='px-6 pb-[70px] lg:px-4 lg:pb-0 pt-4 w-full h-auto max-w-[1336px] mx-auto bg-white border border-stone-300 mt-10 rounded-lg'>
                <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                        <span className='paragraph-large-medium text-black'>Category</span>
                    </div>
                    <div>
                        (<span className='font-bold'>{Object.values(selectedCategories).filter(Boolean).length}</span>
                        categories Assigned)
                    </div>
                </div>
                <div className='p-2'>
                    <CheckBox
                        label='Select All'
                        optionId='Select-All-Categories'
                        paddingL='6 md:pl-0'
                        labelStyle='paragraph-medium-regular md:block md:overflow-hidden'
                        isChecked={selectAllCategories}
                        setIsChecked={handleSelectAllCategories}
                    />
                </div>
                <p className='border-b-2 border-gray-300'>
                    Note: Selecting All Categories will allow all categories (present and coming in future) will be
                    allocated for this Printer.
                </p>
                <div>{structuredCategories.map((category) => renderCategory(category))}</div>
            </div>

            <div className='items px-6 pb-[70px] lg:px-4 lg:pb-0 pt-4 w-full h-auto max-w-[1336px] mx-auto bg-white border border-stone-300 mt-10 rounded-lg'>
                <div>
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center'>
                            <span className='paragraph-large-medium text-black'>Items</span>
                        </div>
                        <div>
                            (<span className='font-bold'>{Object.values(selectedItems).filter(Boolean).length}</span>
                            Items Assigned)
                        </div>
                    </div>
                    <div className='p-2'>
                        <CheckBox
                            label='Select All'
                            optionId='Select-All-Items'
                            paddingL='6 md:pl-0'
                            labelStyle='paragraph-medium-regular md:block md:overflow-hidden'
                            isChecked={selectAllItems}
                            setIsChecked={handleSelectAllItems}
                        />
                    </div>
                    <p className='border-b-2 border-gray-300'>
                        Items Only assign to this printer or not assign to any other printer
                    </p>

                    <div className='flex flex-wrap gap-4 w-full mt-6'>
                        {items.map((item) => (
                            <div
                                key={item.id}
                                className='flex-1 min-w-[calc(25%-1rem)] max-w-[calc(25%-1rem)] mb-2 mt-3'>
                                <CheckBox
                                    label={item.internal_name}
                                    optionId={`item-${item.id}`}
                                    paddingL='6 md:pl-2'
                                    labelStyle='paragraph-medium-regular md:block md:overflow-hidden font-bold'
                                    isChecked={selectedItems[item.id] || false}
                                    setIsChecked={(isChecked) => handleItemChange(item.id, isChecked)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='px-6 pb-[70px] lg:px-4 lg:pb-0 pt-4 w-full h-auto max-w-[1336px] mx-auto bg-white border border-stone-300 mt-10 rounded-lg '>
                <div className='flex gap-60 border-b-2 border-gray-300 p-2'>
                    <span className='paragraph-large-medium text-black'>Order Type</span>
                    <span className='paragraph-large-medium text-black'>Print Copies</span>
                </div>
                <div className='mt-10'>
                    {orderingMode.map((item) => (
                        <div
                            key={item.id}
                            className='flex items-start mb-2 mt-6 gap-4'>
                            <div className='flex-auto'>
                                <CheckBox
                                    label={item.name}
                                    optionId={`item-${item.id}`}
                                    paddingL='6 md:pl-2'
                                    labelStyle='paragraph-medium-regular md:block md:overflow-hidden font-bold'
                                    isChecked={true}
                                />
                            </div>
                            <div className='w-full max-w-[375px] md:max-w-full'>
                                <DefaultInputField
                                    placeholder=''
                                    value={1}
                                    // placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='border-2 border-gray-300 p-3 mt-6 rounded-lg'>
                    <div className=''>
                        <CheckBox
                            label='All Tables'
                            paddingL='6 md:pl-2'
                            labelStyle='paragraph-medium-regular md:block md:overflow-hidden font-bold'
                            isChecked={true}
                        />
                    </div>
                    <p className='p-2 font-medium'>
                        Note: Selecting All Tables will allow all tables (presenting and coming in future) will be
                        allocated for this Printer
                    </p>
                </div>
                <div className='flex justify-end gap-4'>
                    <div className='flex gap-4 -mb-[170px] mt-4'>
                        <MediumPrimaryButton label='Cancel' />
                        <MediumPrimaryButton label='Save' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KitchenDisplaySettings;
