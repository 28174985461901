import React, { useState } from 'react';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import { LargePrimaryButton } from '../../../../../Components/Buttons/Button';
import EditPricePopUp from '../../../AddDish/TabComponents/DishPricing/Components/EditPricePopUp';
import { ReactComponent as EditIcon } from '../../../../../Assets/edit.svg';
import DishPricingTableSectionAddon from './DishPricingTableSectionAddon';

const PricingTableAddOn = () => {
    const [isEditPrice, setIsEditPrice] = useState(false);

    const [showEditPopUp, setShowEditPopUp] = useState(false);

    const handleClickPriceEdit = () => {
        isEditPrice && setShowEditPopUp(!showEditPopUp);
    };

    return (
        <>
            <TitleDescription
                className='mb-6'
                title='Pricing table'
                description='Pricing table consists of all the pricing structure set for the current dish. You can view and modify the prices from the table too.'
            />

            <div
                className='mr-2 cursor-pointer mb-4 max-w-[145px]'
                onClick={() => setIsEditPrice(!isEditPrice)}>
                <LargePrimaryButton
                    label='Edit price'
                    leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                    leftIconClick={<EditIcon stroke='#C4BEED' />}
                />
            </div>

            <DishPricingTableSectionAddon isEditPrice={isEditPrice} />

            {showEditPopUp && <EditPricePopUp handleClickClose={handleClickPriceEdit} />}
        </>
    );
};

export default PricingTableAddOn;
