import React, { useEffect, useState } from 'react';
import CalenderField from '../../../Components/Calender/CalenderField';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import { ReportCardLoader } from '../../../Components/ContentLoader/ReportCardLoader';
import APIV5 from '../../../api/axios/APIV5';
import { useWindowSize } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import SearchableDropdown from '../../../Components/SearchableDropdown/SearchableDropdown';
import RenderRiderSearchBar from './RenderRiderSearchBar';
import RenderCustomerSearchBar from './RenderCustomerSearchBar';
import RenderCategoriesSearchBar from './RenderCategoriesSearchBar';

const DownArrow = ({
    activeTab,
    selectedDates,
    reportDetails,
    setSelectedDates,
    selectedDownload,
    setSelectedDownload,
    DownloadsItems,
    selectedRestaurantId,
    selectedOutletId,
    setOrderingModeIdTabs,
    setLoyalCashbackType,
    setRatingArray,
    setPaymentMode,
    setOrderRating,
    setOrderingModeId,
    setOrderStatus,
    setPaymentCustomerOrder,
    setOrderRefundStatus,
    selectedUser,
    setSelectedUser,
    allSelectedRiders,
    setAllSelectedRiders,
    selectedCustomer,
    setSelectedCustomer,
    allSelectedCustomer,
    setAllSelectedCustomer,
    selectedCategories,
    setSelectedCategories,
    allSelectedCategories,
    setAllSelectedCategories,
    dateError,
    reportResponse,
}) => {
    const methods = useForm({
        defaultValues: {
            customer_ids: [],
            do_add_user: { value: true },
            do_add_customer: { value: true },
            do_add_cateogries: { value: true },
        },
    });
    const orderingModes = useSelector((state) => state.outlet.orderingModes);
    const [activeReport, setActiveReport] = useState(null);
    const [filterSelections, setFilterSelections] = useState({});
    const [appliedFilter, setAppliedFilter] = useState(null);
    // const [ratingForOrder, setRatingForOrder] = useState(null);
    const screenHeight = useWindowSize()?.width;
    const fetchReportsList = async () => {
        try {
            const currentReport = reportResponse?.reports.find((report) => report.label === activeTab.toLowerCase());
            setActiveReport(currentReport);

            const passableFilters = [
                'cashback types',
                'payment modes',
                'refund status',
                'order status',
                'payment methods',
                'ordering mode tabs',
            ];
            // Initialize filter selections
            if (currentReport?.filters) {
                const initialSelections = {};
                currentReport.filters.forEach((filter) => {
                    if (filter.type === 'dropdown') {
                        if (
                            (filter.title === 'ordering modes' || filter.title === 'ordering mode') &&
                            orderingModes.length > 0
                        ) {
                            // Initialize ordering modes selection with the first option
                            initialSelections[filter.title] = {
                                label: orderingModes[0].display_name,
                                value: orderingModes[0].type,
                                id: orderingModes[0]?.id,
                            };

                            if (filter?.title === 'ordering modes' || filter?.title === 'ordering mode') {
                                setOrderingModeId(orderingModes[0]?.id);
                            }
                        } else if (filter.options?.length > 0) {
                            // Initialize other dropdown selections
                            initialSelections[filter.title] = {
                                label:
                                    filter.options[0].charAt(0).toUpperCase() +
                                    filter.options[0].slice(1).toLowerCase().replace(/_/g, ' '),
                                value: filter.options[0],
                            };
                            if (passableFilters.includes(filter.title.toLowerCase())) {
                                const firstOption = filter.options[0];

                                switch (filter.title.toLowerCase()) {
                                    case 'ordering mode tabs':
                                        setOrderingModeIdTabs(firstOption);
                                        break;
                                    case 'cashback types':
                                        setLoyalCashbackType(firstOption);
                                        break;
                                    case 'payment modes':
                                        setPaymentMode(firstOption);
                                        break;
                                    case 'refund status':
                                        setOrderRefundStatus(firstOption);
                                        break;
                                    case 'order status':
                                        setOrderStatus(firstOption);
                                        break;
                                    case 'payment methods':
                                        setPaymentCustomerOrder(firstOption);
                                        break;
                                    default:
                                        alert('Unknown filter');
                                        break;
                                }
                            }
                        }
                    }
                });
                setFilterSelections(initialSelections);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchReportsList();
    }, [activeTab, selectedRestaurantId]);

    // useEffect(() => {
    //     switch (ratingForOrder) {
    //         case 'good':
    //             setOrderRating([4.0, 5.0]);
    //             break;
    //         case 'bad':
    //             setOrderRating([0.0, 2.9]);
    //             break;
    //         case 'average':
    //             setOrderRating([3.0, 3.9]);
    //             break;
    //         case null:
    //             setOrderRating([]);
    //             break;
    //         default:
    //             setOrderRating([]);
    //             break;
    //     }
    // }, [setOrderRating, ratingForOrder]);
    useEffect(() => {
        switch (appliedFilter) {
            case 'good':
                setRatingArray([4.0, 5.0]);
                break;
            case 'bad':
                setRatingArray([0.0, 2.9]);
                break;
            case 'average':
                setRatingArray([3.0, 3.9]);
                break;
            case null:
                setRatingArray([]);
                break;
            default:
                setRatingArray([]);
                break;
        }
    }, [setRatingArray, appliedFilter]);

    const handleFilterSelection = (filterTitle, selection) => {
        switch (filterTitle) {
            // case 'order ratings':
            //     setRatingForOrder(selection.value);
            //     break;
            case 'ordering mode tabs': // Replace with actual filter title
                setOrderingModeIdTabs(selection?.value);
                break;
            case 'refund status': // Replace with actual filter title
                setOrderRefundStatus(selection?.value);
                break;
            case 'cashback types': // Replace with actual filter title
                setLoyalCashbackType(selection?.value);
                break;
            case 'payment modes': // Replace with actual filter title
                setPaymentMode(selection?.value);
                break;
            case 'payment methods ': // Replace with actual filter title
                setPaymentCustomerOrder(selection?.value);
                break;
            case 'delivery ratings': // Replace with actual filter title
                setAppliedFilter(selection?.value);
                break;
            case 'order status': // Replace with actual filter title
                setOrderStatus(selection?.value);
                break;
            case 'rider ratings': // Replace with actual filter title
                setAppliedFilter(selection?.value);
                break;
            default:
                // Handle other filters if needed
                break;
        }
        // setRatingForOrder(selection.value);
        setOrderingModeId(selection?.id);

        setFilterSelections((prev) => ({
            ...prev,
            [filterTitle]: selection,
        }));
    };

    // Get layout class based on number of filters
    const getLayoutClass = () => {
        const totalFilters = (activeReport?.filters?.length || 0) + 1; // +1 for download

        if (screenHeight < 885) {
            return 'grid grid-cols-1 gap-3';
        } else {
            // For screens 860px and above
            if (totalFilters <= 2) {
                return screenHeight < 885 ? 'flex flex-col mt-3' : 'flex flex-row gap-[3rem] w-ful';
            } else if (totalFilters <= 3) {
                return screenHeight < 885 ? 'flex flex-col mt-3' : 'flex flex-row gap-[3rem] w-ful justify-between';
            } else {
                return 'grid grid-cols-3 items-center justify-center gap-4 xl:grid-cols-2 xl:gap-8';
            }
        }
    };

    // Function to render dropdown filter
    const renderDropdownFilter = (filter) => {
        let menuItems = [];

        if (filter.title === 'ordering modes' || filter.title === 'ordering mode') {
            // Use ordering modes from Redux
            menuItems = orderingModes.map((mode) => ({
                label: mode.display_name,
                value: mode.type,
                id: mode.id,
            }));
        } else {
            // Use options from filter configuration
            menuItems =
                filter.options?.map((option) => ({
                    label: option.charAt(0).toUpperCase() + option.slice(1).toLowerCase().replace(/_/g, ' '),
                    value: option,
                })) || [];
        }

        return (
            <div
                className={screenHeight < 885 ? 'w-full' : 'w-[16rem]'}
                key={filter.title}>
                <span className='paragraph-small-semi-bold text-neutral-600 ml-1'>{filter.title.toUpperCase()}</span>
                <DropdownWithValueLabel
                    selectedItem={filterSelections[filter.title]}
                    menuItems={menuItems}
                    setSelected={(selection) => handleFilterSelection(filter.title, selection)}
                />
                {dateError && <div className='text-red-500 text-sm mb-4'>{dateError}</div>}
            </div>
        );
    };

    return reportDetails && reportDetails.length > 0 ? (
        <div className={`${getLayoutClass()}`}>
            {/* Date filter is common for all reports */}
            {activeReport?.filters?.some((filter) => filter.type === 'date') && (
                <div className={screenHeight < 885 ? 'w-full' : 'w-[16rem]'}>
                    <span className='paragraph-small-semi-bold text-neutral-600 ml-2 sm:ml-0'>DATE SELECTIONS</span>
                    <div className='-ml-1'>
                        <CalenderField
                            setSelectedDates={setSelectedDates}
                            selectedDates={selectedDates}
                        />
                    </div>
                    {dateError && <div className='text-red-500 text-sm mb-4'>{dateError}</div>}
                </div>
            )}

            {/* Render all filters except date */}
            {activeReport?.filters?.map((filter) => {
                if (filter.type === 'dropdown') {
                    return renderDropdownFilter(filter);
                } else if (filter?.title === 'search rider') {
                    return (
                        <FormProvider {...methods}>
                            <RenderRiderSearchBar
                                filter={filter}
                                selectedRestaurantId={selectedRestaurantId}
                                selectedOutletId={selectedOutletId}
                                screenHeight={screenHeight}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                allSelectedRiders={allSelectedRiders}
                                setAllSelectedRiders={setAllSelectedRiders}
                            />
                        </FormProvider>
                    );
                } else if (filter?.title === 'search customer') {
                    return (
                        <FormProvider {...methods}>
                            <RenderCustomerSearchBar
                                filter={filter}
                                selectedRestaurantId={selectedRestaurantId}
                                selectedOutletId={selectedOutletId}
                                screenHeight={screenHeight}
                                selectedCustomer={selectedCustomer}
                                setSelectedCustomer={setSelectedCustomer}
                                allSelectedCustomer={allSelectedCustomer}
                                setAllSelectedCustomer={setAllSelectedCustomer}
                            />
                        </FormProvider>
                    );
                } else if (filter?.title === 'categories') {
                    return (
                        <FormProvider {...methods}>
                            <RenderCategoriesSearchBar
                                filter={filter}
                                selectedRestaurantId={selectedRestaurantId}
                                selectedOutletId={selectedOutletId}
                                screenHeight={screenHeight}
                                selectedCategories={selectedCategories}
                                setSelectedCategories={setSelectedCategories}
                                allSelectedCategories={allSelectedCategories}
                                setAllSelectedCategories={setAllSelectedCategories}
                            />
                        </FormProvider>
                    );
                }
                return null;
            })}

            {/* Download options - common for all reports */}
            <div className={screenHeight < 885 ? 'w-full' : 'w-[16rem]'}>
                <span className='paragraph-small-semi-bold text-neutral-600 ml-1'>Download PDF/CSV</span>
                <div>
                    <DropdownWithValueLabel
                        selectedItem={selectedDownload}
                        menuItems={DownloadsItems}
                        setSelected={setSelectedDownload}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className='w-full h-[80px] flex items-center justify-center'>
            <ReportCardLoader />
        </div>
    );
};

export default DownArrow;
