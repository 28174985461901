import React, { useEffect, useState } from 'react';
import Card from './Card';
import SliderDashboard from 'react-slick';
import { CardDescription } from './CardComponent';
import { ReactComponent as DineInIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as OrdersIcon } from '../../../Assets/orders.svg';
import { ReactComponent as RiderIcon } from '../../../Assets/riders.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import APIV5 from '../../../api/axios/APIV5';

export default function ServiceTypeSales(props) {
    const { settingsDashboardSlider, numberOfOutlet, selectedDates, outletId, defaultParams, selectedRestaurantId } =
        props;
    const [serviceTypeSalesData, setServiceTypeSalesData] = useState([]);

    const fetchServiceTypeSalesData = async () => {
        const defaultParamsData = defaultParams();
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/total-service-sales`, {
                params: {
                    ...defaultParamsData,
                    outlet_ids: [outletId],
                },
            });
            setServiceTypeSalesData(response?.total_service_sales || []);
        } catch (error) {
            console.error('Error fetching platform sales data:', error);
        }
    };

    useEffect(() => {
        fetchServiceTypeSalesData();
    }, [selectedDates, outletId]);

    const getIcon = (displayName) => {
        switch (displayName.toLowerCase()) {
            case 'delivery':
                return (
                    <RiderIcon
                        height={24}
                        width={24}
                        stroke='#ffffff'
                    />
                );
            case 'pickup':
                return (
                    <OrdersIcon
                        height={24}
                        width={24}
                        stroke='#ffffff'
                    />
                );
            case 'qsr':
            case 'fine dine':
            case 'self serve':
            default:
                return (
                    <DineInIcon
                        height={24}
                        width={24}
                        stroke='#ffffff'
                    />
                );
        }
    };

    const transformedData = serviceTypeSalesData.map((item) => ({
        title: item.display_name,
        number: `₹${item.total_sales}`,
        percentage: item.percentage_change.toString(),
        description: (
            <CardDescription
                focusContent={`₹${item.new_sales}`}
                description='You made an extra total sales of :focusContent: this week'
                focusContentColor='text-tertiary-800'
            />
        ),
        information: `Total sales of ${numberOfOutlet} outlets`,
        positive: item.percentage_change >= 0,
        icon: getIcon(item.display_name),
    }));

    const isMobileScreen = IsMobileScreen();

    const placeholderData = [
        {
            title: 'No content available',
            number: '₹0',
            percentage: '0%',
            description: 'No data to display',
            information: 'No data available',
            positive: false,
            icon: (
                <DineInIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'No content available',
            number: '₹0',
            percentage: '0%',
            description: 'No data to display',
            information: 'No data available',
            positive: false,
            icon: (
                <OrdersIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'No content available',
            number: '₹0',
            percentage: '0%',
            description: 'No data to display',
            information: 'No data available',
            positive: false,
            icon: (
                <RiderIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
    ];

    const dataToDisplay = serviceTypeSalesData.length > 0 ? transformedData : placeholderData;

    return (
        <>
            <div className='px-8 lg:px-4 md:px-0 md:pb-2'>
                <div className='paragraph-large-medium text-black md:px-4 mt-4 lg:mt-4 mb-2 md:mb-0 md:pb-2 md:mt-0'>
                    Service type sales
                </div>
                {!isMobileScreen ? (
                    <div className='-mx-[11px] lg:-mx-0'>
                        {dataToDisplay.map((el, index) => (
                            <div
                                className='inline-block mx-2.5 my-2 max-w-[303px] w-full lg:w-1/2 lg:max-w-full lg:mx-0 lg:even:pl-1 lg:odd:pr-1 lg:my-1'
                                key={index}>
                                <Card {...el} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <SliderDashboard {...settingsDashboardSlider}>
                            {dataToDisplay.map((el, index) => (
                                <div
                                    className='inline-block mx-2.5 my-2 md:mx-0 md:mb-0 pb-1.5'
                                    key={index}>
                                    <Card {...el} />
                                </div>
                            ))}
                        </SliderDashboard>
                    </div>
                )}
            </div>
        </>
    );
}
