import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as RiderIcon } from '../../Assets/riders.svg';
import { ReactComponent as OrderIcon } from '../../Assets/order.svg';
import { ReactComponent as CashIcon } from '../../Assets/cash.svg';
import { ReactComponent as SelectIcon } from '../../Assets/select.svg';
import { ReactComponent as CloseIcon } from '../../Assets/close.svg';
import { useNavigate } from 'react-router-dom';
import SliderDashboard from 'react-slick';
import { useWindowSize } from '@uidotdev/usehooks';
import Card from '../../Components/Card/Card';
// import RatingGuide from '../../Components/RatingGuide/RatingGuide';
import Table from './Components/Table';
import { format } from 'date-fns';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { IsMobileScreen } from '../../Constants/Constants';
import APIV5 from '../../api/axios/APIV5';
import CardDescription from '../../Components/Card/CardDescription';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../Components/Buttons/Button';
import Header from './Components/Header';
import CardContentLoader from './Components/CardContenLoader';
import PaginationWithNumber from '../../Components/Pagination/PaginationWithNumber';
import AddWaiter from './AddWaiter';
import APIV1 from '../../api/axios/APIV1';

const CreateWaiter = () => {
    const today = new Date();
    const screenHeight = useWindowSize().height;
    const isMobileScreen = IsMobileScreen();

    const settingsDashboardSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: 'dashboard-slide',
    };
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const navigate = useNavigate();
    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    const [showWaiterPage, setShowWaiterPage] = useState(false);
    const [isEditTable, setIsEditTable] = useState(false);
    const [popupRiderDetails, setPopupRiderDetails] = useState();
    const [waiterDetails, setWaiterDetails] = useState([]);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [showCancelDelete, setShowCancelDelete] = useState(true);
    const [selectedDates, setSelectedDates] = useState([today, today]);
    const [addOrEdit, setAddOrEdit] = useState('');
    const [isEditUser, setIsEditUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCardLoading, setIsCardLoading] = useState(false);
    const [cardDetails, setCardDetails] = useState({});
    const [waiterSaved, setWaiterSaved] = useState(false);
    const [waiterDetailsEdit, setWaiterDetailsEdit] = useState([]);
    const [isLoadingEditRider, setIsLoadingEditRider] = useState(false);
    const [appliedFilter, setAppliedFilter] = useState(null);
    const [updatedpaginationStatus, setUpdatedpaginationStatus] = useState({});
    const [ratingArray, setRatingArray] = useState([]);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [checkedItems, setCheckedItems] = useState([]);
    const [uploadedImageDetails, setUploadedImageDetails] = useState('');
    const [paginationChanged, setPaginationChanged] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const handleClickAddRider = () => {
        setShowWaiterPage(!showWaiterPage);
        setUploadedImageDetails('');
        setPopupRiderDetails();
        setAddOrEdit('add');
    };

    const handleClickName = async (value) => {
        navigate(`/rider/rider-details/${value}`);
    };

    const handleClickEditTab = () => {
        setIsEditTable(!isEditTable);
        setShowCheckbox(false);
        setShowCancelDelete(true);
        setAddOrEdit('edit');
        setIsEditUser(!isEditUser);
    };

    const handleBulkSelect = () => {
        setIsEditTable(false);
        setShowCheckbox(!showCheckbox);
        setShowCancelDelete(!showCancelDelete);
    };

    useEffect(() => {
        if (popupRiderDetails) {
            setShowWaiterPage(true);
            setIsEditTable(true);
        }
    }, [popupRiderDetails]);

    const handleClickCancel = () => {
        setShowCancelDelete(!showCancelDelete);
        setShowCheckbox(false);
        setCheckedItems([]);
    };

    const defaultParams = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        return {
            rating: { from: ratingArray[0]?.toFixed(1), to: ratingArray[1]?.toFixed(1) },
            filters: {
                from_date: formatDate(from_date),
                to_date: formatDate(to_date),
            },
        };
    };

    const fetchRiderDetail = async (params) => {
        setIsLoading(true);
        try {
            // const defaultParamsData = defaultParams();
            const response = await APIV1.get(
                `/restaurants/${selectedRestaurantId}/waiters`
                // {
                //     params: {
                //         ...params,
                //         ...defaultParamsData,
                //         per_page: params.per_page ?? updatedpaginationStatus.resultPerPage,
                //         page: params.page ?? updatedpaginationStatus.selectedPage,
                //     },
                // }
            );
            setWaiterDetails(response?.waiter_list);

            // setPaginationState((prevState) => {
            //     return {
            //         ...prevState,
            //         lastPage: response.riders.last_page,
            //         selectedPage: response.riders.current_page,
            //     };
            // });
            setIsLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const updatePaginationState = (updates) => {
        setUpdatedpaginationStatus(updates);
        const newSelectedPage =
            updates.selectedPage !== undefined ? updates.selectedPage : paginationState.selectedPage;
        const params = {
            page: updates.selectedPage ?? paginationState.selectedPage,
            per_page: updates.resultPerPage ?? paginationState.resultPerPage,
            rating: ratingArray,
        };

        fetchRiderDetail(params, () => {
            if (newSelectedPage === paginationState.selectedPage) {
                setPaginationState((prevState) => ({
                    ...prevState,
                    per_page: updates.resultPerPage ?? paginationState.resultPerPage,
                    selectedPage: newSelectedPage,
                    ...updates,
                }));
            }
        });
    };

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            updatePaginationState(data);
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }

        updatePaginationState({ ...data, selectedPage: 1 });
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    useEffect(() => {
        switch (appliedFilter) {
            case 'bad':
                setRatingArray([0.0, 2.9]);
                break;
            case 'average':
                setRatingArray([3.0, 3.9]);
                break;
            case 'good':
                setRatingArray([4.0, 5.0]);
                break;
            case null:
                setRatingArray([]);
                break;
            default:
                setRatingArray([]);
                break;
        }
    }, [appliedFilter]);

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            return;
        }
        setIsLoading(true);
        const params = {
            rating: ratingArray,
        };
        fetchRiderDetail(params);
    }, [selectedDates, ratingArray, waiterSaved]);

    useEffect(() => {
        setPaginationChanged(true);
        const timer = setTimeout(() => {
            setPaginationChanged(false);
        }, 500);
        return () => clearTimeout(timer);
    }, [paginationState]);

    const fetchRiderCardDetails = async (params) => {
        setIsCardLoading(true);
        try {
            const response = await APIV5.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/riders-total-details`,
                {
                    params: { ...params, ...defaultParams() },
                }
            );
            setCardDetails(response.riders);
            setIsCardLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        setIsCardLoading(true);
        fetchRiderCardDetails();
    }, [selectedDates]);

    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            setCheckedItems([...checkedItems, id]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== id));
        }
    };

    const handleDeleteBulkSelect = async (rating) => {
        const params = {
            page: 1,
            per_page: updatedpaginationStatus.resultPerPage,
        };

        try {
            const response = await APIV1.delete(`restaurants/${selectedRestaurantId}/waiters/${checkedItems}`);
            {
                response.success === true && fetchRiderDetail((rating, params));
            }
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const handleClickDelete = () => {
        handleDeleteBulkSelect();
        setCheckedItems([]);
    };

    useEffect(() => {
        if (checkedItems.length > 0) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [checkedItems]);

    const fetchEditWaiterDetails = async (riderId) => {
        setIsLoadingEditRider(true);
        try {
            const response = await APIV1.get(`restaurants/${selectedRestaurantId}/waiters/${riderId}`);
            setWaiterDetailsEdit(response);
            setIsLoadingEditRider(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const riderCardDetails = [
        {
            title: 'Total Waiters',
            highlightedTitle: cardDetails && cardDetails.total_riders ? cardDetails.total_riders : 'N/A',
            description: (
                <CardDescription
                    description={`Number of registered riders :focusContent: for this week`}
                    focusContent='active'
                    focusContentColor='#3D8C82'
                />
            ),
            icon: (
                <RiderIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Total expenditure',
            highlightedTitle:
                cardDetails && cardDetails.total_expenditure !== undefined
                    ? `₹${cardDetails.total_expenditure.toFixed(2)}/-`
                    : 'N/A',
            description: (
                <CardDescription
                    description={`Total money spent on delivering orders with :focusContent: riders for this week`}
                    focusContent={cardDetails && cardDetails.total_riders ? cardDetails.total_riders : '0'}
                    focusContentColor='#3D8C82'
                />
            ),
            icon: (
                <CashIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Total orders delivered',
            highlightedTitle:
                cardDetails && cardDetails.total_orders_delivered !== undefined
                    ? cardDetails.total_orders_delivered
                    : 'N/A',
            description: (
                <CardDescription
                    description={`Number of orders delivered by :focusContent: waiter for this week `}
                    focusContent={cardDetails && cardDetails.total_riders ? cardDetails.total_riders : '0'}
                    focusContentColor='#3D8C82'
                />
            ),
            icon: (
                <OrderIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        // {
        //     title: 'Average waiter rating',
        //     description: (
        //         <CardDescription
        //             description={`Waiter rating received was :focusContent: by ${
        //                 cardDetails && cardDetails?.average_by_customers !== undefined
        //                     ? cardDetails?.average_by_customers
        //                     : 'N/A'
        //             } customers for this week`}
        //             focusContent={
        //                 cardDetails && cardDetails.average_rider_rating >= 4
        //                     ? 'good'
        //                     : cardDetails.average_rider_rating >= 3
        //                       ? 'average'
        //                       : 'bad'
        //             }
        //             focusContentColor={
        //                 cardDetails && cardDetails.average_rider_rating >= 4
        //                     ? '#3D8C82'
        //                     : cardDetails.average_rider_rating >= 3
        //                       ? '#FFA704'
        //                       : '#EF4444'
        //             }
        //         />
        //     ),
        //     highlightedTitle:
        //         cardDetails && cardDetails.average_rider_rating !== undefined
        //             ? cardDetails.average_rider_rating.toFixed(1)
        //             : 'N/A',
        //     ratingCard: true,
        //     icon: (
        //         <RiderIcon
        //             height={24}
        //             width={24}
        //             stroke='#ffffff'
        //         />
        //     ),
        // },
    ];
    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className='px-8 pb-[70px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                    style={{ minHeight: screenHeight - 50 }}>
                    {(!isMobileScreen || !showWaiterPage) && (
                        <div>
                            <Header
                                RiderIcon={RiderIcon}
                                LargePrimaryButton={LargePrimaryButton}
                                handleClickAddRider={handleClickAddRider}
                                handleClickEditTab={handleClickEditTab}
                                setSelectedDates={setSelectedDates}
                                selectedDates={selectedDates}
                                setAppliedFilter={setAppliedFilter}
                                handleBulkSelect={handleBulkSelect}
                                showCancelDelete={showCancelDelete}
                                handleClickCancel={handleClickCancel}
                                handleClickDelete={handleClickDelete}
                                isDisable={isDisable}
                                isEditTable={isEditTable}
                                ratingArray={ratingArray}
                                page={'rider'}
                                isCalenderField={true}
                            />

                            {!isMobileScreen ? (
                                <div className='-mx-[11px] mb-4 lg:-mx-[1px]'>
                                    {riderCardDetails.length > 0 &&
                                        riderCardDetails.map((el, index) => (
                                            <div
                                                key={index}
                                                className='max-w-[303px] w-full inline-block mx-2.5 mt-4 lg:max-w-full lg:w-1/2 lg:mx-0 even:lg:pl-1 odd:lg:pr-1 lg:mt-[9px]'>
                                                {isCardLoading ? (
                                                    <CardContentLoader />
                                                ) : (
                                                    <Card
                                                        title={el.title}
                                                        ratingCard={el.ratingCard}
                                                        heightLightedTitle={el.highlightedTitle}
                                                        tag={el.tag}
                                                        positive={el.positive}
                                                        description={el.description}
                                                        showDetailsPopup={el.showDetailsPopup}
                                                        icon={el.icon}
                                                        cardHeight={el.cardHeight}
                                                        hasAboutDots={el.hasAboutDots}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                <div className='-mx-4'>
                                    <SliderDashboard {...settingsDashboardSlider}>
                                        {riderCardDetails.length > 0 &&
                                            riderCardDetails.map((el, index) => {
                                                return (
                                                    <div key={index}>
                                                        {isCardLoading ? (
                                                            <CardContentLoader />
                                                        ) : (
                                                            <div className='w-[303px] mobile:w-[288px] md:mb-1.5'>
                                                                <Card
                                                                    title={el.title}
                                                                    ratingCard={el.ratingCard}
                                                                    heightLightedTitle={el.highlightedTitle}
                                                                    tag={el.tag}
                                                                    positive={el.positive}
                                                                    description={el.description}
                                                                    showDetailsPopup={el.showDetailsPopup}
                                                                    icon={el.icon}
                                                                    cardHeight={el.cardHeight}
                                                                    hasAboutDots={el.hasAboutDots}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </SliderDashboard>
                                </div>
                            )}

                            <div className='flex flex-row justify-between mb-6 md:mb-0'>
                                {!isMobileScreen &&
                                    (showCancelDelete ? (
                                        <div
                                            className='max-w-[156px] w-full'
                                            onClick={handleBulkSelect}>
                                            <LargePrimaryButton
                                                label='Bulk select'
                                                leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                                leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                            />
                                        </div>
                                    ) : (
                                        <div className='flex flex-row items-center md:w-full justify-between'>
                                            <div
                                                className='max-w-[125px] mr-4 mobile:mr-2 md:w-1/2 md:max-w-full cursor-pointer'
                                                onClick={handleClickCancel}>
                                                <LargeTertiaryButton
                                                    isDefault={false}
                                                    label='Cancel'
                                                    hideLabel='lg:hidden'
                                                    leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                                                    leftIconClick={<CloseIcon stroke='#C4BEED' />}
                                                />
                                            </div>

                                            <div
                                                className='max-w-[192px] md:w-1/2 md:max-w-full'
                                                onClick={handleClickDelete}>
                                                <LargeDestructiveButton
                                                    label='Delete selected'
                                                    leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                                    leftIconClick={<SelectIcon stroke='#D7EDEB' />}
                                                    hideLabel='lg:hidden'
                                                    disabled={isDisable}
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            {/* <div className='flex justify-end md:w-full'>
                                <RatingGuide />
                            </div> */}

                            <Table
                                handleClickName={handleClickName}
                                isEditTable={isEditTable}
                                setPopupRiderDetails={setPopupRiderDetails}
                                showCheckbox={showCheckbox}
                                waiterDetails={waiterDetails}
                                isLoading={isLoading}
                                paginationChanged={paginationChanged}
                                handleCheckboxChange={handleCheckboxChange}
                                checkedItems={checkedItems}
                                fetchEditWaiterDetails={fetchEditWaiterDetails}
                                setAddOrEdit={setAddOrEdit}
                                // setRiderDetails={setRiderDetails}
                                setCheckedItems={setCheckedItems}
                                paginationState={paginationState}
                                fetchRiderDetail={fetchRiderDetail}
                            />

                            {!_.isEmpty(waiterDetails) && (
                                <div className='pt-4'>
                                    <PaginationWithNumber
                                        lastPage={paginationState.lastPage}
                                        selectedPage={paginationState.selectedPage}
                                        setSelectedPage={(value) => {
                                            handlePagination({ selectedPage: value }, 'selectedPage');
                                        }}
                                        setResultPerPage={(value) =>
                                            handlePagination({ resultPerPage: value }, 'resultPerPage')
                                        }
                                        selectedResultPerpage={paginationState.resultPerPage}
                                        isLoading={isLoading}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {showWaiterPage && (
                        <AddWaiter
                            handleClickAddRider={handleClickAddRider}
                            popupRiderDetails={popupRiderDetails}
                            setShowWaiterPage={setShowWaiterPage}
                            addOrEdit={addOrEdit}
                            isEditUser={isEditUser}
                            uploadedImageDetails={uploadedImageDetails}
                            setWaiterSaved={setWaiterSaved}
                            waiterDetailsEdit={waiterDetailsEdit}
                            isLoadingEditRider={isLoadingEditRider}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default CreateWaiter;
