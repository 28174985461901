import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MultipleTab } from '../../../../../Components/Tabs/Tabs';
import SetPriceAddOn from './SetPriceAddOn';
import PricingTableAddOn from './PricingTableAddOn';

const LinkToAddOnChoice = () => {
    const { watch } = useFormContext();
    const tabName = ['Set price', 'Pricing table'];
    const [activeTab, setActiveTab] = useState(0);
    // const orderingModes = watch('exposeDish');

    return (
        <div className='max-w-[640px] md:max-w-full'>
            <>
                <div className='flex flex-row items-center mb-6 mt-8'>
                    {tabName.map((el, index) => (
                        <div
                            className='mr-4'
                            key={index}>
                            <MultipleTab
                                label={el}
                                isActive={index === activeTab}
                                onClick={() => setActiveTab(index)}
                            />
                        </div>
                    ))}
                </div>

                {activeTab === 0 ? <SetPriceAddOn activeTab={activeTab} /> : <PricingTableAddOn />}
            </>
        </div>
    );
};

export default LinkToAddOnChoice;
