import React, { useEffect, useState } from 'react';
import Feedback from './Feedback';
import OrderFeedback from './OrderFeedback';
import { Cashbacks, OrderRatingFooterButton, RefundFooterButton } from './Components';
import RefundDetails from '../../../Screen/BillPayment/Components/RefundDetails';
import { useLocation } from 'react-router-dom';

export default function OtherDetails(props) {
    const {
        page,
        cashbacks,
        feedbacks,
        isRiderFeedback,
        handleClickPrint,
        setShowRefundPopup,
        refundDetails,
        rnnCode,
        handleConfirmDeletePopup,
        handleOfferCreate,
        handleClickSelectOffer,
        showOfferpage,
        setCreateOfferDetails,
        offerId,
        toggleCustomerOfferPopup,
        fetchOfferDetails,
        grossTotal,
        selectedLangaugeId,
    } = props;

    const [renderComponent, setRenderComponent] = useState();
    const location = useLocation();

    const formatAmount = (amount) => {
        return amount?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 });
    };

    const redeemedAmount = formatAmount(cashbacks?.cashback_redeemed);

    const earnedAmount = formatAmount(cashbacks?.cashback_earned);

    useEffect(() => {
        switch (page) {
            case 'riderDetails':
            case 'pastOrders':
            case 'customerInformation':
                setRenderComponent(
                    <div>
                        <OrderFeedback feedbacks={feedbacks} />
                        {isRiderFeedback === 'Delivery' ? <Feedback feedbacks={feedbacks} /> : null}
                    </div>
                );

                break;

            case 'failedOrders':
                setRenderComponent();
                break;

            case 'loyaltyCashback':
                setRenderComponent(
                    <div>
                        <Cashbacks
                            redeemedAmount={redeemedAmount}
                            earnedAmount={earnedAmount}
                        />
                        <OrderFeedback feedbacks={feedbacks} />
                        {isRiderFeedback === 'Delivery' ? <Feedback feedbacks={feedbacks} /> : null}
                    </div>
                );

                break;

            case 'orderRating':
                setRenderComponent(
                    <div>
                        <OrderFeedback feedbacks={feedbacks} />
                        {isRiderFeedback === 'Delivery' ? <Feedback feedbacks={feedbacks} /> : null}
                    </div>
                );
                break;

            default:
                <>
                    <OrderFeedback feedbacks={feedbacks} />
                    <Feedback feedbacks={feedbacks} />
                </>;
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {renderComponent}
            {/* {page === 'billPayment' && (
                <div className='mt-5'>
                    <DropdownWithValueLabel
                        menuItems={printerList}
                        setSelected={handleSelection}
                        placeholder='Select desired printer'
                        label='Select printer'
                        // fixedHeight='h-40'
                    />
                </div>
            )} */}
            {page === 'billPayment' && (
                <>
                    <RefundDetails
                        refundDetails={refundDetails}
                        rnnCode={rnnCode}
                    />
                    {location.pathname !== '/loyalty-cashback/transactional-reward-points' && (
                        <RefundFooterButton
                            setShowRefundPopup={setShowRefundPopup}
                            handleClickPrint={handleClickPrint}
                            refundDetails={refundDetails}
                        />
                    )}
                </>
            )}

            {page === 'orderRating' && (
                <OrderRatingFooterButton
                    handleConfirmDeletePopup={handleConfirmDeletePopup}
                    handleOfferCreate={handleOfferCreate}
                    handleClickSelectOffer={handleClickSelectOffer}
                    showOfferpage={showOfferpage}
                    setCreateOfferDetails={setCreateOfferDetails}
                    offerId={offerId}
                    toggleCustomerOfferPopup={toggleCustomerOfferPopup}
                    fetchOfferDetails={fetchOfferDetails}
                    grossTotal={grossTotal}
                />
            )}
        </>
    );
}
