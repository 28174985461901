import React, { useEffect, useState } from 'react';
import SliderAbandonedCarts from 'react-slick';
import { ReactComponent as User } from '../../Assets/user.svg';
import { ReactComponent as Order } from '../../Assets/order.svg';
import { ReactComponent as Credit } from '../../Assets/credits.svg';
import PaginationWithNumber from '../../Components/Pagination/PaginationWithNumber';
import Card from './Components/Card';
import { IsMobileScreen } from '../../Constants/Constants';
import HeaderButtons from './Components/HeaderButtons';
import Table from './Components/Table';
import OrderDetailsPopup from '../../Components/CustomerOrderDetails/OrderDetailsPopup';
import { useWindowSize } from '@uidotdev/usehooks';
import APIV5 from '../../api/axios/APIV5';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import CardLoader from './Components/CardLoader';
import _ from 'lodash';

const AbandonedCarts = () => {
    const today = new Date();

    const [selectedDates, setSelectedDates] = useState([today, today]);

    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });

    const [paginationChanged, setPaginationChanged] = useState(false);

    const [abandonedCartsList, setAbandonedCartsList] = useState([]);

    const [abandonedCardsDetails, setAbandonedCardsDetails] = useState([]);
    const [isCardsLoading, setIsCardsLoading] = useState(false);

    const [isAbandonedCartListLoading, setIsAbandonedCartListLoading] = useState(false);

    const [popupOrderId, setPopupOrderId] = useState(null);

    const [popupDetails, setPopupDetails] = useState(null);

    const [isPopupLoading, setIsPopupLoading] = useState(false);

    const [filters, setFilters] = useState({ orderingMode: '', customerType: '' });

    const [filteredOrderingMode, setFilteredOrderingMode] = useState([]);

    const [isNew, setIsNew] = useState(false);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const selectedLangaugeId = useSelector((state) => state.langauge.languageId);
    const orderingModes = useSelector((state) => state.outlet.orderingModes);
    useEffect(() => {
        if (abandonedCartsList.length > 50) {
            setPaginationState((prevState) => ({
                ...prevState,
                resultPerPage: abandonedCartsList.length,
            }));
        }
    }, [abandonedCartsList.length]);
    const card = [
        {
            title: 'Customer type',
            header: `${
                abandonedCardsDetails &&
                abandonedCardsDetails.maximum_carts_abandoned_by_customer &&
                abandonedCardsDetails.maximum_carts_abandoned_by_customer.is_new_customer !== undefined &&
                abandonedCardsDetails.maximum_carts_abandoned_by_customer.is_new_customer !== null
                    ? abandonedCardsDetails.maximum_carts_abandoned_by_customer.is_new_customer === 1
                        ? 'New'
                        : 'Old'
                    : 'N/A'
            }`,

            description: `Maximum carts abandoned by ${
                abandonedCardsDetails &&
                abandonedCardsDetails.maximum_carts_abandoned_by_customer &&
                abandonedCardsDetails.maximum_carts_abandoned_by_customer.is_new_customer !== undefined &&
                abandonedCardsDetails.maximum_carts_abandoned_by_customer.is_new_customer !== null
                    ? abandonedCardsDetails.maximum_carts_abandoned_by_customer.is_new_customer === 1
                        ? 'New'
                        : 'Old'
                    : 'N/A'
            } customers for selected dates`,
            icon: (
                <User
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            highLightedPart: `${
                abandonedCardsDetails &&
                abandonedCardsDetails.maximum_carts_abandoned_by_customer &&
                abandonedCardsDetails.maximum_carts_abandoned_by_customer.is_new_customer !== undefined &&
                abandonedCardsDetails.maximum_carts_abandoned_by_customer.is_new_customer !== null
                    ? abandonedCardsDetails.maximum_carts_abandoned_by_customer.is_new_customer === 1
                        ? 'New'
                        : 'Old'
                    : 'N/A'
            }`,
        },
        {
            title: 'Dish',
            header: `${
                abandonedCardsDetails && abandonedCardsDetails?.dish?.translations
                    ? abandonedCardsDetails?.dish?.translations[selectedLangaugeId]
                    : 'N/A'
            }`,
            description: `Maximum dish found in abandoned carts were ${
                abandonedCardsDetails?.dish?.count
                    ? abandonedCardsDetails?.dish?.count
                    : abandonedCardsDetails?.dish?.translations
                      ? 'N/A'
                      : ''
            } ${
                abandonedCardsDetails?.dish?.translations
                    ? abandonedCardsDetails?.dish?.translations[selectedLangaugeId]
                    : 'N/A'
            } for selected dates`,
            icon: (
                <Order
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            highLightedPart: `${
                abandonedCardsDetails?.dish?.count
                    ? abandonedCardsDetails?.dish?.count
                    : abandonedCardsDetails?.dish?.translations
                      ? 'N/A'
                      : ''
            } ${
                abandonedCardsDetails && abandonedCardsDetails?.dish?.translations
                    ? abandonedCardsDetails?.dish?.translations[selectedLangaugeId]
                    : 'N/A'
            }`,
        },
        {
            title: 'Avg. price',
            header: `₹${
                abandonedCardsDetails && abandonedCardsDetails?.avarage_price
                    ? abandonedCardsDetails?.avarage_price
                    : 'N/A'
            }`,
            description: `Avg. price of abandoned carts recorded for selected dates was ₹${
                abandonedCardsDetails && abandonedCardsDetails?.avarage_price
                    ? abandonedCardsDetails?.avarage_price
                    : 'N/A'
            }`,
            icon: (
                <Credit
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            highLightedPart: `₹${
                abandonedCardsDetails && abandonedCardsDetails?.avarage_price
                    ? abandonedCardsDetails?.avarage_price
                    : 'N/A'
            }`,
        },
    ];

    const [showCustomerCartDetail, setShowCustomerCartDetail] = useState(false);

    const toggleCustomerCartDetailsPopup = () => {
        setPopupOrderId(null);
        setShowCustomerCartDetail(!showCustomerCartDetail);
    };

    const settingsAbandonedCarts = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: 'abandoned-cart-slide',
    };

    const pageHeights = useWindowSize().height;

    const isMobileScreen = IsMobileScreen();

    useEffect(() => {
        setFilteredOrderingMode(orderingModes.filter((el) => el.tab === filters.orderingMode).map((item) => item.id));
    }, [filters.orderingMode]);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const defaultParams = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        return {
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
        };
    };

    //list of adandoned carts
    const fetchAbandonedCartList = async (params) => {
        try {
            setIsAbandonedCartListLoading(true);
            const defaultParamsData = defaultParams();

            const modifiedParams = {
                ...params,
                ...defaultParamsData,
                per_page: params.per_page ?? paginationState.resultPerPage,
                page: params.page ?? paginationState.selectedPage,
            };

            const response = await APIV5.get(`/outlets/${selectedOutletId}/abandoned-carts`, {
                params: modifiedParams,
            });
            setAbandonedCartsList(
                isMobileScreen ? [...abandonedCartsList, ...response.carts.data] : response.carts.data
            );
            setIsAbandonedCartListLoading(false);
            setPaginationState((prevState) => {
                return {
                    ...prevState,
                    lastPage: response.carts.last_page,
                };
            });
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    //function for cards
    const getParams = () => {
        if (!selectedDates.length) {
            const params = {
                filters: {
                    date_range: {
                        from_date: format(new Date(), 'yyyy-MM-dd'),
                        to_date: format(new Date(), 'yyyy-MM-dd'),
                    },
                },
            };

            return params;
        }

        const start_date = format(new Date(Math.min(...selectedDates)), 'yyyy-MM-dd');

        const end_date = format(new Date(Math.max(...selectedDates)), 'yyyy-MM-dd');

        const params = {
            start_date,
            end_date,
        };

        return params;
    };

    const fetchAbandonedCardDetails = async () => {
        try {
            setIsCardsLoading(true);
            const response = await APIV5.get(`/outlets/${selectedOutletId}/abandoned-carts-total`, {
                params: getParams(),
            });
            setAbandonedCardsDetails(response);
            setIsCardsLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };
    useEffect(() => {
        fetchAbandonedCardDetails();
    }, [selectedDates, selectedOutletId]);

    useEffect(() => {
        setPaginationState((prevState) => ({ ...prevState, selectedPage: 1 }));
    }, [selectedDates]);

    useEffect(() => {
        const modifiedParams = {
            page: paginationState.selectedPage,
            per_page: paginationState.resultPerPage,
        };

        if (filters.customerType) {
            modifiedParams.is_new_customer = filters.customerType === 'old' ? '0' : '1';
        }

        if (filters.orderingMode) {
            modifiedParams.ordering_mode_ids = orderingModes
                .filter((el) => el.tab === filters.orderingMode)
                .map((item) => item.id);
        }
        fetchAbandonedCartList(modifiedParams);
    }, [
        selectedDates,
        filters.customerType,
        filters.orderingMode,
        paginationState.selectedPage,
        paginationState.resultPerPage,
        selectedOutletId,
    ]);

    useEffect(() => {
        setPaginationChanged(true);
        const timer = setTimeout(() => {
            setPaginationChanged(false);
        }, 500);
        return () => clearTimeout(timer);
    }, [paginationState]);

    const fetchOrderDetailPopup = async () => {
        try {
            setIsPopupLoading(true);
            const response = await APIV5.get(`/carts/${popupOrderId}/cart-details`);

            setPopupDetails(response.carts);

            setIsPopupLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        if (popupOrderId) {
            setShowCustomerCartDetail(!showCustomerCartDetail);
            fetchOrderDetailPopup(popupOrderId);
        }
        setIsNew(
            abandonedCartsList
                .filter((el) => el.id === popupOrderId)
                .map((item) => item.is_new_customer)
                .join(', ')
        );
    }, [popupOrderId]);

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    const formatPlacedAt = (placedAt) => {
        try {
            return format(parseISO(placedAt), 'dd MMM yyyy');
        } catch (error) {
            return '';
        }
    };

    function formatTime(time) {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours, 10);
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 24 || 24;
        const formattedTime = `${formattedHour}:${minutes} ${period}`;

        return formattedTime;
    }

    const calculateTotalQuantity = (cartItems) =>
        cartItems?.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0) || 0;

    const orderingMode = {
        'dine_in': 'Dine-In',
        'delivery': 'Delivery',
        'takeaway': 'Takeaway',
        'CASH_ON_DELIVERY': 'Cash',
        'CREDIT_CARD/DEBIT_CARD': 'Card',
        'CREDIT_CARD': 'Credit card',
        'DEBIT_CARD': 'Debit card',
        'UPI': 'UPI',
        'UPI_INTENT': 'UPI',
        'BALANCE': 'Balance',
    };

    const orderedPlatform = {
        android: 'Android',
        web_app: 'Web App',
        ios: 'iOS',
        desktop_app: 'Desktop App',
    };

    const popupData = [
        {
            item: 'Order bill name',
            value: popupDetails && popupDetails?.bill_name ? popupDetails?.bill_name : 'NA',
        },
        {
            item: 'Order date',
            value: popupDetails && popupDetails?.order_date ? popupDetails?.order_date : 'NA',
        },
        {
            item: 'Cart active time',
            value: popupDetails && popupDetails?.cart_active_time ? `${popupDetails?.cart_active_time} mins` : 'N/A',
        },
        {
            item: 'Ordered via',
            value: popupDetails && popupDetails?.platform ? orderedPlatform[popupDetails?.platform] : 'N/A',
        },
        {
            item: 'Delivery area',
            value: popupDetails && popupDetails?.delivery_area ? popupDetails?.delivery_area : 'N/A',
        },
        {
            item: 'Order type',
            value: popupDetails && popupDetails?.order_type ? orderingMode[popupDetails?.order_type] : 'NA',
        },
        {
            item: 'Order time',
            value: popupDetails && popupDetails?.order_time ? popupDetails?.order_time : 'NA',
        },
        {
            item: 'Dishes in cart',
            value: popupDetails && popupDetails?.cart_items ? calculateTotalQuantity(popupDetails?.cart_items) : 'NA',
        },
        {
            item: 'Platform',
            value: popupDetails && popupDetails?.platform ? orderedPlatform[popupDetails?.platform] : 'N/A',
        },
    ];

    const otherAmount = [
        { type: 'Gross total', value: popupDetails?.gross_total?.toFixed(2) },
        {
            type: 'Other charges & taxes',
            value: (
                popupDetails?.gst_amount +
                popupDetails?.packaging_charge +
                popupDetails?.convenience_charge
            )?.toFixed(2),
        },
        {
            type: 'Delivery charge',
            value: popupDetails?.delivery_charge?.toFixed(2),
        },
    ];

    const extraCharges = [
        {
            gst_amount: popupDetails?.gst_amount,
            packaging_charge: popupDetails?.packaging_charge,
            convenience_charge: popupDetails?.convenience_charge,
        },
    ];

    return (
        <>
            <div className='bg-[#fafafa]'>
                {(!showCustomerCartDetail || !isMobileScreen) && (
                    <div
                        className='pb-10 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                        style={{ minHeight: isMobileScreen ? 'fit' : pageHeights - 50 }}>
                        <div className='px-8 lg:px-4'>
                            <HeaderButtons
                                setSelectedDates={setSelectedDates}
                                selectedDates={selectedDates}
                                setFilters={setFilters}
                                filters={filters}
                                setPaginationState={setPaginationState}
                                isMobileScreen={isMobileScreen}
                                setAbandonedCartsList={setAbandonedCartsList}
                            />
                        </div>

                        {!isMobileScreen ? (
                            <div className='lg:mx-0 px-[22px] lg:px-4'>
                                {isCardsLoading ? (
                                    [...Array(3)].map((_, index) => (
                                        <div
                                            className='max-w-[400px] w-full inline-block align-top mx-2.5 mt-4 lg:w-1/2 lg:odd:pr-1 lg:even:pl-1 lg:max-w-full lg:mx-0 lg:my-1'
                                            key={index}>
                                            <CardLoader />
                                        </div>
                                    ))
                                ) : (
                                    <div className='grid grid-cols-3 lg:grid-cols-3 md:grid-cols-1 gap-5 align-top px-2.5 mt-2 lg:w-full credit-box lg:px-0 lg:odd:pr-1 lg:even:pl-1 mg:odd:pr-0 md:even:pl-0 md:w-full'>
                                        {card.map((el, index) => (
                                            <div
                                                key={index}
                                                className='h-full'>
                                                <Card {...el} />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : isCardsLoading ? (
                            <div className='flex flex-row'>
                                {[...Array(3)].map((_, index) => (
                                    <div
                                        className='md:mb-[5px] mr-4'
                                        key={index}>
                                        <CardLoader />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div>
                                <SliderAbandonedCarts {...settingsAbandonedCarts}>
                                    {card.map((el, index) => {
                                        return (
                                            <div
                                                className='px-4 pb-[5px] h-full '
                                                key={index}>
                                                <Card {...el} />
                                            </div>
                                        );
                                    })}
                                </SliderAbandonedCarts>
                            </div>
                        )}

                        <div className='px-8 lg:px-4'>
                            <Table
                                abandonedCartsList={abandonedCartsList}
                                toggleCustomerCartDetailsPopup={toggleCustomerCartDetailsPopup}
                                setPopupOrderId={setPopupOrderId}
                                paginationChanged={paginationChanged}
                                isAbandonedCartListLoading={isAbandonedCartListLoading}
                                paginationState={paginationState}
                                fetchAbandonedCartList={fetchAbandonedCartList}
                                setPaginationState={setPaginationState}
                            />

                            {!isMobileScreen && (
                                <PaginationWithNumber
                                    lastPage={paginationState.lastPage}
                                    selectedPage={paginationState.selectedPage}
                                    setSelectedPage={(value) => {
                                        handlePagination({ selectedPage: value }, 'selectedPage');
                                    }}
                                    setResultPerPage={(value) => {
                                        handlePagination({ resultPerPage: value }, 'resultPerPage');
                                    }}
                                    selectedResultPerpage={paginationState.resultPerPage}
                                    isLoading={isAbandonedCartListLoading}
                                    totalItems={abandonedCartsList?.length}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
            {showCustomerCartDetail && popupOrderId && (
                <OrderDetailsPopup
                    isNew={isNew}
                    isPaid={false}
                    isPopupLoading={isPopupLoading}
                    cartToken={popupDetails?.cart_token}
                    orderedDishes={popupDetails?.cart_items}
                    extraCharges={extraCharges}
                    otherAmount={otherAmount}
                    netTotal={popupDetails?.net_total}
                    grossTotal={popupDetails?.gross_total}
                    code={
                        popupDetails && popupDetails?.last_four_character_order_label
                            ? popupDetails?.last_four_character_order_label
                            : 'N/A'
                    }
                    orderDetails={popupData}
                    selectedLangaugeId={selectedLangaugeId}
                    handleClickClose={toggleCustomerCartDetailsPopup}
                    page='abandonedCarts'
                    popupDetails={popupDetails}
                />
            )}
        </>
    );
};

export default AbandonedCarts;
