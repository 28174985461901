import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SearchableDropdown from '../../../Components/SearchableDropdown/SearchableDropdown';
import _ from 'lodash';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import Tag from '../../../Components/Tag/Tag';
import APIV2 from '../../../api/axios/APIV2';

const RenderCustomerSearchBar = ({
    filter,
    selectedRestaurantId,
    selectedOutletId,
    screenHeight,
    setSelectedCustomer,
    selectedCustomer,
    allSelectedCustomer,
    setAllSelectedCustomer,
}) => {
    const {
        watch,
        setValue,
        setError,
        control,
        formState: { errors = {} }, // Provide a default empty object
        clearErrors,
    } = useFormContext() || {}; // Provide a default empty object if useFormContext returns undefined

    const [searchQuery, setSearchQuery] = useState('');
    const [isLoadingMenuItems, setIsLoadingMenuItems] = useState(false);

    const formatUserList = (list) => {
        let transformedList = list.map((el) => ({
            label: `${el.name} - ${el.mobile_number}`,
            value: el.id,
            ...el,
        }));

        const customerIds = watch('customer_ids') || [];
        const selectedIds = customerIds.map((el) => el?.value).filter(Boolean);

        return transformedList.filter((el) => !selectedIds.includes(el.value));
    };
    const fetchUserList = async (params) => {
        try {
            setIsLoadingMenuItems(true);
            const response = await APIV2.get(`restaurants/${selectedRestaurantId}/customers`, {
                params,
            });
            const formattedList = formatUserList(response?.customers?.data);
            setAllSelectedCustomer(formattedList);
        } catch (error) {
            console.error('Error fetching user list:', error);
            setAllSelectedCustomer([]);
        } finally {
            setIsLoadingMenuItems(false);
        }
    };

    const handleSelect = (selectedItem, fieldOnChange) => {
        if (!selectedItem || selectedCustomer) return;

        setSelectedCustomer(selectedItem);
        setValue('customer_ids', [selectedItem]);
        clearErrors('customer_ids');
        fieldOnChange([selectedItem]);

        setAllSelectedCustomer((prevItems) => prevItems.filter((item) => item.value !== selectedItem.value));
    };

    const handleClickClose = (item) => {
        if (!item) return;

        setSelectedCustomer(null);
        setValue('customer_ids', []);
        setError('customer_ids', { message: 'Please select user.' });

        setAllSelectedCustomer((prevUserMenuItems) => [...prevUserMenuItems, item]);
    };

    const onCompleteTyping = (query) => {
        setSearchQuery(query);
        if (query.length) {
            handleFetchList(query);
        } else {
            setAllSelectedCustomer([]);
        }
    };

    const handleFetchList = useCallback(
        _.debounce(async (search_query) => {
            fetchUserList({ search_query });
        }, 1500),
        [selectedRestaurantId, selectedOutletId]
    );

    useEffect(() => {
        if (watch('do_add_customer')?.value && !searchQuery) {
            fetchUserList({ search_query: '' });
        }
    }, [watch('do_add_customer')?.value]);
    return (
        <div className={screenHeight < 885 ? 'w-full' : 'w-[16rem] mt-4'}>
            <span className='paragraph-small-semi-bold text-neutral-600 ml-1'>{filter.title.toUpperCase()}</span>
            <Controller
                render={({ field }) => (
                    <SearchableDropdown
                        onCompleteTyping={onCompleteTyping}
                        menuItems={allSelectedCustomer}
                        placeholder={`Search ${filter.title}...`}
                        handleSelect={(selection) => handleSelect(selection, field.onChange)}
                        setIsLoadingMenuItems={setIsLoadingMenuItems}
                        isLoadingMenuItems={isLoadingMenuItems}
                        disabled={selectedCustomer}
                        // zIndex='z-99999'
                        fixedHeight='max-h-[200px]'
                    />
                )}
                name='customer_ids'
                control={control}
                rules={watch('do_add_customer')?.value ? { required: 'Please select user' } : { required: false }}
            />

            {errors?.customer_ids && <ErrorMessage errors={errors.customer_ids} />}

            <div className='align-top mt-1'>
                {selectedCustomer && (
                    <div className='mr-2 inline-block'>
                        <Tag
                            tag={selectedCustomer.label || 'Unknown'}
                            onClose={() => handleClickClose(selectedCustomer)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default RenderCustomerSearchBar;
