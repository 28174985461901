import React, { useEffect, useState } from 'react';
import { ReactComponent as SwiggyIcon } from '../../../../Assets/swiggy.svg';
import { ReactComponent as ZomatoIcon } from '../../../../Assets/zomato.svg';
import ListViewOfKOT from '../../Components/ListViewOfKOTSection/ListViewOfKOTSection';
import { IsMobileScreen } from '../../../../Constants/Constants';
import KOT from '../../../../Components/KOT/KOT';
import RiderDetailsPopup from './Popup/RiderDetails/RiderDetailsPopup';
import CancelOrderPopup from './Popup/CancelOrder/CancelOrderPopup';
import AcceptOrderPopup from './Popup/AcceptOrderPopup/AcceptOrderPopup';
import AssignRiderPopup from '../../Popup/AssignRider/AssignRiderPopup';
import ReadyOrderPopup from './Popup/ReadyOrder/ReadyOrderPopup';
import OrderDetailsPopup from '../../../../Components/CustomerOrderDetails/OrderDetailsPopup';
import OrdersDetailsPopup from '../../Popup/OrdersDetailsPopup';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { changeStatus, fetchOrderDetail, fetchOrderingModeCount, fetchOrders } from '../../Helper/functions';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';
import { TableBlankComponentView } from '../../../../Components/BlankComponent/TableBlankComponentView';

export default function LiveOrder(props) {
    const {
        isKotView,
        isFetchingOrders,
        setAllOrderingModeCounts,
        setIsFetchingOrder,
        orderType,
        setLoading,
        selectedOrderingMode,
        selectedFilter,
        paginationState,
        setPaginationState,
        resetState,
    } = props;
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedOutletDetails = useSelector((state) => state.outlet.selectedOutlet);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const currentDate = format(new Date(), 'yyyy-MM-dd');

    const [orders, setOrders] = useState([]);
    const [showPopup, setShowPopup] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [orderCancleFrom, setOrderCancleFrom] = useState('restaurant');
    const [kitchenPreparationTime, setKitchenPreparationTime] = useState(null);
    const [selectedOrderDetail, setSelectedOrderDetail] = useState({});
    const [hasRiderAssigned, setHasRiderAssigned] = useState(false);
    const [orderItems, setOrderItems] = useState('');
    const [assignRider, setAssignRider] = useState(false);
    const [cancelRider, setCancelRider] = useState(false);
    const [fullFillStatus, setFullFillStatus] = useState(false);
    const [kotOrderDetail, setKotOrderDetail] = useState('');
    const isMobileScreen = IsMobileScreen();
    const fetchData = async () => {
        setIsFetchingOrder(true);
        const response = await fetchOrders({
            orderType,
            startDate: currentDate,
            endDate: currentDate,
            selectedOrderingMode,
            selectedOutletId,
            selectedRestaurantId,
            selectedFilter,
            perPage: paginationState.perPage,
            page: paginationState.currentPage,
        });
        if (response?.order_collection?.orders) {
            setPaginationState({
                currentPage: response.order_collection.orders.current_page,
                lastPage: response.order_collection.orders.last_page,
                perPage: response.order_collection.orders.per_page,
            });
            setOrders(response?.order_collection?.orders?.data ?? response);
        } else {
            console.error('Unexpected response structure:', response);
            setOrders([]);
        }
        setOrders(response?.orders?.data ?? response);
        setSelectedOrder({ ...response?.orders?.data?.[0] });
        setIsFetchingOrder(false);
    };

    const orderingModeCount = async () => {
        const response = await fetchOrderingModeCount({
            orderType,
            startDate: currentDate,
            endDate: currentDate,
            selectedOrderingMode,
            selectedOutletId,
            selectedRestaurantId,
            selectedFilter,
            setLoading,
            perPage: paginationState.perPage,
            page: paginationState.currentPage,
        });
        if (response) {
            setAllOrderingModeCounts(response?.order_counts);
        } else {
            console.error('Unexpected response structure:', response);
        }
    };

    useEffect(() => {
        fetchData();
        orderingModeCount();
    }, [
        selectedOrderingMode,
        selectedFilter,
        paginationState.currentPage,
        paginationState.perPage,
        selectedRestaurantId,
        selectedOutletId,
    ]);
    const onConfirmCancel = async () => {
        const isResponseSuccess = await changeStatus({
            orderId: selectedOrder?.order_id,
            status: 'restaurant_cancelled',
            canceledBy: orderCancleFrom,
            kitchenPreparationTime: 0,
        });
        if (isResponseSuccess) {
            resetState();
            fetchData();
        }
        setShowPopup(null);
    };
    const onConfirmAccept = async () => {
        if (kitchenPreparationTime !== null || kitchenPreparationTime.length !== 0) {
            const isResponseSuccess = await changeStatus({
                orderId: selectedOrder?.order_id,
                status: 'restaurant_accepted',
                canceledBy: orderCancleFrom,
                kitchenPreparationTime: kitchenPreparationTime,
            });
            if (isResponseSuccess) {
                resetState();
                fetchData();
                setShowPopup(null);
            }
            if (
                !(
                    selectedOrderDetail?.rider ||
                    selectedOrderDetail?.elt_order ||
                    selectedOrderDetail?.dunzo_order ||
                    selectedOrderDetail?.wefast_order ||
                    selectedOrderDetail?.scout_order ||
                    selectedOrderDetail?.shadowfax_order ||
                    selectedOrderDetail?.pidge_order
                ) &&
                selectedOrderDetail?.ordering_mode === 'delivery' &&
                !selectedOrderDetail?.outlet?.settings?.enable_rider_auto_assignment_by_priority &&
                !selectedOrderDetail?.outlet?.settings?.enable_rider_auto_assignment_by_preference
            ) {
                setShowPopup('riderAssignment');
            }
        }
    };

    useEffect(() => {
        if (selectedOrder?.order_id) {
            const fetchData = async () => {
                const response = await fetchOrderDetail(selectedOrder?.order_id);
                setSelectedOrderDetail(response);
            };
            fetchData();
        }
        setAssignRider(false);
    }, [selectedOrder, assignRider, cancelRider]);

    useEffect(() => {
        if (orders?.length > 0) {
            const fetchAllOrders = async () => {
                try {
                    const allOrderDetails = await Promise.all(
                        orders.map(async (order) => {
                            const response = await fetchOrderDetail(order.order_id);
                            return { ...order, details: response };
                        })
                    );

                    setOrderItems(allOrderDetails);
                } catch (error) {
                    console.error('Error fetching order details:', error);
                }
            };

            fetchAllOrders();
        }
    }, [orders, assignRider, cancelRider]);

    const onReady = async () => {
        const isResponseSuccess = await changeStatus({
            orderId: selectedOrder?.order_id,
            status: 'restaurant_ready',
            canceledBy: 'restaurant',
            kitchenPreparationTime: 0,
        });

        if (isResponseSuccess) {
            resetState();
            fetchData();
        }
    };

    const onComplete = async () => {
        const isResponseSuccess = await changeStatus({
            orderId: selectedOrder?.order_id,
            status: 'delivered',
            canceledBy: 'restaurant',
            kitchenPreparationTime: 0,
        });

        if (isResponseSuccess) {
            resetState();
            fetchData();
        }
    };
    return (
        <>
            {isFetchingOrders ? (
                <TableLoader
                    hasSwitch={false}
                    columns={5}
                />
            ) : (
                <>
                    {isKotView && !isMobileScreen && (
                        <div className='md:contents -mx-2.5 pageContentSmall:-mx-1 pageContentSmall:pt-2 largeTablet:mx-0 pb-20'>
                            {orderItems ? (
                                orderItems.map((el, index) => (
                                    <div
                                        className='w-[410px] pageContentLaptopSamll:w-[31.6%] pageContentSmall:w-[32.6%] xl:w-[49.1%] mx-2.5 pageContentSmall:mx-1 largeTablet:mx-0 pt-4 pageContentSmall:pt-2 inline-block largeTablet:block align-top largeTablet:w-full'
                                        key={index}>
                                        <KOT
                                            KOT={index}
                                            setShowPopup={setShowPopup}
                                            orders={el}
                                            paginationState={paginationState}
                                            setPaginationState={setPaginationState}
                                            selectedOrder={selectedOrder}
                                            setSelectedOrder={setSelectedOrder}
                                            onReady={onReady}
                                            selectedOrderDetail={selectedOrderDetail}
                                            setSelectedOrderDetail={setSelectedOrderDetail}
                                            handleClickClose={() => setShowPopup(null)}
                                            showPopup={showPopup}
                                            setAssignRider={setAssignRider}
                                            setKotOrderDetail={setKotOrderDetail}
                                            fetchData={fetchData}
                                            onComplete={onComplete}
                                        />
                                    </div>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <TableBlankComponentView previewText={'No Content Available'} />
                                    </td>
                                </tr>
                            )}
                        </div>
                    )}
                    {(!isKotView || isMobileScreen) && (
                        <ListViewOfKOT
                            setShowPopup={setShowPopup}
                            orders={orders}
                            paginationState={paginationState}
                            setPaginationState={setPaginationState}
                            selectedOrder={selectedOrder}
                            setSelectedOrder={setSelectedOrder}
                            onReady={onReady}
                            onComplete={onComplete}
                            selectedOrderDetail={selectedOrderDetail}
                            setSelectedOrderDetail={setSelectedOrderDetail}
                            handleClickClose={() => setShowPopup(null)}
                            setCancelRider={setCancelRider}
                            fetchData={fetchData}
                        />
                    )}
                    {/* {showPopup &&
                        isKotView &&
                        orderItems.map((el, index) => (
                            <RiderDetailsPopup
                                handleClickClose={() => setShowPopup(null)}
                                orderItems={el}
                            />
                        ))} */}
                    {showPopup === 'riderDetails' && (
                        <RiderDetailsPopup
                            handleClickClose={() => setShowPopup(null)}
                            orderItems={orderItems}
                            selectedOrderDetail={selectedOrderDetail}
                        />
                    )}

                    {showPopup === 'acceptOrder' && (
                        <AcceptOrderPopup
                            handleClickClose={() => setShowPopup(null)}
                            setKitchenPreparationTime={setKitchenPreparationTime}
                            selectedOrderDetail={selectedOrderDetail}
                            setShowPopup={setShowPopup}
                            onConfirmAccept={onConfirmAccept}
                            setHasRiderAssigned={setHasRiderAssigned}
                            setFullFillStatus={setFullFillStatus}
                        />
                    )}

                    {showPopup === 'riderAssignment' && selectedOrderDetail?.pidge_order === null && (
                        <AssignRiderPopup
                            thirdPartyRiderSettings={selectedOutletDetails?.outletData?.third_party_rider_setting}
                            inHouseRiderEnabled={selectedOutletDetails?.outletData?.settings?.enable_own_rider_service}
                            handleClickClose={() => setShowPopup(null)}
                            orderId={selectedOrder?.order_id}
                            hasRiderAssigned={hasRiderAssigned}
                            setHasRiderAssigned={setHasRiderAssigned}
                            onConfirmAccept={onConfirmAccept}
                            selectedOrder={selectedOrder}
                            setAssignRider={setAssignRider}
                            assignRider={assignRider}
                            fullFillStatus={fullFillStatus}
                            setFullFillStatus={setFullFillStatus}
                            selectedOutletDetails={selectedOutletDetails}
                            setSelectedOrderDetail={setSelectedOrderDetail}
                        />
                    )}
                    {showPopup === 'customerDetails' && (
                        <OrderDetailsPopup
                            orderDetails={selectedOrder}
                            handleClickClose={() => setShowPopup(null)}
                            selectedOrderDetail={selectedOrderDetail}
                            orderItems={orderItems}
                            kotOrderDetail={kotOrderDetail}
                            // code={selectedOrder.last_four_character_order_label}
                            code={selectedOrderDetail?.last_four_character_order_label}
                        />
                    )}

                    {showPopup === 'readyOrder' && (
                        <ReadyOrderPopup
                            items={orders[0]?.items}
                            handleClickClose={() => setShowPopup(null)}
                        />
                    )}

                    {showPopup === 'completeOrder' && (
                        <ReadyOrderPopup
                            items={orders[0]?.items}
                            handleClickClose={() => setShowPopup(null)}
                        />
                    )}

                    {showPopup === 'cancelOrder' && (
                        <CancelOrderPopup
                            backToPage='order'
                            handleClickClose={() => setShowPopup(null)}
                            orderCancleFrom={orderCancleFrom}
                            setOrderCancleFrom={setOrderCancleFrom}
                            onConfirmCancel={onConfirmCancel}
                            setAssignRider={setAssignRider}
                        />
                    )}

                    {showPopup === 'ordersDetails' && (
                        <OrdersDetailsPopup
                            handleClickClose={() => setShowPopup(null)}
                            selectedOrderDetail={selectedOrderDetail}
                            setShowPopup={setShowPopup}
                            onReady={onReady}
                            onComplete={onComplete}
                            orderDetails={selectedOrderDetail}
                        />
                    )}
                </>
            )}
        </>
    );
}
