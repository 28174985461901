import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';

import { DefaultInputField } from '../../../Components/InputField/InputField';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import axios, { Axios } from 'axios';

export default function AddCredit(props) {
    const {
        handleClickClose,
        creditType,
        setShowPopup,
        fetchServiceCreditList,
        fetchTransactionList,
        fetchInfrastructureCardDetails,
        fetchServiceCardDetails,
    } = props;
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const addRechargeAmount = ['250.00', '500.00', '1,000.00', '1,500.00'];
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedAmount, setSelectedAmount] = useState('');

    const [serviceType, setServiceType] = useState('');

    const handleAmountChange = (value) => {
        setSelectedAmount(value);
        setErrorMessage('');
    };

    const handleAmountSelection = (amount) => {
        const currentAmount = parseFloat(selectedAmount.replace(/,/g, '')) || 0;
        const newAmount = currentAmount + parseFloat(amount.replace(/,/g, ''));
        setSelectedAmount(newAmount.toLocaleString('en-IN'));
        setErrorMessage('');
    };

    useEffect(() => {
        if (creditType === 'infrastructure') {
            setServiceType('infrastructure_credit');
        } else {
            setServiceType('service_credit');
        }
    }, [creditType]);

    const addCredit = async () => {
        if (selectedAmount === '' || selectedAmount < 250) {
            setErrorMessage('Please enter amount higher than 250');
            return;
        }
        try {
            const response = await APIV5.post(`/restaurants/${selectedRestaurantId}/add-credits`, {
                amount: parseFloat(selectedAmount.replace(/,/g, '')),
                service_type: serviceType,
                entity_name: serviceType == 'infrastructure_credit' ? 'restaurant' : 'outlet',
                entity_id: serviceType == 'infrastructure_credit' ? selectedRestaurantId : selectedOutletId,
            });

            // Getting the order details back
            const { razorpay_key } = response;
            const { amount, currency, razorpay_order_id } = response?.razorpay_order;
            const { name, email, mobile_number } = response?.user;

            const options = {
                key: razorpay_key,
                amount: amount.toString(),
                currency: currency,
                name: 'Levaral Sofwares Private Limited',
                description: response.reference_id,
                image: 'https://api.app.fab.delivery/images/levaral_logo.png',
                order_id: razorpay_order_id,
                handler: async function (response) {
                    const data = {
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_signature: response.razorpay_signature,
                    };

                    const result = await axios.post('https://api.staging.fab.delivery/api/razorpay/callback', data);
                    if (result?.data?.success) {
                        if (serviceType === 'infrastructure_credit') {
                            await fetchTransactionList();
                            await fetchInfrastructureCardDetails();
                        } else {
                            await fetchServiceCardDetails();
                            await fetchServiceCreditList();
                        }
                    }
                },
                prefill: {
                    name: name,
                    email: email,
                    contact: mobile_number,
                },
                notes: { address: 'Soumya Dey Corporate Office' },
                theme: {
                    color: '#035280',
                },
            };

            var rzp1 = new window.Razorpay(options);
            setShowPopup((prevState) => ({ ...prevState, addCredit: false }));
            rzp1.open();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex p-4 overflow-auto'>
                <div className='w-[475px] rounded-xl bg-white px-8 py-6 md:p-4 m-auto'>
                    <div className='flex justify-between items-center'>
                        <div>
                            <h3 className='paragraph-large-medium'>Add credits</h3>
                            <p className='paragraph-medium-italic text-neutral-500'>
                                Min. recharge amount required is ₹250.00
                            </p>
                        </div>
                        <span
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </span>
                    </div>
                    <div className='pt-6 pb-2'>
                        <DefaultInputField
                            label='Add recharge amount'
                            placeholder='Add amount'
                            boxHeight='11'
                            value={selectedAmount}
                            onChange={handleAmountChange}
                            inputType='number'
                        />
                        {errorMessage && <p className='text-red-500 text-sm mt-1'>{errorMessage}</p>}
                    </div>
                    <div>
                        {addRechargeAmount.map((el, index) => (
                            <AddRechargeAmountCard
                                amount={el}
                                key={index}
                                onSelect={handleAmountSelection}
                            />
                        ))}
                    </div>
                    <div className='paragraph-medium-italic pt-3 pb-12 md:hidden'>
                        {selectedAmount ? selectedAmount : '0'} credits = ₹{selectedAmount ? selectedAmount : '0'} +
                        processing fees
                    </div>
                    <div onClick={addCredit}>
                        <LargePrimaryButton
                            label='Proceed to payment'
                            isClicked={errorMessage ? false : {}}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

const AddRechargeAmountCard = (props) => {
    const { amount, onSelect } = props;

    const handleClick = () => {
        onSelect(amount);
    };

    return (
        <>
            <div
                className='inline-block align-top px-4 pt-2.5 pb-2 border border-primary-500 rounded text-center mr-2 mb-3 cursor-pointer hover:bg-primary-100
                group'
                onClick={handleClick}>
                <div className='flex'>
                    <AddIcon
                        stroke='#6C5DD3'
                        width={18}
                        height={18}
                        className='mx-auto'
                    />
                </div>
                <div className='paragraph-small-regular text-primary-500 pt-1.5'>₹{amount}</div>
            </div>
        </>
    );
};
