import React, { useState } from 'react';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import CalenderField from '../../../Components/Calender/CalenderField';
import { ReactComponent as FilterIcon } from '../../../Assets/filter.svg';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';
import { ReactComponent as ExportIcon } from '../../../Assets/export.svg';
import { format } from 'date-fns';
import APIV3 from '../../../api/axios/APIV3';
import { useSelector } from 'react-redux';
import { Filters } from './Filters';

export default function HeaderButtons(props) {
    const {
        setSelectedDates,
        selectedDates,
        setFilters,
        filters,
        setPaginationState,
        isMobileScreen,
        setAbandonedCartsList,
    } = props;
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [isDisable, setIsDisable] = useState(false);

    const [showFilters, setShowFilters] = useState(false);

    const handleClickFilter = () => {
        setShowFilters(!showFilters);
    };

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    let data;

    // const handleClickExportData = async () => {
    //     const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
    //     data = {
    //         type: 'abandoned_carts_report',
    //         start_date: formatDate(from_date),
    //         end_date: formatDate(to_date),
    //         outlet_id: selectedOutletId,
    //     };

    //     if (filters?.orderingMode) {
    //         data.ordering_mode_id = filters?.orderingMode;
    //     }

    //     if (filters?.customerType) {
    //         data.is_new_customer = filters?.customerType === 'old' ? '0' : '1';
    //     }

    //     try {
    //         setIsDisable(true);
    //         const response = await APIV3.post(`export-report`, data);

    //         if (response.success === true) {
    //             setIsDisable(false);
    //         } else {
    //             setIsDisable(false);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    return (
        <>
            <div className='flex flex-col border-b border-neutral-300 mb-2 md:mb-4 md:flex-col'>
                <div className='flex flex-row justify-between  mb-2 md:mb-4 md:flex-col '>
                    <div className='flex items-center w-full justify-between'>
                        <div className='mr-4'>
                            <CalenderField
                                setSelectedDates={setSelectedDates}
                                selectedDates={selectedDates}
                                isShowSpacing={false}
                            />
                        </div>
                        <div
                            className='md:w-1/3 w-[120px] sm:pr-[2rem]'
                            onClick={handleClickFilter}>
                            <LargePrimaryButton
                                label='Filters'
                                hideLabel='lg:hidden mobile:hidden md:block'
                                leftIconDefault={<FilterIcon fill='#FFFFFF' />}
                                leftIconClick={<FilterIcon fill='#C4BEED' />}
                            />
                        </div>
                    </div>
                </div>
                {showFilters ? (
                    <Filters
                        setFilters={setFilters}
                        setPaginationState={setPaginationState}
                        isMobileScreen={isMobileScreen}
                        setAbandonedCartsList={setAbandonedCartsList}
                    />
                ) : null}
            </div>
        </>
    );
}
