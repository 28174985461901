import React, { useState } from 'react';
import DownArrow from './DownArrow';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as ExportIcon } from '../../../../src/Assets/export.svg';
import { format } from 'date-fns';
import APIV3 from '../../../api/axios/APIV3';

const PaymentReports = ({
    activeTab,
    fetchReportDetails,
    reportType,
    selectedRestaurantId,
    selectedDownload,
    setSelectedDownload,
    DownloadsItems,
    reportDetails,
    selectedOutletId,
    reportResponse,
}) => {
    const [isReportGenerated, setIsReportGenerated] = useState(false);
    const today = new Date();
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [selectedDates, setSelectedDates] = useState([today, today]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showReportPopup, setShowReportPopup] = useState(false);
    const [isGenerateButtonClicked, setIsGenerateButtonClicked] = useState(false);
    const [loyaltyCashbackType, setLoyalCashbackType] = useState(null);
    const [ratingArray, setRatingArray] = useState([]);
    const [orderRating, setOrderRating] = useState([]);
    const [paymentMode, setPaymentMode] = useState(null);
    const [orderingModeId, setOrderingModeId] = useState(null);
    const [orderingModeIdTabs, setOrderingModeIdTabs] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [paymentCustomerOrder, setPaymentCustomerOrder] = useState(null);
    const [OrderRefundStatus, setOrderRefundStatus] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [allSelectedRiders, setAllSelectedRiders] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [allSelectedCustomer, setAllSelectedCustomer] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(null);
    const [allSelectedCategories, setAllSelectedCategories] = useState([]);
    const [dateError, setDateError] = useState(null);

    const allRidersID = allSelectedRiders.map((rider) => rider?.rider_id);
    const allCustomerID = allSelectedCustomer.map((customer) => customer?.id);
    const allCategoryID = allSelectedCategories.map((category) => category.category_id);

    const formatDate = (date) => {
        try {
            return format(date, 'yyyy-MM-dd');
        } catch (error) {
            console.error('Date formatting error:', error);
            setDateError('Invalid date selected. Please choose valid dates.');
            return null;
        }
    };

    const getReportParams = (activeTab, formattedStartDate, formattedEndDate, selectedRestaurantId) => {
        // if (!orderingModeId) {
        //     throw new Error('Ordering mode is required for this report type');
        // }

        switch (activeTab) {
            case 'Loyalty cashback':
                return {
                    type: 'loyalty_cashback_report',
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    outlet_id: selectedOutletId,
                    cashback_type: loyaltyCashbackType,
                };

            case 'Signup cashback':
                return {
                    type: 'signup_cashback_report',
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    restaurant_id: selectedRestaurantId,
                };

            case 'Infrastructure credit':
                return {
                    type: 'infrastructure_credit_report',
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    restaurant_id: selectedRestaurantId,
                };

            case 'Topup infrastructure credit':
                return {
                    type: 'infrastructure_credit_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Topup service credit':
                return {
                    type: 'topup_infrastructure_credit_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Service credit':
                return {
                    type: 'service_credit_report',
                    outlet_id: selectedOutletId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Riders':
                return {
                    type: 'rider_list_report',
                    outlet_id: selectedOutletId,
                    rating: {
                        from: ratingArray?.[0] ?? 4.0, // by default to 4.0
                        to: ratingArray?.[1] ?? 5.0, // by default to 5.0
                    },
                    join_date_sort_order: 'asc',
                };

            case 'Rider orders':
                return {
                    type: 'rider_orders_list_report',
                    rider_id: selectedUser?.rider_id ? selectedUser?.rider_id : allRidersID,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    rating: {
                        from: ratingArray?.[0] ?? 4.0, // by default to 4.0
                        to: ratingArray?.[1] ?? 5.0, // by default to 5.0
                    },
                    payment_mode: paymentMode,
                };

            case 'Order rating':
                return {
                    type: 'order_rating_list_report',
                    outlet_id: selectedOutletId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    order_rating: {
                        from: ratingArray?.[0] ?? 4.0, // by default to 4.0
                        to: ratingArray?.[1] ?? 5.0, // by default to 5.0
                    },
                    rider_rating: {
                        from: ratingArray?.[0] ?? 4.0, // by default to 4.0
                        to: ratingArray?.[1] ?? 5.0, // by default to 5.0
                    },
                };

            case 'Abandoned carts':
                return {
                    type: 'abandoned_carts_report',
                    outlet_id: selectedOutletId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    ordering_mode_ids: [orderingModeId],
                    is_new_customer: 0,
                };

            case 'Customers':
                return {
                    type: 'customer_list_report',
                    restaurant_id: selectedRestaurantId,
                };

            case 'Customer orders':
                return {
                    type: 'customer_orders_list_report',
                    customer_id: selectedCustomer?.id ? selectedCustomer?.id : allCustomerID,
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    ordering_mode_ids: [orderingModeId],
                    order_status: [orderStatus],
                    payment_method: paymentCustomerOrder,
                };

            case 'Past orders':
                return {
                    type: 'past_orders_list_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    ordering_mode: orderingModeIdTabs,
                    status: orderStatus,
                    refund_status: OrderRefundStatus,
                };

            case 'Failed orders':
                return {
                    type: 'failed_orders_list_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    refund_status: OrderRefundStatus,
                };

            case 'Restaurant timing':
                return {
                    type: 'restaurant_timing_summary',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Captain performance':
                return {
                    type: 'captain_performance_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Due payment':
                return {
                    type: 'due_payment_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Waiter performance':
                return {
                    type: 'waiter_performance_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Locality wise':
                return {
                    type: 'locality_wise_report',
                    outlet_id: selectedOutletId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Sales report: online platforms':
                return {
                    type: 'sales_report_online_platforms',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Sales report':
                return {
                    type: 'sales_report',
                    ordering_mode_ids: [orderingModeId],
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    outlet_ids: [selectedOutletId],
                    end_date: formattedEndDate,
                };
            case 'Item sales report':
                return {
                    type: 'item_sales_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    category_ids: selectedCategories?.category_id ? selectedCategories?.category_id : allCategoryID,
                };
            case 'Ordering mode wise report':
                return {
                    type: 'ordering_mode_wise_sales_report',
                    ordering_mode_ids: [orderingModeId],
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };
            case 'Day wise sales report':
                return {
                    type: 'day_wise_sales_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };
            case 'Order cancellation report':
                return {
                    type: 'order_cancellation_report',
                    ordering_mode_ids: [orderingModeId],
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Discount summary report':
                return {
                    type: 'discount_summary_report',
                    ordering_mode_ids: [orderingModeId],
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            default:
                return null;
        }
    };

    const handleClickExportData = async () => {
        setIsGenerateButtonClicked(true);
        setIsDisabled(true);
        setIsButtonClicked(true);

        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        const formattedStartDate = formatDate(from_date);
        const formattedEndDate = formatDate(to_date);

        const reportParams = getReportParams(activeTab, formattedStartDate, formattedEndDate, selectedRestaurantId);

        if (!reportParams) {
            console.error('Invalid activeTab or missing parameters');
            setDateError('Unable to generate report. Invalid parameters.');
            setIsDisabled(false);
            setIsButtonClicked(false);
            setIsGenerateButtonClicked(false);
            return;
        }

        try {
            const response = await APIV3.post(`export-report`, reportParams);
            if (response?.success === true) {
                setIsDisabled(false);
                fetchReportDetails();
                setIsReportGenerated(true);
                setShowReportPopup(true);
            } else {
                setDateError('Failed to generate report. Please try again.');
                setIsDisabled(false);
                setIsGenerateButtonClicked(false);
            }
        } catch (error) {
            console.log(error);
            setIsDisabled(false);
            setIsGenerateButtonClicked(false);
        } finally {
            setIsButtonClicked(false);
        }
    };

    const handleClosePopup = () => {
        setShowReportPopup(false);
        setIsGenerateButtonClicked(false);
        setIsDisabled(false);
    };

    return (
        <div className='lg:mx-[0rem] mx-[1rem] md:mx-0 p-3'>
            <div className='p-4 lg:p-6'>
                <span className='paragraph-large-medium text-black mb-4'>{activeTab} Reports</span>
                <div className='mb-4'>
                    <span className='text-orange-500 paragraph-small-semi-bold'>Please note:</span>
                    <span className='paragraph-x-small-regular text-gray-600 ml-4'>
                        • Data Available for {format(selectedDates[0], 'dd MMM yyyy')} -{' '}
                        {format(selectedDates[1], 'dd MMM yyyy')} , Max Duration in a Single Report: 1 month
                    </span>
                </div>
                <DownArrow
                    activeTab={activeTab}
                    reportResponse={reportResponse}
                    selectedOutletId={selectedOutletId}
                    setOrderingModeId={setOrderingModeId}
                    setOrderingModeIdTabs={setOrderingModeIdTabs}
                    setRatingArray={setRatingArray}
                    selectedCustomer={selectedCustomer}
                    selectedCategories={selectedCategories}
                    setSelectedCustomer={setSelectedCustomer}
                    setSelectedCategories={setSelectedCategories}
                    setSelectedUser={setSelectedUser}
                    allSelectedCategories={allSelectedCategories}
                    setAllSelectedCategories={setAllSelectedCategories}
                    allSelectedCustomer={allSelectedCustomer}
                    setAllSelectedCustomer={setAllSelectedCustomer}
                    setOrderRefundStatus={setOrderRefundStatus}
                    setOrderStatus={setOrderStatus}
                    selectedUser={selectedUser}
                    setPaymentCustomerOrder={setPaymentCustomerOrder}
                    setPaymentMode={setPaymentMode}
                    allSelectedRiders={allSelectedRiders}
                    setAllSelectedRiders={setAllSelectedRiders}
                    setOrderRating={setOrderRating}
                    setLoyalCashbackType={setLoyalCashbackType}
                    isReportGenerated={isReportGenerated}
                    selectedRestaurantId={selectedRestaurantId}
                    reportDetails={reportDetails}
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                    selectedDownload={selectedDownload}
                    setSelectedDownload={setSelectedDownload}
                    DownloadsItems={DownloadsItems}
                    dateError={dateError}
                />

                <div className='flex flex-row h-[5rem] mt-3 gap-6 items-center justify-start lg:mx-2 mx-4'>
                    <div className='sm:w-[8rem] sm:h-[3rem]'>
                        <div
                            className='md:w-1/2 md:ml-[8.5px] mobile:ml-1'
                            onClick={handleClickExportData}>
                            <LargePrimaryButton
                                label='Generate report'
                                isClicked={isButtonClicked}
                                leftIconDefault={<ExportIcon stroke='#FFFFFF' />}
                                leftIconClick={<ExportIcon stroke='#FFFFFF' />}
                            />
                        </div>
                    </div>
                </div>

                {showReportPopup && (
                    <div className='fixed bg-black bg-opacity-50 inset-0 z-50 md:px-4 flex'>
                        <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4'>
                            <div className='pb-3 flex flex-row items-center'>
                                <div className='p-2 bg-primary-500 rounded-md'>
                                    <ExportIcon stroke='#FFFFFF' />
                                </div>
                                <h2 className='paragraph-large-medium ml-2'>Report Successfully Generated!</h2>
                            </div>
                            <p className='paragraph-medium-italic text-neutral-500 pb-3'>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum voluptatem sit, dolorum
                                qui eligendi sint e optio nemo quaerat!
                            </p>

                            <div className='flex flex-row justify-end'>
                                <div
                                    className='w-[8rem] md:ml-[7.5px] ml-2.5 cursor-pointer'
                                    onClick={handleClosePopup}>
                                    <LargePrimaryButton label='Okay' />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentReports;
