// import React, { useEffect, useState } from 'react';
// import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';
// import { useFormContext } from 'react-hook-form';
// import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
// import { format } from 'date-fns';
// import { DatePicker } from 'rsuite';
// import '../Styles/datePicker.css';
// import DropdownSection from '../../../Components/DropDown/DropdownSection';
// import { IsMobileScreen } from '../../../Constants/Constants';

// export default function ScheduleNotification(props) {
//     const { disabled, label, openFromBottom, menuItems, timeTypeName, selectedTimeName, selectedDateName } = props;

//     const {
//         watch,
//         setValue,
//         clearErrors,
//         formState: { errors },
//     } = useFormContext();

//     useEffect(() => {
//         if (!disabled) {
//             return;
//         }

//         setValue(selectedTimeName, null);
//         setValue(selectedDateName, null);
//         setValue(timeTypeName, null);

//         // eslint-disable-next-line
//     }, [disabled]);

//     useEffect(() => {
//         if (watch(timeTypeName)?.value === '0') {
//             clearErrors(selectedTimeName);
//         }
//     }, [watch(timeTypeName)?.value, clearErrors, timeTypeName]);

//     const disabledDate = (date) => {
//         const currentDate = new Date();

//         currentDate.setHours(0, 0, 0, 0);

//         return date < currentDate;
//     };

//     const formatDateAndAddHours = (input, hoursToAdd) => {
//         const inputDate = new Date(input);
//         inputDate.setHours(inputDate.getHours() + hoursToAdd);

//         const year = inputDate.getFullYear();
//         const month = String(inputDate.getMonth() + 1).padStart(2, '0');
//         const day = String(inputDate.getDate()).padStart(2, '0');
//         const hours = String(inputDate.getHours()).padStart(2, '0');
//         const minutes = String(inputDate.getMinutes()).padStart(2, '0');

//         return `${year}-${month}-${day} ${hours}:${minutes}`;
//     };

//     const getPlaceholder = formatDateAndAddHours(new Date(), 0);

//     const handleSelection = (dateStr) => {
//         const date = new Date(dateStr);

//         const formattedDate = format(date, 'yyyy-MM-dd');

//         const formattedTime = format(date, 'HH:mm');

//         setValue(selectedDateName, formattedDate);
//         setValue(selectedTimeName, formattedTime);
//     };

//     const ranges = [
//         {
//             label: 'Now',
//             value: new Date(),
//         },
//     ];

//     const isMobileScreen = IsMobileScreen();

//     return (
//         <>
//             <span className={`paragraph-small-medium ${disabled && 'text-neutral-300'}`}>{label}</span>
//             <div className='flex flex-row items-start mt-1 md:flex-col md:gap-3'>
//                 <div className='w-1/2 mr-1 relative md:w-full md:mr-0'>
//                     <DropdownSection
//                         name={timeTypeName}
//                         rules={{ required: 'Please select Date' }}
//                         icon={<CalenderIcon stroke={disabled ? '#D3D2D8' : '#131126'} />}
//                         openFromBottom={openFromBottom}
//                         placeholder='Please select time'
//                         menuItems={menuItems}
//                     />

//                     {errors?.[timeTypeName] && <ErrorMessage errors={errors[timeTypeName]} />}
//                 </div>

//                 {watch(timeTypeName)?.value === menuItems[1]?.value && (
//                     <DatePicker
//                         shouldDisableDate={disabledDate}
//                         onSelect={(value) => handleSelection(value)}
//                         placeholder={getPlaceholder}
//                         format='dd MMM yyyy hh:mm aa'
//                         showMeridian
//                         ranges={ranges}
//                         style={{ width: isMobileScreen ? '100%' : 260 }}
//                     />
//                 )}
//             </div>
//         </>
//     );
// }
import React, { useEffect, useState } from 'react';
import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import { format } from 'date-fns';
import { dayTimeSlots } from '../../CreateOffer/TabComponents/OfferTiming/Components/constants';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import '../Styles/datePicker.css';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export default function ScheduleNotification(props) {
    const {
        disabled,
        label,
        openFromBottom,
        menuItems,
        timeTypeName,
        errors,
        selectedTimeName,
        selectedDateName,
        pageTitle,
        id,
    } = props;

    const {
        watch,
        setValue,
        control,
        formState: { isDirty },
        clearErrors,
    } = useFormContext();

    const [showCalender, setShowCalender] = useState(false);

    const [isTimeSlotSelectionEnable, setIsTimeSlotSelectionEnable] = useState(false);

    useEffect(() => {
        const timeTypeValue = watch(timeTypeName)?.value;

        if (timeTypeValue === 'custom') {
            setShowCalender(true);
        } else {
            setShowCalender(false);
        }

        setIsTimeSlotSelectionEnable(timeTypeValue && timeTypeValue !== '0');
    }, [watch(timeTypeName)]);

    const handleSelectTime = (selectedDate, fieldChange) => {
        fieldChange(selectedDate);

        const date = new Date(selectedDate);

        const formattedDate = format(date, 'dd MMMM yyyy');

        setValue(timeTypeName, { label: formattedDate, value: formattedDate });

        setIsTimeSlotSelectionEnable(true);
    };

    useEffect(() => {
        if (disabled) {
            setValue(selectedTimeName, null);
            setValue(selectedDateName, null);
            setValue(timeTypeName, null);
        }
    }, [disabled]);

    useEffect(() => {
        if (watch(timeTypeName)?.value === '0') {
            clearErrors(selectedTimeName);
        }
    }, [watch(timeTypeName)?.value, clearErrors, timeTypeName]);

    return (
        <>
            <span className={`paragraph-small-medium ${disabled && 'text-neutral-300'}`}>{label}</span>
            <div className='flex flex-row items-center mt-1'>
                <div className='w-1/2 mr-1 relative'>
                    <div>
                        <Controller
                            render={({ field }) => (
                                <DropdownWithValueLabel
                                    openFromBottom={openFromBottom}
                                    disabled={disabled}
                                    icon={<CalenderIcon stroke={disabled ? '#D3D2D8' : '#131126'} />}
                                    menuItems={menuItems}
                                    placeholder='Please select time'
                                    setSelected={field.onChange}
                                    selectedItem={
                                        menuItems?.filter((item) => item?.value === field.value)?.[0] ?? field.value
                                    }
                                />
                            )}
                            name={timeTypeName}
                            control={control}
                            rules={{ required: 'Please select Date' }}
                        />
                    </div>
                    {errors?.[timeTypeName] && <ErrorMessage errors={errors[timeTypeName]} />}

                    {pageTitle !== 'Edit preset' && (
                        <div className='absolute left-0 top-7 bottom-10'>
                            <Controller
                                render={({ field }) => (
                                    <ReactDatePicker
                                        selected={new Date()}
                                        onChange={(selectedDate) => handleSelectTime(selectedDate, field.onChange)}
                                        timeIntervals={15}
                                        timeCaption='time'
                                        dateFormat='dd MMM yyyy'
                                        popperPlacement='bottom-end'
                                        minDate={new Date()}
                                        showPopperArrow={false}
                                        className='hidden'
                                        open={showCalender}
                                        popperProps={{
                                            positionFixed: true,
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [-5, -80],
                                                    },
                                                },
                                            ],
                                        }}
                                    />
                                )}
                                name={selectedDateName}
                                control={control}
                            />
                        </div>
                    )}
                </div>

                {(pageTitle !== 'Edit preset' || watch(timeTypeName)?.value === '0') && isTimeSlotSelectionEnable && (
                    <div className='w-1/2 ml-1'>
                        <Controller
                            render={({ field }) => (
                                <DropdownWithValueLabel
                                    openFromBottom={openFromBottom}
                                    icon={
                                        <TimerIcon
                                            stroke={
                                                !isTimeSlotSelectionEnable && watch(timeTypeName)?.value !== '0'
                                                    ? '#D3D2D8'
                                                    : '#131126'
                                            }
                                        />
                                    }
                                    menuItems={dayTimeSlots}
                                    shadow='shadow-smallDropDownShadow'
                                    fixedHeight='h-[260px]'
                                    placeholder='Please select time'
                                    setSelected={(value) => {
                                        field.onChange(value);
                                        if (value === '0') {
                                            clearErrors(timeTypeName);
                                        }
                                    }}
                                    selectedItem={field.value}
                                />
                            )}
                            name={selectedTimeName}
                            control={control}
                        />
                        {errors?.[selectedTimeName] && <ErrorMessage errors={errors[selectedTimeName]} />}
                    </div>
                )}
            </div>
        </>
    );
}
