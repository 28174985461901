import React from 'react';
import ContentLoader from 'react-content-loader';

export const ReportCardLoader = (props) => {
    return (
        <ContentLoader
            viewBox='0 0 1000 90' // Adjusted height
            height={90} // Reduced outer height to 90px
            width={1000}
            {...props}>
            <rect
                x='0'
                y='20'
                rx='8'
                ry='0'
                width='200'
                height='50'
            />
            <rect
                x='260'
                y='20'
                rx='8'
                ry='0'
                width='200'
                height='50'
            />
            <rect
                x='520'
                y='20'
                rx='8'
                ry='0'
                width='200'
                height='50'
            />
            <rect
                x='780'
                y='20'
                rx='8'
                ry='0'
                width='200'
                height='50'
            />
        </ContentLoader>
    );
};
export const TabsLoader = (props) => (
    <ContentLoader
        width='20%'
        height='100%'
        viewBox='0 0 100% 300'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}>
        {[...Array(12)].map((_, index) => (
            <rect
                key={index}
                x='0'
                y={index * 53 + 10}
                rx='10'
                ry='10'
                width='210'
                height='43'
            />
        ))}
    </ContentLoader>
);
export const YAxisLineLoader = (props) => (
    <ContentLoader
        width='12px'
        height='100%'
        viewBox='0 0 100% 300'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}>
        {/* Vertical line */}
        <rect
            x='0'
            y='0'
            rx='10'
            ry='10'
            width='12'
            height='100%'
        />
    </ContentLoader>
);
