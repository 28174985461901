import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import Header from './Components/Header';
import LiveOrder from './OrderTabPage/LiveOrder/LiveOrder';
import PastOrder from './OrderTabPage/PastOrder/PastOrder';
import FailedOrder from './OrderTabPage/FailedOrder/FailedOrder';
import BillPayment from '../BillPayment/BillPayment';
import OrderDetailsPopup from '../../Components/CustomerOrderDetails/OrderDetailsPopup';

export default function Orders() {
    const { orderType } = useParams();

    const screenHeight = useWindowSize().height;

    const componentToMap = {
        'live': LiveOrder,
        'past': PastOrder,
        'failed': FailedOrder,
        'bill-payment': BillPayment,
    };

    const [isKotView, setIsKotView] = useState(false);

    const ComponentToRender = componentToMap[orderType];

    const [showPopup, setShowPopup] = useState(null);
    const [allOrderingModeCounts, setAllOrderingModeCounts] = useState([]);
    const [selectedOrderingMode, setSelectedOrderingMode] = useState('all');
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [isFetchingOrders, setIsFetchingOrder] = useState(true);
    const [orderDetail, setOrderDetail] = useState({});
    const [paginationState, setPaginationState] = useState({
        currentPage: 1,
        lastPage: 1,
        perPage: 10,
    });
    const [isLoading, setLoading] = useState(false);

    const resetState = () => {
        setSelectedOrderingMode('all');
        setSelectedFilter('all');
        setIsFetchingOrder(false);
        setPaginationState({ currentPage: 1, lastPage: 1, perPage: 10 });
    };
    useEffect(() => {
        resetState();
    }, [orderType]);

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className='px-8 pageContentSmall:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                    style={{ minHeight: screenHeight - 50 }}>
                    <Header
                        setShowPopup={setShowPopup}
                        isLoading={isLoading}
                        allOrderingModeCounts={allOrderingModeCounts}
                        setIsKotView={setIsKotView}
                        setSelectedOrderingMode={setSelectedOrderingMode}
                        setSelectedFilter={setSelectedFilter}
                        resetState={resetState}
                        orderType={orderType}
                        selectedOrderingMode={selectedOrderingMode}
                    />
                    <ComponentToRender
                        isKotView={isKotView}
                        setShowPopup={setShowPopup}
                        isFetchingOrders={isFetchingOrders}
                        setIsFetchingOrder={setIsFetchingOrder}
                        orderType={orderType}
                        setLoading={setLoading}
                        setAllOrderingModeCounts={setAllOrderingModeCounts}
                        selectedOrderingMode={selectedOrderingMode}
                        selectedFilter={selectedFilter}
                        paginationState={paginationState}
                        setPaginationState={setPaginationState}
                        resetState={resetState}
                        setOrderDetail={setOrderDetail}
                        orderDetail={orderDetail}
                    />
                </div>
            </div>
            {showPopup === 'orderDetails' && (
                <OrderDetailsPopup
                    code={orderDetail?.last_four_character_order_label}
                    orderDetail={orderDetail}
                    handleClickClose={() => setShowPopup(null)}
                />
            )}
        </>
    );
}
