import React, { useState } from 'react';
import DownArrow from './DownArrow';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as ExportIcon } from '../../../../src/Assets/export.svg';
import { format } from 'date-fns';
import APIV3 from '../../../api/axios/APIV3';

const PaymentReports = ({
    activeTab,
    fetchReportDetails,
    reportType,
    selectedRestaurantId,
    selectedDownload,
    setSelectedDownload,
    DownloadsItems,
    reportDetails,
    selectedOutletId,
}) => {
    const [isReportGenerated, setIsReportGenerated] = useState(false);
    const today = new Date();
    const [selectedDates, setSelectedDates] = useState([today, today]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showReportPopup, setShowReportPopup] = useState(false);
    const [isGenerateButtonClicked, setIsGenerateButtonClicked] = useState(false);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const getReportParams = (activeTab, formattedStartDate, formattedEndDate, selectedRestaurantId) => {
        switch (activeTab) {
            case 'Loyalty cashback':
                return {
                    type: 'loyalty_cashback_report',
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    outlet_id: selectedOutletId,
                    cashback_type: '',
                };

            case 'Signup cashback':
                return {
                    type: 'signup_cashback_report',
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    restaurant_id: selectedRestaurantId,
                };

            case 'Infrastructure credit':
                return {
                    type: 'infrastructure_credit_report',
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    restaurant_id: selectedRestaurantId,
                };

            case 'Topup infrastructure credit':
                return {
                    type: 'topup_infrastructure_credit_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Service credit':
                return {
                    type: 'service_credit_report',
                    outlet_id: selectedOutletId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Riders':
                return {
                    type: 'rider_list_report',
                    outlet_id: selectedOutletId,
                    rating: {
                        from: 0,
                        to: 3,
                    },
                    join_date_sort_order: 'asc',
                };

            case 'Rider orders':
                return {
                    type: 'rider_orders_list_report',
                    rider_id: 1,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    rating: {
                        from: 0,
                        to: 3,
                    },
                    payment_mode: '',
                };

            case 'Order rating':
                return {
                    type: 'order_rating_list_report',
                    outlet_id: selectedOutletId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    order_rating: {
                        from: 0,
                        to: 3,
                    },
                    rider_rating: {
                        from: 0,
                        to: 3,
                    },
                };

            case 'Abandoned carts':
                return {
                    type: 'abandoned_carts_report',
                    outlet_id: selectedOutletId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    ordering_mode_ids: [111],
                    is_new_customer: 0,
                };

            case 'Customers':
                return {
                    type: 'customer_list_report',
                    restaurant_id: selectedRestaurantId,
                    filter: {
                        column: '',
                        direction: '',
                    },
                };

            case 'Customer orders':
                return {
                    type: 'customer_orders_list_report',
                    customer_id: 12,
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    ordering_mode_ids: [111],
                    order_status: '',
                    filter: {
                        column: '',
                        direction: '',
                    },
                    payment_method: '',
                };

            case 'Past orders':
                return {
                    type: 'past_orders_list_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    ordering_mode: [],
                    status: '',
                    refund_status: '',
                };

            case 'Failed orders':
                return {
                    type: 'failed_orders_list_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    refund_status: '',
                };

            case 'Restaurant timing':
                return {
                    type: 'restaurant_timing_summary',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Captain performance':
                return {
                    type: 'captain_performance_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Due payment':
                return {
                    type: 'due_payment_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Waiter performance':
                return {
                    type: 'waiter_performance_report',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Locality wise':
                return {
                    type: 'locality_wise_report',
                    outlet_id: selectedOutletId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            case 'Sales report: online platforms':
                return {
                    type: 'sales_report_online_platforms',
                    restaurant_id: selectedRestaurantId,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };

            default:
                return null;
        }
    };

    const handleClickExportData = async () => {
        setIsGenerateButtonClicked(true);
        setIsDisabled(true);

        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        const formattedStartDate = formatDate(from_date);
        const formattedEndDate = formatDate(to_date);

        const reportParams = getReportParams(activeTab, formattedStartDate, formattedEndDate, selectedRestaurantId);

        if (!reportParams) {
            console.error('Invalid activeTab or missing parameters');
            setIsDisabled(false);
            setIsGenerateButtonClicked(false);
            return;
        }

        try {
            const response = await APIV3.post(`export-report`, reportParams);
            if (response?.success === true) {
                setIsDisabled(false);

                setIsReportGenerated(true);
                setShowReportPopup(true);
            } else {
                setIsDisabled(false);
                setIsGenerateButtonClicked(false);
            }
        } catch (error) {
            console.log(error);
            setIsDisabled(false);
            setIsGenerateButtonClicked(false);
        }
    };

    const handleClosePopup = () => {
        fetchReportDetails();
        setShowReportPopup(false);
        setIsGenerateButtonClicked(false);
        setIsDisabled(false);
    };

    return (
        <div className='lg:mx-[0rem] mx-[1rem] md:mx-0 p-3'>
            <div className='p-4 lg:p-6'>
                <span className='paragraph-large-medium text-black mb-4'>{activeTab} Reports</span>
                <div className='mb-4'>
                    <span className='text-orange-500 paragraph-small-semi-bold'>Please note:</span>
                    <span className='paragraph-x-small-regular text-gray-600 ml-4'>
                        • Data Available for {format(selectedDates[0], 'dd MMM yyyy')} -{' '}
                        {format(selectedDates[1], 'dd MMM yyyy')} , Max Duration in a Single Report: 1 month
                    </span>
                </div>
                <DownArrow
                    isReportGenerated={isReportGenerated}
                    reportDetails={reportDetails}
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                    selectedDownload={selectedDownload}
                    setSelectedDownload={setSelectedDownload}
                    DownloadsItems={DownloadsItems}
                />

                <div className='flex flex-row h-[5rem] mt-3 gap-6 items-center justify-start lg:mx-2 mx-4'>
                    <div className='sm:w-[8rem] sm:h-[3rem]'>
                        <div
                            className='md:w-1/2 md:ml-[8.5px] mobile:ml-1'
                            onClick={handleClickExportData}>
                            <LargePrimaryButton
                                label='Generate report'
                                leftIconDefault={<ExportIcon stroke='#FFFFFF' />}
                                leftIconClick={<ExportIcon stroke='#FFFFFF' />}
                            />
                        </div>
                    </div>
                </div>

                {showReportPopup && (
                    <div className='fixed bg-black bg-opacity-50 inset-0 z-50 md:px-4 flex'>
                        <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4'>
                            <div className='pb-3 flex flex-row items-center'>
                                <div className='p-2 bg-primary-500 rounded-md'>
                                    <ExportIcon stroke='#FFFFFF' />
                                </div>
                                <h2 className='paragraph-large-medium ml-2'>Report Successfully Generated!</h2>
                            </div>
                            <p className='paragraph-medium-italic text-neutral-500 pb-3'>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum voluptatem sit, dolorum
                                qui eligendi sint e optio nemo quaerat!
                            </p>

                            <div className='flex flex-row justify-end'>
                                <div
                                    className='w-[8rem] md:ml-[7.5px] ml-2.5 cursor-pointer'
                                    onClick={handleClosePopup}>
                                    <LargePrimaryButton label='Okay' />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentReports;
