import React from 'react';
import PriceInputForm from './PriceInputForm';
import VariantImageNameForm from './VariantImageNameForm';

export default function SetSeparatePrice(props) {
    const { id, name } = props;

    const imageDetailsName = `${id}.imageDetails`;

    return (
        <>
            <VariantImageNameForm
                imageDetailsName={imageDetailsName}
                name={name}
            />

            <PriceInputForm
                id={id}
                variantNamePrefix={`${id}.prices[${id}]`}
            />
        </>
    );
}
