import React, { useEffect, useState } from 'react';
import SliderDashboard from 'react-slick';
import Card from './Card';
import { CardDescription } from './CardComponent';
import { ReactComponent as MobileIcon } from '../../../Assets/mobile.svg';
import { ReactComponent as SwiggyIcon } from '../../../Assets/swiggy.svg';
import { ReactComponent as ZomatoIcon } from '../../../Assets/zomato.svg';
import { ReactComponent as BannerIcon } from '../../../Assets/banners.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';
import APIV5 from '../../../api/axios/APIV5';

export default function PlatformWiseSalesCards(props) {
    const { settingsDashboardSlider, numberOfOutlet, defaultParams, selectedDates, outletId, selectedRestaurantId } =
        props;
    const [platformSalesData, setPlatformWiseSalesData] = useState([]);

    useEffect(() => {
        const fetchPlatformSalesData = async () => {
            const defaultParamsData = defaultParams();
            try {
                const response = await APIV5.get(`restaurants/${selectedRestaurantId}/platform-wise-sales`, {
                    params: {
                        ...defaultParamsData,
                        outlet_ids: [outletId],
                    },
                });
                setPlatformWiseSalesData(response?.platform_wise_sales || []);
            } catch (error) {
                console.error('Error fetching platform sales data:', error);
            }
        };
        fetchPlatformSalesData();
    }, [selectedDates]);

    const getPlatformIcon = (platform) => {
        switch (platform?.toLowerCase()) {
            case 'fab system':
                return (
                    <MobileIcon
                        height={24}
                        width={24}
                        stroke='#ffffff'
                    />
                );
            case 'swiggy':
                return <SwiggyIcon />;
            case 'zomato':
                return (
                    <ZomatoIcon
                        height={32}
                        width={32}
                        stroke='#ffffff'
                    />
                );
            default:
                return (
                    <BannerIcon
                        height={24}
                        width={24}
                        stroke='#ffffff'
                    />
                );
        }
    };
    const cardContent = platformSalesData.map((platform) => {
        const newSales = parseFloat(platform.new_sales) || 0;
        return {
            title: platform.platform ? platform.platform : 'HardCodedName',
            number: `₹${platform.total_sales}`,
            percentage: platform.percentage_change,
            description: (
                <CardDescription
                    focusContent={`₹${newSales}`}
                    description='You made :focusContent: of new sales this period'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total sales of ${numberOfOutlet} outlets`,
            positive: parseFloat(platform.percentage_change) >= 0,
            icon: getPlatformIcon(platform.platform),
        };
    });
    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='px-8 lg:px-4 md:px-0 md:pb-2'>
                <div className='paragraph-large-medium text-black md:px-4 mt-6 lg:mt-4 mb-2 md:pb-2'>
                    Platform wise sales
                </div>

                {!isMobileScreen ? (
                    <div className='-mx-[11px] lg:-mx-0'>
                        {cardContent.map((el, index) => (
                            <div
                                className='inline-block mx-2.5 my-2 max-w-[303px] w-full lg:w-1/2 lg:max-w-full lg:mx-0 lg:even:pl-1 lg:odd:pr-1 lg:my-1'
                                key={index}>
                                <Card {...el} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <SliderDashboard {...settingsDashboardSlider}>
                            {cardContent.map((el, index) => (
                                <div
                                    className='inline-block mx-2.5 my-2 md:mx-0 md:my-0 pb-1.5'
                                    key={index}>
                                    <Card {...el} />
                                </div>
                            ))}
                        </SliderDashboard>
                    </div>
                )}
            </div>
        </>
    );
}
