import React, { useEffect } from 'react';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import { useFormContext } from 'react-hook-form';
import InputSection from '../../../../../Components/InputField/InputSection';

export default function Configuration() {
    const { watch, handleSubmit, setValue } = useFormContext();

    const minimumSelectionPreference = watch('config.minimumSelectionPreference')?.value;
    const maximumSelectionPreference = watch('config.maximumSelectionPreference')?.value;
    useEffect(() => {
        console.log('Form Data:', watch());
        const showAgmark = watch('details.show_agmark');
        const minSelection = watch('config.minimumSelectionPreference');
        const maxSelection = watch('config.maximumSelectionPreference');

        console.log('Show Agmark:', showAgmark);
        console.log('Minimum Selection:', minSelection);
        console.log('Maximum Selection:', maxSelection);
    }, [watch]);

    return (
        <>
            <form
                // onSubmit={handleSubmit(onSubmit)}
                className='mt-4'>
                <DropdownSection
                    menuItems={preferenceMenuItem}
                    name='details.show_agmark'
                    title='Show agmark'
                    className='mb-4'
                    marginBetween='mb-2'
                    placeholder='Please select a food type'
                    description='Select preference to show agmark on this dish'
                />

                <DropdownSection
                    title='Minimum selection preference'
                    description='Select minimum selection preference for this addon group'
                    name='config.minimumSelectionPreference'
                    placeholder='Select your preference'
                    menuItems={preferenceMenuItem}
                    className='mb-4'
                />

                {minimumSelectionPreference && (
                    <InputSection
                        className='mb-4'
                        inputType='number'
                        title='Minimum selection'
                        description='Enter minimum selection number for this addon group'
                        placeholder='Enter minimum selection number'
                        name='config.minimumSelection'
                    />
                )}

                <DropdownSection
                    title='Maximum selection preference'
                    description='Select maximum selection preference for this addon group'
                    name='config.maximumSelectionPreference'
                    placeholder='Select your preference'
                    menuItems={preferenceMenuItem}
                    className='mb-4'
                />

                {maximumSelectionPreference && (
                    <InputSection
                        className='mb-4'
                        inputType='number'
                        title='Maximum selection'
                        description='Enter maximum selection number for this addon group'
                        placeholder='Enter maximum selection number'
                        name='config.maximumSelection'
                    />
                )}
            </form>
        </>
    );
}
