import React from 'react';
import ContentLoader from 'react-content-loader';

export const ReportCardLoader = () => {
    return (
        <ContentLoader
            viewBox='0 0 1000 90' // Adjusted height
            height={90} // Reduced outer height to 90px
            width={1000}>
            <rect
                x='0'
                y='20'
                rx='8'
                ry='8'
                width='200'
                height='50'
            />
            <rect
                x='360'
                y='20'
                rx='8'
                ry='8'
                width='200'
                height='50'
            />
            <rect
                x='720'
                y='20'
                rx='8'
                ry='8'
                width='200'
                height='50'
            />
            {/* If you need forth loader then uncommit this  */}
            {/* <rect
                x='780'
                y='20'
                rx='8'
                ry='8'
                width='200'
                height='50'
            /> */}
        </ContentLoader>
    );
};
export const TabsLoader = ({ screenSize }) => (
    <ContentLoader
        width={`${screenSize.width < 1420 ? '18%' : '20%'}`}
        height='100%'
        viewBox='0 0 100% 300'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'>
        {[...Array(12)].map((_, index) => (
            <rect
                key={index}
                x='0'
                y={index * 53 + 10}
                rx='10'
                ry='10'
                width={`${
                    screenSize.width < 720
                        ? '70'
                        : screenSize.width >= 720 && screenSize.width < 1000
                          ? '100'
                          : screenSize.width >= 1000 && screenSize.width < 1420
                            ? '120'
                            : '210'
                }`}
                height='43'
            />
        ))}
    </ContentLoader>
);
export const YAxisLineLoader = (props) => (
    <ContentLoader
        width='12px'
        height='100%'
        viewBox='0 0 100% 300'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        {...props}>
        {/* Vertical line */}
        <rect
            x='0'
            y='0'
            rx='10'
            ry='10'
            width='12'
            height='100%'
        />
    </ContentLoader>
);
