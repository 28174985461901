import React, { useEffect, useState } from 'react';
import { Table } from './Table';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../api/axios/APIV5';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function TopManager({ selectedDates, outletId, defaultParams, selectedRestaurantId }) {
    const header = ['CUSTOMER NAME', 'ORDERS MADE', 'REVENUE  GENERATED'];

    const style = ['min-w-[214px] lg:w-[238px]', 'min-w-[208px] lg:w-[208px]', 'min-w-[202px] lg:w-[218px]'];
    const [topUserDetails, setTopUserDetails] = useState([]);

    useEffect(() => {
        const fetchPlatformSalesData = async () => {
            const defaultParamsData = defaultParams();
            try {
                const response = await APIV5.get(`restaurants/${selectedRestaurantId}/top-users`, {
                    params: {
                        ...defaultParamsData,
                        outlet_ids: [outletId],
                    },
                });
                setTopUserDetails(response?.top_users);
            } catch (error) {
                console.error('Error fetching platform sales data:', error);
            }
        };
        fetchPlatformSalesData();
    }, [selectedDates]);

    const tableDetails = (topUserDetails || []).map((item) => [
        item.customer_name || 'N/A',
        item.order_count || 0,
        item.revenue_generated || '₹0',
    ]);
    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='max-w-[626px] w-full lg:max-w-full lg:w-full'>
                <div className='mb-4 md:mb-2'>
                    <span className='paragraph-large-medium text-black'>Top user</span>
                </div>

                {!isMobileScreen && (topUserDetails || []).length > 0 ? (
                    <Table
                        header={header}
                        style={style}
                        details={tableDetails}
                    />
                ) : (
                    <div className='w-full border h-64 border-neutral-300 rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden'>
                        <table className='w-full break-words'>
                            <thead>
                                <tr className='bg-neutral-50 paragraph-overline-small text-neutral-700 shadow-innerShadow h-11 justify-center'>
                                    {header.map((el, index) => (
                                        <th
                                            className={`text-left pageContent:min-w-min pl-6 ${style[index]}`}
                                            key={index}>
                                            {el}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                        </table>
                        <tbody className='flex justify-center items-center h-full'>
                            <tr className='first:border-none border-t border-neutral-300 even:bg-neutral-50 paragraph-small-regular h-[70px] justify-center'>
                                <td className='p-0'>
                                    <div className='w-full pl-6 flex flex-row items-center'>
                                        <span className='text-lg  font-bold'>No Content Available</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                )}
            </div>
        </>
    );
}
