import React, { useEffect } from 'react';
import { Tab } from '../../Components/Tabs/Tabs';
import { useState } from 'react';
import CalenderField from '../../Components/Calender/CalenderField';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../Components/Buttons/Button';
import PaginationWithNumber from '../../Components/Pagination/PaginationWithNumber';
import { ReactComponent as SelectIcon } from '../../Assets/select.svg';
import { ReactComponent as AddIcon } from '../../Assets/add.svg';
import { ReactComponent as CloseIcon } from '../../Assets/close.svg';
import { NotificationList, PresetList } from './Components/Tables';
import NotificationPopup from './Popup/NotificationPopup';
import { IsMobileScreen } from '../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import { useNavigate, useParams } from 'react-router-dom';
import APIV5 from '../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import APIV2 from '../../api/axios/APIV2';
import { format } from 'date-fns';
import _ from 'lodash';
import APIV3 from '../../api/axios/APIV3';
import CreateNotificationPopup from './Popup/CreateNotification/CreateNotificationPopup';
import ConfirmDeletePopup from '../../Components/ConfirmDeletePopup/ConfirmDeletePopup';

export default function Notifications() {
    const { tab } = useParams();
    const today = new Date();

    const navigate = useNavigate();

    const handleTabChange = (tab) => {
        navigate(`/notifications/${tab}`);
    };

    const screenHeight = useWindowSize().height;

    const isMobileScreen = IsMobileScreen();

    const [showNotificaionPopup, setshowNotificaionPopup] = useState(false);
    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    const [isPresetListLoading, setIsPresetListLoading] = useState(false);
    const [isNotificationListLoading, setIsNotificationListLoading] = useState(false);
    const [selectedDates, setSelectedDates] = useState([today, today]);
    const [createNotification, setCreateNotification] = useState(false);
    const [editPreset, setEditPreset] = useState(false);
    const [editNotification, setEditNotification] = useState(false);
    const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedLangaugeId = useSelector((state) => state.langauge.languageId);
    const [notificationPopupTitle, setNotificationPopupTitle] = useState();
    const [notificationPopupTagline, setNotificationPopupTagline] = useState();
    const [presetDetail, setPresetDetail] = useState(null);
    const [isPresetDetailLoading, setIsPresetDetailLoading] = useState(false);
    const [notificationDetail, setNotificationDetail] = useState(null);
    const [isNotificationDetailLoading, setIsNotificationDetailLoading] = useState(false);
    const [popupDetails, setPopupDetails] = useState({ title: '', description: '', screen: '' });
    const [uploadImageBackToPageName, setUploadImageBackToPageName] = useState();
    const [notificationList, setNotificationList] = useState([]);
    const [presetList, setPresetList] = useState([]);
    const [isPresetEdited, setIsPresetEdited] = useState(false);
    const [isNotificationEdited, setIsNotificationEdited] = useState(false);
    const [isNotificationCreated, setIsNotificationCreated] = useState(false);
    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
    const [showCancelDelete, setShowCancelDelete] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [isDisable, setIsDisable] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [metaTagsList, setMetaTagsList] = useState([]);
    const [screensList, setScreensList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [isEditNotificationFieldEnabled, setIsEditNotificationFieldEnabled] = useState(false);

    const togglePopup = (title, tagline, details) => {
        setNotificationPopupTitle(title);
        setNotificationPopupTagline(tagline);
        setPopupDetails(details);
    };
    const handleClickUploadImage = (value) => {
        setShowUploadImagePopup(!showUploadImagePopup);
        setshowNotificaionPopup(!showNotificaionPopup);
        setUploadImageBackToPageName(notificationPopupTitle.toLowerCase());
    };

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const defaultParams = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        return {
            'filters[from_date]': formatDate(from_date),
            'filters[to_date]': formatDate(to_date),
        };
    };
    const fetchData = () => {
        if (tab === 'notifications-list') {
            setIsNotificationListLoading(true);
            fetchNotificationList();
            return;
        }

        if (tab === 'presets-list') {
            setIsPresetListLoading(true);
            fetchPresetList();
        }
    };

    const handleConfirmDeletePopup = () => {
        setShowConfirmDeletePopup(!showConfirmDeletePopup);
    };

    const fetchPresetList = async () => {
        setIsPresetListLoading(true);
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/preset-notifications`, {
                params: {
                    per_page: paginationState.resultPerPage,
                    page: paginationState.selectedPage,
                },
            });
            setPresetList(isMobileScreen ? [...presetList, ...response.presets.data] : response.presets.data);
            setPaginationState((prevState) => {
                return {
                    ...prevState,
                    lastPage: response.presets.last_page,
                    selectedPage: response.presets.current_page,
                };
            });
            setIsPresetListLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const handleCloseDeletePopup = () => {
        setShowConfirmDeletePopup(false);
    };

    useEffect(() => {
        fetchPresetList();
    }, [isPresetEdited]);

    const fetchNotificationList = async (params) => {
        setIsNotificationListLoading(true);
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/notifications`, {
                params: {
                    ...params,
                    ...defaultParams(),
                    per_page: paginationState.resultPerPage,
                    page: paginationState.selectedPage,
                },
            });

            setNotificationList(
                isMobileScreen ? [...notificationList, ...response.notifications.data] : response.notifications.data
            );
            setPaginationState((prevState) => {
                return {
                    ...prevState,
                    lastPage: response.notifications.last_page,
                    selectedPage: response.notifications.current_page,
                };
            });
            setIsNotificationListLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        // fetchNotificationList();
        setNotificationDetail({});
    }, [isNotificationEdited]);

    useEffect(() => {
        fetchNotificationList();
    }, [isNotificationCreated, isNotificationEdited]);

    const fetchDetailsOfPreset = async (el) => {
        setIsPresetDetailLoading(true);
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/preset-notifications/${el.id}`);
            setPresetDetail(response.preset);
            togglePopup('Edit preset', 'Edit preset notification settings', el);
            setIsPresetDetailLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const fetchDetailsOfNotification = async (id) => {
        try {
            setIsNotificationDetailLoading(true);
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/notifications/${id}`);
            setNotificationDetail(response.notifications);
            setIsNotificationDetailLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [tab, selectedRestaurantId, paginationState.selectedPage, paginationState.resultPerPage, selectedDates]);

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    useEffect(() => {
        if (checkedItems.length > 0) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [checkedItems]);

    useEffect(() => {
        setShowCancelDelete(false);
        setShowCheckbox(false);
    }, [isDeleted]);

    const handleDeleteBulkSelect = async () => {
        const params = {
            page: 1,
            per_page: paginationState.resultPerPage,
        };

        try {
            const response = await APIV5.delete(`restaurants/${selectedRestaurantId}/notifications`, {
                params: { notification_ids: checkedItems },
            });
            setIsDeleted(!isDeleted);
            setShowConfirmDeletePopup(false);
            setNotificationDetail({});
            setCheckedItems([]);
            {
                response.success === true && fetchNotificationList(params);
            }
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const fetchMetaTagsList = async () => {
        try {
            const response = await APIV5.get(`meta-tags`);
            setMetaTagsList(response.notification_mata_tags);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const fetchScreensList = async () => {
        try {
            const response = await APIV2.get(`mobile/screens-list`);
            setScreensList(response.screens);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const fetchCategoriesList = async () => {
        try {
            const response = await APIV3.get(
                `restaurants/${selectedRestaurantId}/categories/internal-name?language_id=${selectedLangaugeId}`
            );
            setCategoriesList(response.categories);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        fetchMetaTagsList();
        fetchScreensList();
        fetchCategoriesList();
    }, [selectedRestaurantId]);

    const handleClickDelete = () => {
        handleDeleteBulkSelect();
        setCheckedItems([]);
    };

    const handleClickCancel = () => {
        setShowCancelDelete(!showCancelDelete);
        setShowCheckbox(!showCheckbox);
        setCheckedItems([]);
    };

    const handleBulkSelect = () => {
        setShowCancelDelete(!showCancelDelete);
        setShowCheckbox(!showCheckbox);
    };

    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            setCheckedItems([...checkedItems, id]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== id));
        }
    };

    const handleClick = () => {
        setPresetDetail({});
        setNotificationDetail({});
        setCreateNotification(false);
        setEditNotification(false);
    };

    if (isMobileScreen && createNotification) {
        return (
            <CreateNotificationPopup
                handleClickClose={() => {
                    setCreateNotification(!createNotification);
                }}
                metaTagsList={metaTagsList}
                screensList={screensList}
                categoriesList={categoriesList}
                setShowUploadImagePopup={setShowUploadImagePopup}
                createNotification={createNotification}
                setCreateNotification={setCreateNotification}
                handleClick={handleClick}
                isNotificationCreated={isNotificationCreated}
                setIsNotificationCreated={setIsNotificationCreated}
            />
        );
    }
    if (isMobileScreen && !_.isEmpty(notificationDetail) && editNotification) {
        return (
            <NotificationPopup
                handleClickClose={() => {
                    togglePopup();
                    setNotificationDetail({});
                    setEditNotification(false);
                    setIsEditNotificationFieldEnabled(false);
                }}
                handleClickUploadImage={handleClickUploadImage}
                pageTitle={notificationPopupTitle}
                pageTagline={notificationPopupTagline}
                popupDetails={popupDetails}
                selectedLangaugeId={selectedLangaugeId}
                showUploadImagePopup={showUploadImagePopup}
                setShowUploadImagePopup={setShowUploadImagePopup}
                uploadImageBackToPageName={uploadImageBackToPageName}
                setshowNotificaionPopup={setshowNotificaionPopup}
                editNotification={editNotification}
                setEditNotification={setEditNotification}
                isNotificationEdited={isNotificationEdited}
                setIsNotificationEdited={setIsNotificationEdited}
                details={notificationDetail}
                metaTagsList={metaTagsList}
                screensList={screensList}
                categoriesList={categoriesList}
                setCheckedItems={setCheckedItems}
                handleConfirmDeletePopup={handleConfirmDeletePopup}
                handleClick={handleClick}
            />
        );
    }
    if (isMobileScreen && !_.isEmpty(presetDetail) && editPreset) {
        return (
            <NotificationPopup
                handleClickClose={() => {
                    togglePopup();
                    setPresetDetail({});
                    setEditNotification(false);
                    setIsEditNotificationFieldEnabled(false);
                }}
                handleClickUploadImage={handleClickUploadImage}
                pageTitle={notificationPopupTitle}
                pageTagline={notificationPopupTagline}
                popupDetails={popupDetails}
                selectedLangaugeId={selectedLangaugeId}
                showUploadImagePopup={showUploadImagePopup}
                setShowUploadImagePopup={setShowUploadImagePopup}
                uploadImageBackToPageName={uploadImageBackToPageName}
                setshowNotificaionPopup={setshowNotificaionPopup}
                details={presetDetail}
                setEditPreset={setEditPreset}
                setIsPresetEdited={setIsPresetEdited}
                isPresetEdited={isPresetEdited}
                editPreset={editPreset}
                metaTagsList={metaTagsList}
                isPresetDetailLoading={isPresetDetailLoading}
                handleClick={handleClick}
                fetchPresetList={fetchPresetList}
            />
        );
    }

    return (
        <>
            <div className='bg-[#fafafa]'>
                {(!(showNotificaionPopup || showUploadImagePopup) || !isMobileScreen) && (
                    <div
                        className='px-8 lg:px-4 pt-4 w-full pb-[70px] lg:pb-[23px] max-w-[1336px] mx-auto bg-white relative md:max-w-full'
                        style={{ minHeight: screenHeight - 50 }}>
                        <div className='flex flex-row items-center pb-4 border-b border-neutral-300'>
                            <div className='flex flex-row md:mb-4'>
                                <div
                                    className='mr-2 w-1/2 min-w-[167px] md:min-w-0 lg:mr-0 md:mr-2 mobile:mr-1 cursor-pointer'
                                    onClick={() => {
                                        handleTabChange('notifications-list');
                                        setPaginationState((prevState) => ({
                                            ...prevState,
                                            resultPerPage: 10,
                                            selectedPage: 1,
                                            lastPage: 1,
                                        }));
                                    }}>
                                    <Tab
                                        label='Notifications List'
                                        isActive={tab === 'notifications-list'}
                                        bannerTabStyle='md:text-[15px] mobile:text-sm'
                                    />
                                </div>

                                <div
                                    className='w-1/2 ml-2 mobile:ml-1 cursor-pointer'
                                    onClick={() => {
                                        handleTabChange('presets-list');
                                        setPaginationState((prevState) => ({
                                            ...prevState,
                                            resultPerPage: 10,
                                            selectedPage: 1,
                                            lastPage: 1,
                                        }));
                                    }}>
                                    <Tab
                                        label='Presets List'
                                        isActive={tab === 'presets-list'}
                                        bannerTabStyle='md:text-[15px] mobile:text-sm'
                                    />
                                </div>
                            </div>
                        </div>

                        {tab === 'notifications-list' && (
                            <div className='flex flex-row items-center justify-between lg:justify-start mt-4 md:block'>
                                <div className='flex flex-row justify-between'>
                                    <div className='w-fit'>
                                        <CalenderField
                                            setSelectedDates={setSelectedDates}
                                            selectedDates={selectedDates}
                                        />
                                    </div>
                                    {showCancelDelete ? (
                                        <div className='flex flex-row items-center md:w-full'>
                                            <div
                                                className='max-w-[120px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                                onClick={handleClickCancel}>
                                                <LargeTertiaryButton
                                                    isDefault={false}
                                                    label='Cancel'
                                                    hideLabel='lg:hidden'
                                                    leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                                                    leftIconClick={<CloseIcon stroke='#C4BEED' />}
                                                />
                                            </div>
                                            <div
                                                className='max-w-[200px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                                onClick={handleClickDelete}>
                                                <LargeDestructiveButton
                                                    label='Delete selected'
                                                    leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                                    leftIconClick={<SelectIcon stroke='#D7EDEB' />}
                                                    hideLabel='lg:hidden'
                                                    disabled={isDisable}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className='max-w-[156px] w-full md:max-w-[64px] ml-4 lg:ml-2 md:ml-1'
                                            onClick={handleBulkSelect}>
                                            <LargePrimaryButton
                                                leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                                leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                                label='Bulk select'
                                                hideLabel='lg:hidden'
                                            />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className='w-[214px] lg:ml-2 md:ml-0 md:mt-4 md:w-full cursor-pointer'
                                    onClick={() => {
                                        togglePopup('Create notification', 'Send notification to your customers');
                                        setCreateNotification(!createNotification);
                                    }}>
                                    <LargePrimaryButton
                                        isDefault={false}
                                        leftIconDefault={<AddIcon stroke='#ffffff' />}
                                        leftIconClick={<AddIcon stroke='#C4BEED' />}
                                        label='Create notification'
                                    />
                                </div>
                            </div>
                        )}

                        {tab === 'notifications-list' ? (
                            <NotificationList
                                handleClickTitle={togglePopup}
                                showCheckbox={showCheckbox}
                                handleCheckboxChange={handleCheckboxChange}
                                checkedItems={checkedItems}
                                setCheckedItems={setCheckedItems}
                                notificationList={notificationList}
                                selectedLangaugeId={selectedLangaugeId}
                                fetchDetailsOfNotification={fetchDetailsOfNotification}
                                editNotification={editNotification}
                                setEditNotification={setEditNotification}
                                isNotificationListLoading={isNotificationListLoading}
                                fetchNotificationList={fetchNotificationList}
                                paginationState={paginationState}
                            />
                        ) : (
                            <PresetList
                                handleClickName={togglePopup}
                                presetList={presetList}
                                selectedLangaugeId={selectedLangaugeId}
                                fetchDetailsOfPreset={fetchDetailsOfPreset}
                                setEditPreset={setEditPreset}
                                editPreset={editPreset}
                                isPresetListLoading={isPresetListLoading}
                                paginationState={paginationState}
                                fetchPresetList={fetchPresetList}
                            />
                        )}

                        {!isMobileScreen && (!_.isEmpty(presetList) || !_.isEmpty(notificationList)) && (
                            <div className='mt-4'>
                                <PaginationWithNumber
                                    lastPage={paginationState.lastPage}
                                    selectedPage={paginationState.selectedPage}
                                    setSelectedPage={(value) => {
                                        handlePagination({ selectedPage: value }, 'selectedPage');
                                    }}
                                    setResultPerPage={(value) =>
                                        handlePagination({ resultPerPage: value }, 'resultPerPage')
                                    }
                                    selectedResultPerpage={paginationState.resultPerPage}
                                    isLoading={isNotificationListLoading || isPresetListLoading}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>

            {!_.isEmpty(notificationDetail) && editNotification && (
                <NotificationPopup
                    handleClickClose={() => {
                        togglePopup();
                        setNotificationDetail({});
                        setIsEditNotificationFieldEnabled(false);

                        setEditNotification(false);
                    }}
                    handleClickUploadImage={handleClickUploadImage}
                    pageTitle={notificationPopupTitle}
                    pageTagline={notificationPopupTagline}
                    popupDetails={popupDetails}
                    selectedLangaugeId={selectedLangaugeId}
                    showUploadImagePopup={showUploadImagePopup}
                    setShowUploadImagePopup={setShowUploadImagePopup}
                    uploadImageBackToPageName={uploadImageBackToPageName}
                    setshowNotificaionPopup={setshowNotificaionPopup}
                    editNotification={editNotification}
                    setEditNotification={setEditNotification}
                    isNotificationEdited={isNotificationEdited}
                    setIsNotificationEdited={setIsNotificationEdited}
                    details={notificationDetail}
                    metaTagsList={metaTagsList}
                    screensList={screensList}
                    isPresetDetailLoading={isPresetDetailLoading}
                    categoriesList={categoriesList}
                    setCheckedItems={setCheckedItems}
                    handleConfirmDeletePopup={handleConfirmDeletePopup}
                    handleClick={handleClick}
                    isEditNotificationFieldEnabled={isEditNotificationFieldEnabled}
                    setIsEditNotificationFieldEnabled={setIsEditNotificationFieldEnabled}
                />
            )}

            {!_.isEmpty(presetDetail) && editPreset && (
                <NotificationPopup
                    handleClickClose={() => {
                        togglePopup();
                        setPresetDetail({});
                        setEditNotification(false);
                        setIsEditNotificationFieldEnabled(false);
                    }}
                    handleClickUploadImage={handleClickUploadImage}
                    pageTitle={notificationPopupTitle}
                    pageTagline={notificationPopupTagline}
                    popupDetails={popupDetails}
                    selectedLangaugeId={selectedLangaugeId}
                    showUploadImagePopup={showUploadImagePopup}
                    setShowUploadImagePopup={setShowUploadImagePopup}
                    uploadImageBackToPageName={uploadImageBackToPageName}
                    setshowNotificaionPopup={setshowNotificaionPopup}
                    details={presetDetail}
                    setEditPreset={setEditPreset}
                    setIsPresetEdited={setIsPresetEdited}
                    isPresetEdited={isPresetEdited}
                    editPreset={editPreset}
                    metaTagsList={metaTagsList}
                    isPresetDetailLoading={isPresetDetailLoading}
                    handleClick={handleClick}
                    fetchPresetList={fetchPresetList}
                />
            )}

            {createNotification && (
                <CreateNotificationPopup
                    handleClickClose={() => {
                        setCreateNotification(!createNotification);
                    }}
                    metaTagsList={metaTagsList}
                    screensList={screensList}
                    categoriesList={categoriesList}
                    setShowUploadImagePopup={setShowUploadImagePopup}
                    createNotification={createNotification}
                    setCreateNotification={setCreateNotification}
                    handleClick={handleClick}
                    isNotificationCreated={isNotificationCreated}
                    setIsNotificationCreated={setIsNotificationCreated}
                />
            )}

            {showConfirmDeletePopup && (
                <ConfirmDeletePopup
                    header={<h2 className='paragraph-large-medium ml-2'>Delete the review?</h2>}
                    description={
                        <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                            Are you sure you want to delete this review?
                        </p>
                    }
                    confirmDelete={handleDeleteBulkSelect}
                    handleClickClose={handleCloseDeletePopup}
                />
            )}
        </>
    );
}
