import React, { useMemo, useState } from 'react';
import CalenderField from '../../../Components/Calender/CalenderField';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { ReactComponent as Settings } from '../../../Assets/settings.svg';
import { useWindowSize } from '@uidotdev/usehooks';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import { ReportCardLoader } from '../../../Components/ContentLoader/ReportCardLoader';

const DownArrow = ({
    isReportGenerated,
    reportData,
    selectedDates,
    reportDetails,
    setSelectedDates,
    selectedDownload,
    setSelectedDownload,
    DownloadsItems,
}) => {
    const orderingModeMenuItems = [
        { label: 'Pending', value: '/Pending' },
        { label: 'Failed', value: '/Failed' },
        { label: 'InProgress', value: '/InProgress' },
        { label: 'Completed', value: '/Completed' },
        { label: 'Canceled', value: '/Canceled' },
    ];
    const paymentStatusMenuItems = useMemo(
        () => [
            { label: 'Cash', value: 'cash' },
            { label: 'Card', value: 'card' },
            { label: 'Due Payment', value: 'duePayment' },
            { label: 'Other', value: 'other' },
            { label: 'Wallet', value: 'wallet' },
            { label: 'Online', value: 'online' },
        ],
        []
    );

    const [selectedOrderingMode, setSelectedOrderingMode] = useState(orderingModeMenuItems[0]);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(paymentStatusMenuItems[0]);

    const handleSelectionForPayment = (selection) => {
        setSelectedPaymentStatus(selection);
    };
    const handleSelectionForOrderingMode = (selection) => {
        setSelectedOrderingMode(selection);
    };
    const handleSelectionForDownloads = (selection) => {
        setSelectedDownload(selection);
    };

    return reportDetails && reportDetails.length > 0 ? (
        <div className='flex flex-row xl:flex-col xl:ml-0 gap-3 items-center justify-center'>
            <div className='w-full'>
                <span className='paragraph-small-semi-bold text-neutral-600 ml-2 lg:ml-0'>DURATION OF COLLECTIONS</span>
                <div className='lg:-ml-4'>
                    <CalenderField
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                    />
                </div>
            </div>

            <div className='w-full'>
                <span className='paragraph-small-semi-bold text-neutral-600 ml-1'>PAYMENT STATUS</span>
                <div>
                    <DropdownWithValueLabel
                        selectedItem={selectedPaymentStatus}
                        menuItems={paymentStatusMenuItems}
                        setSelected={(selection) => {
                            handleSelectionForPayment(selection);
                        }}
                    />
                </div>
            </div>

            <div className='w-full'>
                <span className='paragraph-small-semi-bold text-neutral-600 ml-1'>VENDOR</span>
                <DropdownWithValueLabel
                    selectedItem={selectedOrderingMode}
                    menuItems={orderingModeMenuItems}
                    setSelected={(selection) => {
                        handleSelectionForOrderingMode(selection);
                    }}
                />
            </div>

            {/* SELECT DOWNLOADS Button */}
            <div className='w-full'>
                <span className='paragraph-small-semi-bold text-neutral-600 ml-1'>Download PDF/CSV</span>
                <div>
                    <DropdownWithValueLabel
                        selectedItem={selectedDownload}
                        menuItems={DownloadsItems}
                        setSelected={(selection) => {
                            handleSelectionForDownloads(selection);
                        }}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className='w-full h-[80px] flex items-center justify-center'>
            <ReportCardLoader />
        </div>
    );
};

export default DownArrow;
