import React, { useEffect, useLayoutEffect, useState } from 'react';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import { useSelector } from 'react-redux';
import InputSection from '../../../../../Components/InputField/InputSection';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import SetSeparatePrice from '../../../AddDish/TabComponents/DishPricing/Components/SetSeparatePrice';
import Variants from '../../../AddDish/TabComponents/DishPricing/Components/Variants';
import VariantGroupForm from '../../../AddDish/TabComponents/DishPricing/Components/VariantGroupForm';

const SetPriceAddOn = ({ activeTab }) => {
    const {
        watch,
        formState: { errors },
        setValue,
        getValues,
    } = useFormContext();
    useLayoutEffect(() => {
        setValue('do_add_variant', true);
        setValue('AddOn.Choices', [{ id: 0 }]);
        setValue('activeVariant', 0);
    }, []);
    const [showError, setShowError] = useState(false);

    const availableLanguages = useSelector((state) => state?.langauge?.availableLanguages);
    let activeVariant = watch('activeVariant');

    return (
        <>
            {activeTab !== 0 ? (
                <SetSeparatePrice
                    id='AddOn.dishPrice'
                    name='AddOn.Choices'
                />
            ) : (
                <>
                    <Variants
                        showError={showError}
                        setShowError={setShowError}
                        activeVariantName='activeVariant'
                        availableVariantName='AddOn.Choices'
                    />
                    {watch('AddOn.Choices')?.map((el, variantIndex) => {
                        if (activeVariant !== el.id) return null;
                        const hasValidTitle = el.title?.some((title) => title?.trim()?.length > 0);
                        return (
                            <div key={variantIndex}>
                                <TitleDescription
                                    className='mb-4'
                                    title='Variant name'
                                    description='Lorem ipsum dolor sit amet consectetur, adipisicing elit. '
                                />

                                <div className='flex flex-row gap-2 mb-4'>
                                    {availableLanguages.map((el, index) => {
                                        return (
                                            <div
                                                className='w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full md:mr-0 md:mb-2 relative'
                                                key={index}>
                                                <InputSection
                                                    boxHeight='[52px]'
                                                    labelMarginB='mb-2'
                                                    label={`(${el.language_label})`}
                                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                                    placeholder={`Enter title in ${el.language_label}`}
                                                    shadow='shadow-smallDropDownShadow'
                                                    name={`AddOn.Choices[${activeVariant}].title.${el.id}`}
                                                    rules={{
                                                        required: `Please enter title in ${el.language_label}`,
                                                    }}
                                                />
                                                {!hasValidTitle && showError && (
                                                    <span className='text-red-500 text-sm'>
                                                        Please ensure all variants have a name before adding another.
                                                    </span>
                                                )}

                                                {/* {errors?.pricing?.variantPrice?.[activeVariant]?.title?.[el.id] && (
                                                    <ErrorMessage
                                                        errors={
                                                            errors?.pricing?.variantPrice?.[activeVariant]?.title?.[
                                                                el.id
                                                            ]
                                                        }
                                                    />
                                                )} */}
                                            </div>
                                        );
                                    })}
                                </div>

                                <SetSeparatePrice
                                    id={`AddOn.Choices.${el.id}`}
                                    name='AddOn.Choices'
                                />
                            </div>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default SetPriceAddOn;
