import { useCallback, useEffect, useRef, useState } from 'react';
import { format, isAfter, isBefore, max, min, subWeeks } from 'date-fns';
import { ReactComponent as CalenderIcon } from '../../Assets/calendar.svg';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import { styles } from './styles';
import './styles.css';

export default function CalenderField(props) {
    const { isShowSpacing = true } = props;
    const today = new Date();

    const [selectedDates, setSelectedDates] = useState(props.selectedDates ?? [today, today]);
    const [showModelCalendar, setShowModelCalendar] = useState(false);

    const [dateSelection, setDateSelection] = useState([]);

    const getLastWeekRange = () => {
        const currentDate = new Date();
        const lastWeekStart = startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 0 }); // Start from Sunday
        const lastWeekEnd = endOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 0 }); // End on Saturday
        return [lastWeekStart, lastWeekEnd];
    };
    useEffect(() => {
        function handleClickOutside(event) {
            wrapperRef.current = document.querySelector('.rs-picker-menu');
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                handleClickCancelFunction();
            }
        }

        if (showModelCalendar) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

        // eslint-disable-next-line
    }, [showModelCalendar]);

    const predefinedRanges = [
        {
            label: 'Today',
            value: [today, today],
            placement: 'left',
            // closeOverlay: false,
            // onClick: (e) => setSelectedDates([today, today]),
        },
        {
            label: 'Yesterday',
            value: [addDays(today, -1), addDays(today, -1)],
            placement: 'left',
            // closeOverlay: false,
            // onClick: () => setSelectedDates([addDays(today, -1), addDays(today, -1)]),
        },
        {
            label: 'This week',
            value: [startOfWeek(today), today],
            placement: 'left',
            // closeOverlay: false,
            // onClick: () => setSelectedDates([startOfWeek(today), today]),
        },
        {
            label: 'Last week',
            value: getLastWeekRange(),
            placement: 'left',
            // closeOverlay: false,
            // onClick: () => setSelectedDates([subDays(today, 6), today]),
        },
        {
            label: 'Last 30 days',
            value: [subDays(today, 29), today],
            placement: 'left',
            // closeOverlay: false,
            // onClick: () => setSelectedDates([subDays(today, 29), today]),
        },
        {
            label: 'Last 90 days',
            value: [subDays(today, 89), today],
            placement: 'left',
            // closeOverlay: false,
            // onClick: () => setSelectedDates([subDays(today, 89), today]),
        },
    ];

    const [tempDateStore, setTempDateStore] = useState(null);

    const handleSelection = (value) => {
        setTempDateStore(value);

        dateSelection.length === 2 ? setDateSelection([value]) : setDateSelection((prevState) => [...prevState, value]);

        if (isBefore(value, selectedDates?.[0]) || isBefore(value, selectedDates?.[1])) {
            setSelectedDates([value]);
        } else {
            setSelectedDates([tempDateStore, value]);
        }

        insertInnerHtmlOfButton([...dateSelection, value]);
    };

    const formatDate = (value, index) => {
        const minDate = min(value);
        const maxDate = max(value);

        const formattedDate = (date) => format(date, 'dd/MM/yyyy');

        return index === 0 ? formattedDate(minDate, index) : formattedDate(maxDate, index);
    };

    const handleClickApplyChanges = (e, selectedDates) => {
        e.preventDefault();
        setShowModelCalendar(false);
        props?.setSelectedDates?.(selectedDates);
    };

    const handleClickCancelFunction = (e) => {
        e?.preventDefault();
        setShowModelCalendar(false);
        setSelectedDates(props.selectedDates);
        props?.setSelectedDates?.(props.selectedDates);
    };

    const insertInnerHtmlOfButton = (currentSelectedDate) => {
        setTimeout(() => {
            const customButton = document.querySelector('.rs-picker-toolbar');
            customButton.innerHTML = `
                <div class="flex flex-row items-center justify-between w-full bg-white h-[55px] md:block md:w-[280px]">
                    <div class="paragraph-small-medium flex flex-row md:mb-2">
                        <span class="px-4 h-10 flex items-center justify-center border border-neutral-200 rounded-md mr-2 md:w-[140px]">${formatDate(
                            currentSelectedDate,
                            0
                        )}</span>
                        <span class="px-4 h-10 flex items-center justify-center border border-neutral-200 rounded-md ml-2 md:w-[140px]">${formatDate(
                            currentSelectedDate,
                            1
                        )}</span>
                    </div>

                    <div class="flex flex-row paragraph-small-medium ml-1">
                        <button class="px-4 border border-neutral-200 rounded-md removeFocusCancel mr-2">Cancel</button>
                        <button class="bg-primary-500 h-10 rounded-md text-white px-4 removeFocusToApplyChanges hover:bg-primary-600 disabled:bg-primary-200 group w-full items-center flex justify-center">Apply changes</button>
                    </div>
                </div>`;

            const applyChangesButton = document.querySelector('.removeFocusToApplyChanges');
            const cancelButton = document.querySelector('.removeFocusCancel');

            if (currentSelectedDate.length < 2) {
                applyChangesButton.setAttribute('disabled', true);
            } else {
                applyChangesButton.removeAttribute('disabled');
            }

            applyChangesButton.addEventListener('click', (e) => handleClickApplyChanges(e, currentSelectedDate));
            cancelButton.addEventListener('click', handleClickCancelFunction);
        }, 1);
    };

    const wrapperRef = useRef(null);
    const handleClickCalander = (e) => {
        if (wrapperRef.current == null) {
            setShowModelCalendar(true);
            insertInnerHtmlOfButton(selectedDates);
        } else {
            setShowModelCalendar(false);
            wrapperRef.current = null;
        }
    };

    const todayPlaceholder = () => {
        const formattedToday = format(today, 'dd MMM yyyy');

        return `${formattedToday} - ${formattedToday}`;
    };

    const disableDate = (date) => {
        const today = new Date();

        const ninetyDaysAgo = subDays(today, 90);

        return isAfter(date, today) || isAfter(ninetyDaysAgo, date);
    };
    const onChange = (dates) => {
        insertInnerHtmlOfButton(dates);
        setSelectedDates(dates);
    };

    return (
        <>
            <style>{styles()}</style>

            <div className='relative cursor-pointer'>
                <Stack
                    direction='row'
                    spacing={isShowSpacing ? 8 : 0}
                    alignItems='flex-center'
                    style={{ width: '100%' }}
                    // className='calender-stack'
                >
                    <span className='left-5 z-[7] absolute md:left-75'>
                        <CalenderIcon style={{ marginTop: '12px' }} />
                    </span>
                    <DateRangePicker
                        onClick={(e) => handleClickCalander(e)}
                        onSelect={(value) => handleSelection(value)}
                        onChange={onChange}
                        ranges={predefinedRanges}
                        placeholder={todayPlaceholder()}
                        style={{ width: '100%' }}
                        format='dd MMM yyyy'
                        value={
                            !selectedDates?.length || selectedDates?.length <= 1 ? props.selectedDates : selectedDates
                        }
                        character=' - '
                        open={showModelCalendar}
                        onClose={() => (wrapperRef.current = null)}
                        shouldDisableDate={(date) => disableDate(date)}
                    />
                </Stack>
            </div>
        </>
    );
}
