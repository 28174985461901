import React, { useEffect, useState } from 'react';
import { fetchOrderDetail, fetchWaiterOrders } from './Helper/functions';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import TableLoader from '../../Components/ContentLoader/TableLoader';
import ListViewOrders from './Components/ListViewOrders';
import { useWindowSize } from '@uidotdev/usehooks';

const Waiter = () => {
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const [waiterOrderDetail, setWaiterOrderDetail] = useState([]);
    const [isKotView, setIsKotView] = useState(false);
    const [isFetchingOrders, setIsFetchingOrders] = useState(true);
    const [selectedOrderingMode, setSelectedOrderingMode] = useState('all');
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [paginationState, setPaginationState] = useState({
        currentPage: 1,
        lastPage: 1,
        perPage: 10,
    });
    const [showPopup, setShowPopup] = useState(null);
    const [orderItems, setOrderItems] = useState('');

    const resetState = () => {
        setSelectedOrderingMode('all');
        setSelectedFilter('all');
        setIsFetchingOrders(false);
        setPaginationState({ currentPage: 1, lastPage: 1, perPage: 10 });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsFetchingOrders(true);
                const response = await fetchWaiterOrders({
                    startDate: currentDate,
                    endDate: currentDate,
                    selectedOrderingMode: 'dine_in',
                    selectedRestaurantId,
                    selectedStatus: 'restaurant_accepted',
                    perPage: 10,
                    page: paginationState.currentPage,
                });
                setWaiterOrderDetail(response);
                setIsFetchingOrders(false);
            } catch (error) {
                console.error('Error fetching waiter orders:', error);
                setIsFetchingOrders(false); // Stop the loader in case of error
            }
        };

        fetchData();
    }, [currentDate, selectedRestaurantId, paginationState.currentPage]);

    useEffect(() => {
        if (waiterOrderDetail?.data?.length > 0) {
            const fetchAllOrders = async () => {
                try {
                    const allOrderDetails = await Promise.all(
                        waiterOrderDetail?.data?.map(async (order) => {
                            const response = await fetchOrderDetail(order?.order_id);
                            return { ...order, details: response };
                        })
                    );
                    setOrderItems(allOrderDetails);
                } catch (error) {
                    console.error('Error fetching order details:', error);
                }
            };

            fetchAllOrders();
        }
    }, [waiterOrderDetail]);
    const screenHeight = useWindowSize().height;

    return (
        <>
            {/* {isFetchingOrders ? (
                <TableLoader
                    hasSwitch={false}
                    columns={5}
                />
            ) : ( */}
            <>
                <div className='bg-[#fafafa]'>
                    <div
                        className={`px-8 pageContentSmall:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white`}
                        style={{ minHeight: screenHeight - 50 }}>
                        {!isKotView && (
                            <ListViewOrders
                                orderItems={orderItems}
                                setShowPopup={setShowPopup}
                                waiterOrders={waiterOrderDetail}
                                paginationState={paginationState}
                                setPaginationState={setPaginationState}
                                isFetchingOrders={isFetchingOrders}
                                // selectedOrder={selectedOrder}
                                // setSelectedOrder={setSelectedOrder}
                                // onReady={onReady}
                                // selectedOrderDetail={selectedOrderDetail}
                                // setSelectedOrderDetail={setSelectedOrderDetail}
                                // handleClickClose={() => setShowPopup(null)}
                                // setCancelRider={setCancelRider}
                            />
                        )}
                    </div>
                </div>
            </>
            {/* )} */}
        </>
    );
};

export default Waiter;
