import { ReactComponent as CalenderIcon } from '../../../../Assets/calendar.svg';
import { ReactComponent as ScheduleIcon } from '../../../../Assets/schedule.svg';
import { ReactComponent as LinearMenuIcon } from '../../../../Assets/linear-menu.svg';
import { ReactComponent as EditIcon } from '../../../../Assets/edit.svg';
import { Link } from '../../../../Components/LinkOffer/Link';
import { useSelector } from 'react-redux';
import { CheckBoxWithoutLabels } from '../../../../Components/FormControl/FormControls';
import TimeSloteSection from '../BannerPopupComponent/TimeSloteSection';
import { formateDateTime } from '../../functions';

export const TableRows = (props) => {
    const {
        isEditbanner,
        tab,
        item,
        handleClickUnlink,
        provided,
        isRowSelected,
        handleSelection,
        isSelectionEnable,
        setEditBannerData,
        setLinkBannerData,
    } = props;

    const availableModes = item.ordering_modes.map((modes) => modes);

    const validFromDateTime = formateDateTime(item.from_date);

    const validToDateTime = formateDateTime(item.to_date);

    const orderingModes = useSelector((state) => state.outlet?.orderingModes);

    const availableOrderingModes = (modes) => {
        return modes
            .map((availableMode) => {
                const orderingMode = orderingModes?.find(
                    (mode) => mode.id === availableMode.restaurant_ordering_mode_id
                );

                return orderingMode ? (
                    <span
                        className='inline-block px-2 py-[3px] border border-primary-500 rounded mr-1 my-0.5 bg-primary-50 text-primary-500'
                        key={availableMode.id}>
                        {orderingMode.display_name}
                    </span>
                ) : null;
            })
            .filter(Boolean);
    };

    return (
        <>
            <tr
                className={`h-[70px] justify-center paragraph-small-regular ${
                    isRowSelected
                        ? 'border border-primary-500 bg-primary-50'
                        : 'border-t first:border-none border-neutral-300 even:bg-neutral-50'
                }`}
                ref={provided?.innerRef}
                {...provided?.draggableProps}
                {...provided?.dragHandleProps}>
                <td
                    className={`pl-6 h-[70px] min-w-[254px] relative ${isEditbanner && 'cursor-pointer'}`}
                    onClick={() => isEditbanner && setEditBannerData(item)}>
                    <div className='flex flex-row items-center'>
                        {isEditbanner && (
                            <span className='absolute left-1.5'>
                                <EditIcon
                                    width={16}
                                    height={16}
                                />
                            </span>
                        )}

                        {isSelectionEnable && (
                            <span className='mr-2 -ml-2'>
                                <CheckBoxWithoutLabels
                                    onChange={handleSelection}
                                    id={item.title}
                                    isChecked={isRowSelected}
                                />
                            </span>
                        )}

                        <img
                            src={item?.translations?.image?.[1]?.image_url}
                            alt='banner'
                            className='w-20 h-[45px]'
                        />

                        <span className='ml-3'>{item.title}</span>
                    </div>
                </td>

                <td className='pl-6 min-w-[158px]'>
                    {validFromDateTime ? (
                        <>
                            <div className='flex flex-row items-center mb-1'>
                                <CalenderIcon className='w-6 h-6' />
                                <span>{validFromDateTime.date}</span>
                            </div>

                            <div className='flex flex-row items-center'>
                                <ScheduleIcon className='w-6 h-6' />
                                <span>{validFromDateTime.time}</span>
                            </div>
                        </>
                    ) : (
                        <div className='text-center'>-</div>
                    )}
                </td>

                <td className='pl-6 min-w-[159px]'>
                    {validToDateTime ? (
                        <>
                            <div className='flex flex-row items-center mb-1'>
                                <CalenderIcon className='w-6 h-6' />
                                <span>{validToDateTime.date}</span>
                            </div>

                            <div className='flex flex-row items-center'>
                                <ScheduleIcon className='w-6 h-6' />
                                <span>{validToDateTime.time}</span>
                            </div>
                        </>
                    ) : (
                        <div className='text-center'>-</div>
                    )}
                </td>

                <td className='pl-6 min-w-[156px]'>{item.screen.charAt(0).toUpperCase() + item.screen.slice(1)}</td>
                <td className='pl-6 min-w-[177px]'>{availableOrderingModes(availableModes)}</td>

                <td className='pl-6 min-w-[215px]'>
                    <TimeSloteSection item={item} />
                </td>

                {tab === 'banner-catalogue' ? (
                    <td
                        className='pl-6 cursor-pointer min-w-[151px]'
                        onClick={() => setLinkBannerData(item)}>
                        <Link linkOfferNumber={item.outlet.length} />
                    </td>
                ) : (
                    <td
                        className='pl-6 cursor-pointer min-w-[151px]'
                        onClick={() => handleClickUnlink(item.id)}>
                        <Link
                            linkOfferNumber={item.linkOutletNumber}
                            linkLabel='Unlink'
                        />
                    </td>
                )}
            </tr>
        </>
    );
};
