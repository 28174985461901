import React from 'react';
import { useParams } from 'react-router-dom';
import PaginationWithNumber from '../../../Components/Pagination/PaginationWithNumber';
import RestaurantOfferTableSection from './RestaurantOfferTableSection';
import OutletWiseOfferSection from './OutletWiseOfferSection';
import APIV2 from '../../../api/axios/APIV2';
import { useSelector } from 'react-redux';

export default function TableSection(props) {
    const {
        list,
        fetchRestaurantOfferList,
        activeState,
        pageState,
        setPageState,
        selectedOffer,
        setSelectedOffer,
        isListLoading,
        setLinkOfferId,
        fetchOutletWiseOffersList,
        setActiveState,
        setIsListLoading,
        setSelectedOfferId,
        isLoading,
    } = props;

    const { tab } = useParams();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const isRestaurantOfferPage = tab !== 'outlet-offer-list';

    const handlePageStateChange = (state, value, page) => {
        const newState = {
            ...pageState[page],
            ...(state === 'per_page' ? { page: 1, [state]: value } : { [state]: value }),
        };

        setPageState((prevState) => {
            return { ...prevState, [page]: newState };
        });

        isRestaurantOfferPage ? fetchRestaurantOfferList(newState) : fetchOutletWiseOffersList(newState);
    };

    const paginationParameter = (page) => {
        const params = {
            lastPage: pageState[page].last_page,
            selectedPage: pageState[page].page,
        };

        return params;
    };

    const showPagination =
        !activeState.reArrange &&
        (isRestaurantOfferPage ? list.restaurantOfferList.length : list.outletwiseOffersList.length);

    const handleStatusChange = async (id) => {
        try {
            await APIV2.patch(`restaurants/${selectedRestaurantId}/offers/${id}/toggle-active`);
            // fetchRestaurantOfferList();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {isRestaurantOfferPage ? (
                <RestaurantOfferTableSection
                    fetchRestaurantOfferList={fetchRestaurantOfferList}
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                    activeState={activeState}
                    list={list.restaurantOfferList}
                    isListLoading={isListLoading}
                    setLinkOfferId={setLinkOfferId}
                    handleStatusChange={handleStatusChange}
                    setSelectedOfferId={setSelectedOfferId}
                />
            ) : (
                <OutletWiseOfferSection
                    list={list.outletwiseOffersList}
                    activeState={activeState}
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                    isListLoading={isListLoading}
                    fetchRestaurantOfferList={fetchRestaurantOfferList}
                    fetchOutletWiseOffersList={fetchOutletWiseOffersList}
                    setLinkOfferId={setLinkOfferId}
                    pageState={pageState}
                    setActiveState={setActiveState}
                    setIsListLoading={setIsListLoading}
                />
            )}

            {!!showPagination &&
                (isRestaurantOfferPage ? (
                    <section className='mt-4'>
                        <PaginationWithNumber
                            setSelectedPage={(value) => handlePageStateChange('page', value, 'restaurantPageState')}
                            setResultPerPage={(value) =>
                                handlePageStateChange('per_page', value, 'restaurantPageState')
                            }
                            selectedResultPerpage={pageState.restaurantPageState.per_page}
                            {...paginationParameter('restaurantPageState')}
                            isLoading={isLoading}
                        />
                    </section>
                ) : (
                    !activeState.reArrange && (
                        <section className='mt-4'>
                            <PaginationWithNumber
                                setSelectedPage={(value) => handlePageStateChange('page', value, 'outletPageState')}
                                setResultPerPage={(value) =>
                                    handlePageStateChange('per_page', value, 'outletPageState')
                                }
                                selectedResultPerpage={pageState.outletPageState.per_page}
                                {...paginationParameter('outletPageState')}
                                isLoading={isLoading}
                            />
                        </section>
                    )
                ))}
        </>
    );
}
