import React, { useState } from 'react';
import { ReactComponent as DropDownIcon } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CalenderIcon } from '../../../../Assets/calendar.svg';
import { ReactComponent as ScheduleIcon } from '../../../../Assets/schedule.svg';
import { formateDateTime } from '../../functions';
import { Link } from '../../../../Components/LinkOffer/Link';
import { useSelector } from 'react-redux';
import TimeSloteSection from '../BannerPopupComponent/TimeSloteSection';

export default function BannerCard(props) {
    const { item, tab, setLinkBannerData, handleClickUnlink } = props;

    const [showDetails, setShowDetails] = useState(false);

    const orderingModes = useSelector((state) => state.outlet?.orderingModes);

    const handleClickCard = () => {
        setShowDetails(!showDetails);
    };

    const availableModes = item.ordering_modes.map((modes) => modes);

    const validFromDateTime = formateDateTime(item.from_date);

    const validToDateTime = formateDateTime(item.to_date);

    const availableOrderingModes = (modes) => {
        return modes
            .map((availableMode) => {
                const orderingMode = orderingModes?.find(
                    (mode) => mode.id === availableMode.restaurant_ordering_mode_id
                );

                return orderingMode ? (
                    <span
                        className='inline-block px-2 py-[3px] border border-primary-500 rounded mr-1 my-0.5 bg-primary-50 text-primary-500'
                        key={availableMode.id}>
                        {orderingMode.display_name}
                    </span>
                ) : null;
            })
            .filter(Boolean);
    };

    return (
        <>
            <div className='border border-neutral-300 px-4 py-3 rounded-md w-full overflow-hidden'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickCard}>
                    <div className='flex flex-row items-center'>
                        <img
                            src={item?.translations?.image?.[1]?.image_url}
                            alt=''
                            className='h-[45px] w-20 mr-3'
                        />
                        <span className='paragraph-small-regular'>{item.title}</span>
                    </div>
                    <div className=''>
                        <DropDownIcon className={`${showDetails && 'rotate-180'}`} />
                    </div>
                </div>

                {showDetails && (
                    <>
                        <div className='flex flex-row items-center mt-2.5'>
                            <span className='paragraph-overline-small mobile:text-[11px]'>VALID FROM:</span>
                            {validFromDateTime ? (
                                <>
                                    <div className='flex flex-row items-center mx-1'>
                                        <CalenderIcon
                                            height={20}
                                            width={20}
                                        />
                                        <span className='ml-1 paragraph-small-regular mobile:text-[11px]'>
                                            {validFromDateTime.date}
                                        </span>
                                    </div>
                                    <div className='flex flex-row items-center'>
                                        <ScheduleIcon
                                            height={20}
                                            width={20}
                                        />
                                        <span className='ml-1 paragraph-small-regular mobile:text-[11px]'>
                                            {validToDateTime.time}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <div className='pl-4 text-center'>-</div>
                            )}
                        </div>

                        <div className='flex flex-row items-center mt-2.5'>
                            <span className='paragraph-overline-small mobile:text-[11px]'>VALID TILL:</span>
                            {validToDateTime ? (
                                <>
                                    <div className='flex flex-row items-center ml-3'>
                                        <CalenderIcon
                                            height={20}
                                            width={20}
                                        />
                                        <span className='ml-1 paragraph-small-regular mobile:text-[11px]'>
                                            {validToDateTime.date}
                                        </span>
                                    </div>
                                    <div className='flex flex-row items-center ml-[4px]'>
                                        <ScheduleIcon
                                            height={20}
                                            width={20}
                                        />
                                        <span className='ml-1 paragraph-small-regular mobile:text-[11px]'>
                                            {validToDateTime.time}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <div className='pl-4 text-center'>-</div>
                            )}
                        </div>

                        <div className='flex flex-row items-center mt-2.5'>
                            <span className='paragraph-overline-small mobile:text-[11px]'>BANNER SCREEN:</span>
                            <div className='flex flex-row items-center mx-1'>
                                <span className='paragraph-small-regular mobile:text-[11px]'>
                                    {item.screen.charAt(0).toUpperCase() + item.screen.slice(1)}
                                </span>
                            </div>
                        </div>

                        <div className='flex flex-row mt-2.5 mobile:block'>
                            <span className='paragraph-overline-small mobile:text-[11px]'>AVAILABLE MODES:</span>
                            <div className='flex flex-col ml-1 mobile:ml-0 mobile:block'>
                                {availableOrderingModes(availableModes)}
                            </div>
                        </div>

                        <div className='flex flex-row mobile:block'>
                            <span className='paragraph-overline-small mobile:text-[11px]'>AVAILABLE TIME SLOTS:</span>

                            <div className='ml-2'>
                                <TimeSloteSection item={item} />
                            </div>
                        </div>

                        <div className='flex flex-row items-center mt-2.5'>
                            {tab === 'banner-catalogue' ? (
                                <div
                                    className='cursor-pointer min-w-[151px]'
                                    onClick={() => setLinkBannerData(item)}>
                                    <Link linkOfferNumber={item.outlet.length} />
                                </div>
                            ) : (
                                <div
                                    className='cursor-pointer min-w-[151px]'
                                    onClick={() => handleClickUnlink(item.id)}>
                                    <Link
                                        linkOfferNumber={item.linkOutletNumber}
                                        linkLabel='Unlink'
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
