import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import { bannerType } from './constants';

export default function TypeSelection({ bannerTypeCheck, editBannerData }) {
    const { control, setValue } = useFormContext();

    useEffect(() => {
        const matchedBannerType = bannerType.find((type) => type.url === bannerTypeCheck);

        if (matchedBannerType) {
            setValue('bannerType', matchedBannerType);
        }
    }, [bannerTypeCheck, setValue]);

    return (
        <Controller
            name='bannerType'
            control={control}
            render={({ field: { value, onChange } }) => (
                <DropdownWithValueLabel
                    label='Banner type'
                    boxHeight='h-[44px]'
                    name='bannerType'
                    disabled={editBannerData}
                    className='mb-[13px] relative'
                    menuItems={bannerType}
                    maxWidth='max-w-full'
                    selectedItem={value}
                    onSelect={(selectedItem) => {
                        onChange(selectedItem);
                    }}
                />
            )}
        />
    );
}
