import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from './Layout';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import BasicDetails from '../TabComponents/BasicDetails';
import Configuration from '../TabComponents/Configuration/Configuration';
import LinkToCategory from '../TabComponents/LinkToCategory/LinkToCategory';
import { preferenceMenuItem } from '../../../../Constants/Constants';
import LinkToAddOnChoice from '../TabComponents/LinkToCategory/LinkToAddOnChoice';

export default function DishWithUseForm(props) {
    const { defaultDishFormState } = props;

    const { addonTab } = useParams();

    const componentToMap = {
        'basic-details': BasicDetails,
        'configure': Configuration,
        'link-to-category': LinkToCategory,
        'link-to-AddOnChoices': LinkToAddOnChoice,
    };

    const ComponentToRender = componentToMap?.[addonTab];

    const getDefaultFormValue = () => {
        return _.isEmpty(defaultDishFormState)
            ? {
                  details: { show_agmark: preferenceMenuItem[0] },
                  config: {
                      minimumSelectionPreference: preferenceMenuItem[1],
                      maximumSelectionPreference: preferenceMenuItem[1],
                  },
              }
            : defaultDishFormState;
    };

    const methods = useForm({
        defaultValues: getDefaultFormValue(),
    });

    return (
        <>
            <FormProvider {...methods}>
                <Layout>{ComponentToRender ? <ComponentToRender /> : <div>Invalid Tab</div>}</Layout>
            </FormProvider>
        </>
    );
}
