import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as LeftArrow } from '../../Assets/chevron-down.svg';
import { ReactComponent as Close } from '../..//Assets/close.svg';

import { DefaultInputField, InputArea } from '../../Components/InputField/InputField';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../Components/Buttons/Button';
import DropdonwnDifferentFirstMenuItem from '../../Components/DropDown/DropdonwnDifferentFirstMenuItem';
import { IsMobileScreen } from '../../Constants/Constants';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '../../Components/Error/ErrorMessage';
import { InputField } from '../Login/Components/Components';
import { ValidateUserPhoneNumber } from './Components/ValidateUserPhoneNumber';
import PhoneInputDropdown from '../../Components/MobileNumberInputFlagDropDown/Dropdown';
import _ from 'lodash';
import UploadImagePopup from '../../Components/UploadImagePopup/UploadImagePopup';
import { useSelector } from 'react-redux';
import { countryCodes } from '../../Components/MobileNumberInputFlagDropDown/CountryData';
import APIV5 from '../../api/axios/APIV5';
import RiderPopupLoader from './Components/RiderPopupLoader';
import CircularLoader from '../../Components/Loaders/CircularLoader';
import useOutsideClick from '../../Helper/Hooks/useOutsideClick';

export default function AddRider(props) {
    const isMobileScreen = IsMobileScreen();

    const {
        handleClickAddRider,
        riderDetails,
        setShowAddRiderPage,
        addOrEdit,
        isEditUser,
        uploadedImageDetails,
        setriderSaved,
        riderDetailsEdit,
        isLoadingEditRider,
    } = props;

    const {
        handleSubmit,
        control,
        formState: { errors, isDirty },
        setValue,
        watch,
        getValues,
        setError,
        clearErrors,
        trigger,
    } = useForm();

    const popupRef = useRef();
    useOutsideClick(popupRef, handleClickAddRider);

    const outletList = useSelector((state) => state.outlet.outletList);

    const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
    const [isShowMobileNumberPopup, setIsShowMobileNumberPopup] = useState(false);
    const [validatedNumber, mobileNumber, otp] = watch(['validatedNumber', 'mobileNumber', 'otp']);
    const [isValidInput, setIsValidInput] = useState(true);
    const [countryData, setCountryData] = useState(
        countryCodes.find((country) => '+' + country.code == (watch('country_code') ?? '+91'))
    );

    useEffect(() => {
        if (riderDetailsEdit && addOrEdit === 'edit') {
            setValue('validatedNumber', riderDetailsEdit?.mobile_number, { shouldDirty: false });
            setValue('Delivery radius', riderDetailsEdit?.delivery_radius, { shouldDirty: false });
            setValue('Delivery rate', riderDetailsEdit?.delivery_rate, { shouldDirty: false });
            setValue('RiderAddress', riderDetailsEdit?.rider_address, { shouldDirty: false });
            setValue('Rider age', riderDetailsEdit?.age, { shouldDirty: false });
            setValue('First name', riderDetailsEdit?.first_name, { shouldDirty: false });
            setValue('Last name', riderDetailsEdit?.last_name ? riderDetailsEdit?.last_name : 'last name', {
                shouldDirty: false,
            });
            setValue('Middle name', riderDetailsEdit?.middle_name, { shouldDirty: false });
            setValue('mobile_number_id', riderDetailsEdit?.phone_detail_id, { shouldDirty: false });
            setValue('imageDetails.profile_image_url', riderDetailsEdit?.profile_image_url, { shouldDirty: false });
            setValue(
                'outletSelection',
                riderDetailsEdit?.outlets.map((el) => el.id),
                { shouldDirty: false }
            );
            setValue('Fuel allowance', riderDetailsEdit?.fuel_allowance, { shouldDirty: false });
            setValue('Extra KM rate', riderDetailsEdit?.extra_delivery_rate, { shouldDirty: false });
        }
    }, [riderDetailsEdit, setValue, addOrEdit]);

    const addRiderCardInputField = [
        { label: 'First name', placeholder: 'Enter first name', required: true },
        {
            label: 'Middle name',
            placeholder: 'Enter middle name',
            required: true,
        },
        { label: 'Last name', placeholder: 'Enter Last name', required: true },
        { label: 'Mobile number', placeholder: 'Enter mobile number', required: false },
        { label: 'Delivery radius', placeholder: 'Enter radius in kms', required: false },
        { label: 'Delivery rate', placeholder: 'Enter price in rupees', required: false },
        { label: 'Rider age', placeholder: "Enter rider's age", required: true },
        { label: 'Fuel allowance', placeholder: 'Enter fuel allowance in rupees', required: false },
        { label: 'Extra KM rate', placeholder: 'Enter extra KM rate in rupees', required: false },
    ];

    const variable = getValues('outletSelection') ?? [];

    const handleClickItem = (item) => {
        const selectedIndex = variable?.indexOf(item.value);
        if (selectedIndex === -1) {
            setValue('outletSelection', [...variable, item.value]);
        } else {
            const newSelectedItems = [...variable];
            newSelectedItems.splice(selectedIndex, 1);
            setValue('outletSelection', newSelectedItems);
        }

        clearErrors('outletSelection');
    };

    const data = {
        first_name: watch('First name'),
        last_name: watch('Last name'),
        middle_name: watch('Middle name'),
        phone_detail_id: watch('mobile_number_id') ?? riderDetailsEdit?.phone_detail_id,
        // phone_detail_id: 1,
        country_code: countryData?.code,
        age: watch('Rider age'),
        address: watch('RiderAddress'),
        profile_upload_id: watch('imageDetails')?.id ?? riderDetailsEdit?.profile_image?.id,
        delivery_radius: watch('Delivery radius'),
        extra_delivery_rate: watch('Extra KM rate'),
        fuel_allowance: watch('Fuel allowance'),
        outlets: watch('outletSelection'),
        delivery_rate: watch('Delivery rate'),
    };

    const handleEdit = async () => {
        try {
            const response = await APIV5.put(`restaurants/${32}/riders/${riderDetailsEdit?.id}`, data);
            setShowAddRiderPage(false);
            {
                response.success === true && setriderSaved((prevState) => !prevState);
            }
        } catch (error) {
            console.log('error ==>', error);
            setError('editError', error);
        }
    };

    const onEditSubmit = () => {
        handleSubmit(handleEdit)();
    };

    const onAddSubmit = () => {
        if (!getValues('imageDetails')) {
            setError('imageDetails', { message: 'Please select Image' });
        } else {
            clearErrors('imageDetails');
        }

        if (!getValues('outletSelection')?.length) {
            setError('outletSelection', { message: 'Please select at least one outlet' });
        } else {
            clearErrors('outletSelection');
        }

        handleSubmit(handleAddRider)();
    };

    let addRider = {
        first_name: watch('First name'),
        last_name: watch('Last name'),
        middle_name: watch('Middle name'),
        phone_detail_id: watch('mobile_number_id'),
        country_code: countryData?.code,
        age: watch('Rider age'),
        address: watch('RiderAddress'),
        profile_upload_id: watch('imageDetails')?.id,
        delivery_radius: watch('Delivery radius') ?? 0,
        extra_delivery_rate: watch('Extra KM rate') ?? 0,
        fuel_allowance: watch('Fuel allowance') ?? 0,
        outlets: watch('outletSelection'),
        delivery_rate: watch('Delivery rate') ?? 0,
    };

    const handleAddRider = async () => {
        try {
            const response = await APIV5.post(`restaurants/${32}/riders`, addRider);
            setShowAddRiderPage(false);
            if (response.success === true) {
                setriderSaved((prevState) => !prevState);
                setShowAddRiderPage(false);
            }
        } catch (error) {
            console.error('Error adding rider:', error);
        }
    };

    if (showUploadImagePopup) {
        return (
            <UploadImagePopup
                selectedType={{ label: 'Riders', value: 'riders' }}
                setUploadedImageDetails={(data) => setValue('imageDetails', data)}
                handleClickClose={() => setShowUploadImagePopup(false)}
                isRiderPage={true}
            />
        );
    }
    const handleDiscard = () => {
        handleClickAddRider({});
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 md:z-[9] flex overflow-auto px-[33px] py-4 md:bg-white md:relative md:py-0 md:px-1'>
                <div
                    className='max-w-[830px] min-h-[888px] rounded-xl bg-shades-50 px-8 py-6 m-auto w-full md:max-w-full md:px-0 md:py-0 md:min-h-full'
                    ref={popupRef}>
                    {isLoadingEditRider ? (
                        <RiderPopupLoader />
                    ) : (
                        <div ref={popupRef}>
                            {isMobileScreen && (
                                <div
                                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                    onClick={() => handleClickAddRider()}>
                                    <LeftArrow className='rotate-90' />
                                    <span className='ml-1'>Back to riders</span>
                                </div>
                            )}

                            <div className='flex flex-row justify-between items-center border-neutral-300'>
                                <div>
                                    {addOrEdit === 'add' ? (
                                        <>
                                            <span className='paragraph-large-medium'>Add rider</span>
                                            <div className='flex flex-row items-center'>
                                                <span className='paragraph-medium-italic text-neutral-500'>
                                                    Register a new rider for your outlet
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        addOrEdit === 'edit' && (
                                            <>
                                                <span className='paragraph-large-medium'>Edit rider</span>
                                                <div className='flex flex-row items-center'>
                                                    <span className='paragraph-medium-italic text-neutral-500'>
                                                        Edit an existing rider for your outlet
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    )}
                                </div>
                                <div
                                    onClick={handleClickAddRider}
                                    className='md:hidden cursor-pointer'>
                                    <Close />
                                </div>
                            </div>

                            <div className='mt-6 flex flex-row items-center md:block'>
                                <p className='paragraph-medium-medium'>Upload profile picture</p>
                                <span className='paragraph-medium-italic text-neutral-500 ml-1 md:ml-0'>
                                    (1080px x 1080px recommended)
                                </span>
                            </div>

                            {watch('imageDetails')?.image_url || watch('imageDetails')?.profile_image_url ? (
                                <div className='flex items-center'>
                                    <div className='flex items-start'>
                                        <img
                                            className='w-[220px] h-[250px] rounded-md md:mr-[17px] md:min-w-[163px] mobile:min-w-0 mt-2'
                                            src={
                                                watch('imageDetails')?.profile_image_url
                                                    ? watch('imageDetails')?.profile_image_url
                                                    : watch('imageDetails')?.image_url
                                            }
                                            alt='UploadedImage'
                                        />
                                    </div>
                                    <div
                                        className='max-w-[197px] w-full md:max-w-full ml-4'
                                        onClick={() => setShowUploadImagePopup(true)}>
                                        <LargeTertiaryButton label='Replace image' />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className='mt-4 mb-2 md:flex md:flex-row'
                                        onClick={() => setShowUploadImagePopup(true)}>
                                        <button className='px-[34.5px] py-[3px] rounded-md min-w-[197px] h-[48px] border-neutral-300 border paragraph-medium-regular mr-[17px] md:min-w-0 md:w-1/2 md:px-0 mobile:text-sm'>
                                            Upload an image
                                        </button>
                                    </div>
                                    {errors?.imageDetails && <ErrorMessage errors={errors?.imageDetails} />}
                                </>
                            )}

                            <div>
                                {addRiderCardInputField.map((el, index) => {
                                    return (
                                        <>
                                            <div
                                                key={index}
                                                className='align-top inline-block w-1/2 even:pl-2 odd:pr-2 my-2'>
                                                {el.label === 'Mobile number' ? (
                                                    <div className='w-full  relative lg:w-full lg:ml-0 remove-spin-button'>
                                                        <Controller
                                                            render={() => (
                                                                <InputField
                                                                    inputValue={validatedNumber ?? ''}
                                                                    setValue={(value) => {
                                                                        setValue('mobileNumber', value, {
                                                                            shouldDirty: true,
                                                                        });
                                                                    }}
                                                                    showMobileIcon={false}
                                                                    isMobileNumberPage={true}
                                                                    isValidInput={isValidInput}
                                                                    setIsValidInput={setIsValidInput}
                                                                    onInputClick={() => {
                                                                        setIsShowMobileNumberPopup(true);
                                                                    }}
                                                                    paddingLeft='pl-[105px]'
                                                                    mobileNumberFIeldName={'mobileNumber'}
                                                                    otpFieldName={'otp'}
                                                                />
                                                            )}
                                                            name={`validatedNumber`}
                                                            control={control}
                                                            rules={{ required: 'Mobile number is required' }}
                                                        />
                                                        <div
                                                            className={`phoneinputbox absolute ${
                                                                errors?.validatedNumber ? 'bottom-8' : 'bottom-3'
                                                            } left-4`}
                                                            onClick={() => setIsShowMobileNumberPopup(true)}>
                                                            <PhoneInputDropdown
                                                                value={countryData?.code}
                                                                disabled={true}
                                                                countryData={countryData}
                                                                setCountryData={setCountryData}
                                                            />
                                                        </div>
                                                        {errors?.validatedNumber && (
                                                            <ErrorMessage errors={errors?.validatedNumber} />
                                                        )}

                                                        {isShowMobileNumberPopup && (
                                                            <ValidateUserPhoneNumber
                                                                setIsShowMobileNumberPopup={setIsShowMobileNumberPopup}
                                                                mobileNumber={mobileNumber}
                                                                otp={otp}
                                                                isValidInput={isValidInput}
                                                                setIsValidInput={setIsValidInput}
                                                                countryData={countryData}
                                                                setCountryData={setCountryData}
                                                                methods={{
                                                                    setValue,
                                                                    setError,
                                                                    formState: { errors },
                                                                    clearErrors,
                                                                    getValues,
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <Controller
                                                        key={index}
                                                        render={({ field }) => (
                                                            <div>
                                                                <DefaultInputField
                                                                    label={el.label}
                                                                    placeholder={el.placeholder}
                                                                    enteredValue={(value) => {
                                                                        field.onChange(value);
                                                                        setValue(el.label, value, {
                                                                            shouldDirty: true,
                                                                        });
                                                                    }}
                                                                    value={field.value}
                                                                    onChange={() => {
                                                                        // field.onChange();
                                                                        trigger(el.label);
                                                                    }}
                                                                />
                                                                {!field.value && errors && errors[el.label] && (
                                                                    <ErrorMessage errors={errors[el.label]} />
                                                                )}
                                                            </div>
                                                        )}
                                                        name={el.label}
                                                        control={control}
                                                        rules={{ required: el.required && el.label + ' is required' }}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>

                            <div className='relative mt-2 pb-2'>
                                <DropdonwnDifferentFirstMenuItem
                                    label='Select rider outlets'
                                    menuItems={outletList?.map((el) => ({ label: el.displayName, value: el.outletId }))}
                                    placeholder="Select rider's outlets"
                                    searchItemPlaceholder='Search outlet by typing...'
                                    type='searchDropdown'
                                    openFromBottom={isMobileScreen ?? true}
                                    handleClickItem={handleClickItem}
                                    watch={watch}
                                    name='outletSelection'
                                />
                                {errors?.outletSelection && <ErrorMessage errors={errors?.outletSelection} />}
                            </div>

                            <div className='mt-2 mb-12'>
                                <Controller
                                    render={({ field }) => (
                                        <InputArea
                                            label='Rider address'
                                            placeholder="Select rider's outlets"
                                            enteredValue={field.onChange}
                                            inputValue={field.value}
                                        />
                                    )}
                                    control={control}
                                    name='RiderAddress'
                                    rules={{ required: 'Rider address is Required' }}
                                />
                                {errors && errors.RiderAddress && <ErrorMessage errors={errors.RiderAddress} />}
                            </div>
                            {addOrEdit === 'add' ? (
                                <div
                                    className='w-full'
                                    onClick={onAddSubmit}>
                                    <LargePrimaryButton
                                        label='Add rider'
                                        type='submit'
                                    />
                                </div>
                            ) : (
                                addOrEdit === 'edit' && (
                                    <div className='flex justify-between'>
                                        <div
                                            className='w-8/12 pr-2'
                                            onClick={isDirty ? onEditSubmit : {}}>
                                            <LargePrimaryButton
                                                label='Edit rider'
                                                type='submit'
                                                disabled={!isDirty}
                                            />
                                        </div>
                                        <div
                                            className='w-4/12 pl-2'
                                            onClick={handleClickAddRider}>
                                            <LargeDestructiveButton
                                                label='Discard Rider'
                                                type='submit'
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                            {errors && errors.editError && (
                                <ErrorMessage errors={{ message: errors?.editError?.response?.data?.errors?.age }} />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
