import React, { useEffect, useState, useCallback } from 'react';
import Table from './Components/Table';
import PaymentReports from './Components/PaymentReports';
import { MultipleTab } from '../../Components/Tabs/Tabs';
import { useWindowSize } from '@uidotdev/usehooks';
import { ReactComponent as SelectIcon } from '../../Assets/select.svg';
import { ReactComponent as Dowload } from '../../Assets/download.svg';
import { ReactComponent as CloseIcon } from '../../Assets/close.svg';
import { LargePrimaryButton, LargeTertiaryButton } from '../../Components/Buttons/Button';
import APIV5 from '../../api/axios/APIV5';
import _ from 'lodash';
import PaginationWithNumber from '../../Components/Pagination/PaginationWithNumber';
import { useSelector } from 'react-redux';
import { TabsLoader, YAxisLineLoader } from '../../Components/ContentLoader/ReportCardLoader';

const DOWNLOADS_ITEMS = [
    { label: 'Excel', value: '/excel' },
    { label: 'CSV', value: '/csv' },
];

const Reports = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const [showCancelDelete, setShowCancelDelete] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [isDisable, setIsDisable] = useState(true);
    const [isReportCardLoading, setIsReportCardLoading] = useState(false);
    const [reportDetails, setReportDetails] = useState([]);
    const [selectedDownload, setSelectedDownload] = useState(DOWNLOADS_ITEMS[0]);
    const [paginationState, setPaginationState] = useState({
        resultPerPage: 10,
        selectedPage: 1,
        lastPage: 1,
    });
    const [updatedPaginationStatus, setUpdatedPaginationStatus] = useState({});
    const [paginationChanged, setPaginationChanged] = useState(false);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const screenSize = useWindowSize();

    const fetchReportsTabName = useCallback(async () => {
        try {
            setIsReportCardLoading(true);
            const response = await APIV5.get('/reports-list');

            if (response?.reports) {
                const formattedTabs = response?.reports?.map((report) => ({
                    label: report?.label,
                    value: report?.type,
                }));
                setTabs(formattedTabs);

                if (formattedTabs.length > 0) {
                    setActiveTab(formattedTabs[0].label);
                }
            }
        } catch (error) {
            console.error('Error fetching reports:', error);
        } finally {
            setIsReportCardLoading(false);
        }
    }, []);

    const fetchReportDetails = useCallback(
        async (params = {}) => {
            if (!activeTab) return;

            try {
                setIsReportCardLoading(true);
                const response = await APIV5.get('/report-downloads', {
                    params: {
                        ...params,
                        per_page:
                            params.per_page ?? updatedPaginationStatus.resultPerPage ?? paginationState.resultPerPage,
                        page: params.page ?? updatedPaginationStatus.selectedPage ?? paginationState.selectedPage,
                    },
                });

                setReportDetails(response?.requests?.data || []);

                setPaginationState((prevState) => ({
                    ...prevState,
                    lastPage: response.requests.last_page,
                    selectedPage: response.requests.current_page,
                }));

                setIsReportCardLoading(false);
            } catch (error) {
                console.log('error ===> ', error);
                setIsReportCardLoading(false);
            }
        },
        [activeTab, paginationState, updatedPaginationStatus]
    );

    useEffect(() => {
        fetchReportsTabName();
    }, [selectedRestaurantId, selectedOutletId]);

    useEffect(() => {
        fetchReportDetails();
    }, [activeTab]);

    useEffect(() => {
        if (checkedItems.length > 0) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [checkedItems]);

    // Add pagination change effect
    useEffect(() => {
        setPaginationChanged(true);
        const timer = setTimeout(() => {
            setPaginationChanged(false);
        }, 500);
        return () => clearTimeout(timer);
    }, [paginationState]);

    const updatePaginationState = (updates) => {
        setUpdatedPaginationStatus(updates);
        const newSelectedPage =
            updates.selectedPage !== undefined ? updates.selectedPage : paginationState.selectedPage;

        const params = {
            page: updates.selectedPage ?? paginationState.selectedPage,
            per_page: updates.resultPerPage ?? paginationState.resultPerPage,
        };

        fetchReportDetails(params);
        // fetchReportDetails(params, () => {
        //     if (newSelectedPage === paginationState.selectedPage) {
        //         setPaginationState((prevState) => ({
        //             ...prevState,
        //             per_page: updates.resultPerPage ?? paginationState.resultPerPage,
        //             selectedPage: newSelectedPage,
        //             ...updates,
        //         }));
        //     }
        // });
    };

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            updatePaginationState(data);
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }

        updatePaginationState({ ...data, selectedPage: 1 });
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab.label);
        // Reset pagination when changing tabs
        setPaginationState((prevState) => ({ ...prevState, selectedPage: 1 }));
        setUpdatedPaginationStatus({});
    };

    const getActiveTabValue = () => {
        return tabs.find((tab) => tab.label === activeTab) || '';
    };

    const handleBulkSelect = () => {
        setShowCancelDelete(!showCancelDelete);
        setShowCheckbox(!showCheckbox);
    };

    const handleClickCancel = () => {
        setShowCancelDelete(!showCancelDelete);
        setShowCheckbox(!showCheckbox);
        setCheckedItems([]);
    };

    const handleDownloadSelectedReports = () => {
        if (checkedItems?.length === 0) return;
        checkedItems.forEach((report) => {
            if (report.url) {
                try {
                    const link = document.createElement('a');
                    link.href = encodeURI(`${'https://api.staging.fab.delivery/storage/reports/' + report.name}`);
                    link.download = `${'https://api.staging.fab.delivery/storage/reports/' + report.name}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } catch (error) {
                    console.error(`Failed to download file from URL: ${report.url}`, error);
                    alert(`Error downloading file: ${report.name || 'Unnamed Report'}`);
                }
            } else {
                console.warn('No URL found for the report:', report);
            }
        });
    };

    return (
        <div className='bg-[#fafafa]'>
            <div className={`flex flex-col h-screen w-full max-w-[1336px] mx-auto bg-white p-4 pb-16`}>
                <div className='flex h-1/2'>
                    {/* Sidebar */}
                    {tabs.length > 0 ? (
                        <div
                            className='2xl:w-[12rem] xl:w-[11rem] w-[14.5rem] overflow-auto scrollbar-style p-2'
                            style={{ height: screenSize.height - 441 }}>
                            {tabs.map((tab, index) => (
                                <div
                                    className='lg:max-w-[253px] w-full mb-4'
                                    key={tab.label}>
                                    <MultipleTab
                                        label={tab.label}
                                        index={index}
                                        onClick={() => handleTabClick(tab)}
                                        isActive={activeTab == tab.label}
                                        minWidth='min-w-[113px]'
                                        maxHeight='min-h-[53px]'
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            <TabsLoader />
                            <YAxisLineLoader />
                        </>
                    )}

                    {/* Main Content */}
                    <div className='scrollbar-style w-full h-full xl:p-2 lg:p-1 overflow-auto'>
                        <PaymentReports
                            fetchReportDetails={fetchReportDetails}
                            activeTab={activeTab}
                            selectedRestaurantId={selectedRestaurantId}
                            reportType={getActiveTabValue()}
                            selectedDownload={selectedDownload}
                            setSelectedDownload={setSelectedDownload}
                            DownloadsItems={DOWNLOADS_ITEMS}
                            selectedOutletId={selectedOutletId}
                            reportDetails={reportDetails}
                        />
                    </div>
                </div>

                <div className='h-1/2 w-full mt-6 p-6'>
                    <div className='flex flex-row items-center'>
                        <div className=''>
                            {showCancelDelete ? (
                                <div className='flex flex-row items-center md:w-full'>
                                    <div
                                        className='max-w-[120px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                        onClick={handleClickCancel}>
                                        <LargeTertiaryButton
                                            isDefault={false}
                                            label='Cancel'
                                            hideLabel='lg:hidden'
                                            leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                                            leftIconClick={<CloseIcon stroke='#C4BEED' />}
                                        />
                                    </div>
                                    <div
                                        className='max-w-[300px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                        onClick={handleDownloadSelectedReports}>
                                        <LargePrimaryButton
                                            label='Download Selected Report'
                                            leftIconDefault={
                                                <Dowload
                                                    height={24}
                                                    width={24}
                                                    stroke='#ffffff'
                                                />
                                            }
                                            leftIconClick={
                                                <Dowload
                                                    height={24}
                                                    width={24}
                                                    stroke='#ffffff'
                                                />
                                            }
                                            hideLabel='lg:hidden'
                                            disabled={isDisable}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className='max-w-[156px] w-full md:max-w-[64px] ml-2 lg:ml-2 md:ml-1'
                                    onClick={handleBulkSelect}>
                                    <LargePrimaryButton
                                        leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                        leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                        label='Bulk select'
                                        hideLabel='lg:hidden'
                                    />
                                </div>
                            )}
                        </div>
                        <span className='paragraph-large-medium text-black font-bold ml-4'>
                            Recently Generated Reports
                        </span>
                    </div>
                    <Table
                        reportDetails={reportDetails}
                        paginationChanged={paginationChanged}
                        isReportCardLoading={isReportCardLoading}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                        showCheckbox={showCheckbox}
                    />
                </div>
                {!_.isEmpty(reportDetails) && (
                    <div className='pt-4'>
                        <PaginationWithNumber
                            lastPage={paginationState.lastPage}
                            selectedPage={paginationState.selectedPage}
                            setSelectedPage={(value) => handlePagination({ selectedPage: value }, 'selectedPage')}
                            setResultPerPage={(value) => handlePagination({ resultPerPage: value }, 'resultPerPage')}
                            selectedResultPerpage={paginationState.resultPerPage}
                            isLoading={isReportCardLoading}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Reports;
